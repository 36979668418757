import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
export default function ActiveEmployees() {
  const [showActivemployeedetails, setActiveEmployeeDetails] = useState([]);
  const [showActiveErrMsg,setActiveErrMsg]=useState(false)
  const navigateActive = useNavigate()
  function navigateNoOfDues(valueAtDues) {
    try {
      if (valueAtDues === "withdues") {navigateActive("/duesdetails");localStorage.setItem("value", "with");} 
      else if (valueAtDues === "withoutdues") {navigateActive("/withoutduesdetails");localStorage.setItem("value", "without");} 
      else if (valueAtDues === "total") {navigateActive("/totalemployeesassetdetails");}
    } catch (errAtActive) {console.error(":::", errAtActive);}
  }

  function GetDataAtActiveEmp() {
    let Url = (config?.url?.COMMON_URL + "assetsdashboard");
    CommonAxiosGet(Url).then((resAtActive) => {
      if (resAtActive?.data?.status === true) {
        setActiveEmployeeDetails(resAtActive?.data?.assetsdashboard);
        setActiveErrMsg(false)
      }
      else {
        setActiveEmployeeDetails([]);
        setActiveErrMsg(true)
      }
    })
  }

const fetchData = useRef(false)
  useEffect(() => {
    if(!fetchData?.current){
      GetDataAtActiveEmp()
fetchData.current=true
    }
  }, []);

  return (<>
    <jnb.Row className="px-3 mb-2 pt-3" >
      <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Employee Dues Report</li>
        </ol>
      </jnb.Col>
    </jnb.Row>
{showActivemployeedetails?.length > 0 ? (<>
    <jnb.Row className="px-3" >
      <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="pt-3">
        <div className="card-container">
          <div className="card3d">
            <jnb.Card.Body>
              <jnb.Card.Title className="highlight-title">No.Of Employees</jnb.Card.Title>
              <jnb.Card.Text >
                <b className="highlight-text" onClick={(e) => { navigateNoOfDues("total") }}>{showActivemployeedetails[0]?.employee_count}</b>
              </jnb.Card.Text></jnb.Card.Body></div></div>
      </jnb.Col>
      <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="pt-3">
        <div className="card-container">
          <div className="card3d">
            <jnb.Card.Body>
              <jnb.Card.Title className="highlight-title" >Employees with issued Items</jnb.Card.Title>
              <jnb.Card.Text >
                {showActivemployeedetails[0]?.pendind_assets_dues === null ? (<div onClick={() => { Swal.fire("No Data Found", "info") }}>
                  <div className="highlight-text">0</div>
                </div>) : (<>
                  <a className="highlight-text" href={() => false} style={{textDecoration: "none"}} onClick={(e) => { navigateNoOfDues("withdues") }} >
                    {showActivemployeedetails[0]?.pendind_assets_dues} </a></>)}
              </jnb.Card.Text>
            </jnb.Card.Body></div>
        </div>
      </jnb.Col>
      <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mt-3">
        <div className="card-container">
          <div className="card3d">
            <jnb.Card.Body>
              <jnb.Card.Title className="highlight-title">Employees without issued Items</jnb.Card.Title>
              <jnb.Card.Text className="highlight-text" onClick={() => { navigateNoOfDues("withoutdues") }}>
                {showActivemployeedetails[0]?.no_assets_dues === null ? (<>0
                </>) : (<>  {showActivemployeedetails[0]?.no_assets_dues}</>)}
              </jnb.Card.Text>
            </jnb.Card.Body>
          </div></div>
      </jnb.Col>
    </jnb.Row>
    </>):(<>{showActiveErrMsg === true ? (<>
      <center><b style={{color:"red"}}>*********************No Data Found******************* </b></center></>):(<></>)}</>)}
  </>)
}
