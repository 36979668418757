const SUPPORTED_FORMAT = ["application/pdf"];
const MAX_FILESIZE = 3000000;
function validateFileType(customefile) {
  let errormsg = "";
  if (customefile.size > MAX_FILESIZE) {
    errormsg = "Please Check your file size, it should be less than 3MB\n";
  }
  if (!SUPPORTED_FORMAT.includes(customefile.type)) {
    errormsg += "File format is invalid, Please upload only .pdf";
  }
  if (errormsg === "") {
    return true;
  } else {
    alert(errormsg);
    return false;
  }
}

export default function FilesBase64(e, formIkforFile, name, hiddenValue) {
  e.preventDefault();
  let fileValue = validateFileType(e.target.files[0]);
  if (fileValue) {

    const filevalue = e.target.value;
    const file = e.target.files[0];
    formIkforFile.setFieldValue(name, filevalue);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        formIkforFile.setFieldValue(hiddenValue, event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  }
  else {
    formIkforFile.setFieldValue(name, '');
    formIkforFile.setFieldValue(hiddenValue, '');
    e.target.value = '';
  }

}
