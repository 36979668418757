import { ErrorMessage, Field, FormikProvider, useFormik,Form } from "formik";
import * as jnb from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../CommonUtils/CommonApis";
import React, { useEffect, useState } from "react";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import allowNumbersOnly, { allowAlphabetAndSpacesOnly } from "../../../CommonUtils/CommonValidations";
import * as Yup from "yup";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import Card from 'react-bootstrap/Card';
export default function ROApprisalForm() {
    const rologindata = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const employeeId = useSelector((state) => state.reducers.employeereducer.empid);
    const dispatch = useDispatch();
    const [showEmpID, setEmpID] = useState("");
    const [showEmpoverallData, setempoveralldata] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [showROEmpData, setROEmpData] = useState([]);
    const [showROEmpKpData, setROEmpKpData] = useState([]);
    const [showROEmpKpStatusData, setROEmpKpStatusData] = useState([]);
    const onlynum = /^[0-9]+$/;
    const validation = Yup.object().shape({
        roFinalRating: Yup.string().required("Please Select Final Rating").nullable(),
        roFinalRemarks: Yup.string().when("roFinalRating", {
            is: (roFinalRating) => (roFinalRating !== showEmpoverallData.overall_rating) ? true : false,
            then: Yup.string().required("Please Enter Final Rating Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),
        promotionRemarks: Yup.string().when("promotionStatus", {
            is: (promotionStatus) => (promotionStatus === true) ? true : false,
            then: Yup.string().required("Please Enter Promotion Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),
        strengthsWeaknesses: Yup.string().required("Please Enter Strengths").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        weaknesses: Yup.string().required("Please Enter Areas for Improvements").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        salaryCorrection: Yup.string().required("Please Select Salary Correction").nullable(),
        salaryCorrectionRemarks: Yup.string().when("salaryCorrection", {
            is: (salaryCorrection) => (salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please Enter Remarks").trim().min(4, 'Minimum 4 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),

        salaryCorrectionAmount: Yup.string().when("salaryCorrection", {
            is: (salaryCorrection) => (salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please Enter Salary Correction Amount").matches(onlynum, "Amount must be digits only").nullable(),
        })
    })
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            roEmpId: rologindata?.username,
            empId: employeeId,
            kpWeightage: 100,
            ioFinalRating: "",
            ioFinalMarks: "",
            empSelfFinalMarks: "",
            empSelfFinalRating: "",
            roFinalRating: "",
            roFinalMarks: "",
            roFinalRemarks: "",
            promotionStatus: false,
            promotionRemarks: "",
            strengthsWeaknesses: "",
            weaknesses: "",
            salaryCorrection: "",
            salaryCorrectionAmount: 0,
            salaryCorrectionRemarks: ""
        },
        validationSchema: validation,
        onSubmit: (values) => {
            ROsubmitAlert(values);
        },

    });
    const ROsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.rologinPost(values)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetROApprisalData();
                                }
                            })
                        }
                        else { Swal.fire('Canceled', 'Something went wrong,Please check', 'error'); }
                    })
                    .catch((err) => {console.log("err",err)});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };

    const [empFile, setEmpFile] = useState('');
    function GetROApprisalData() {
        let Url = (config.url.COMMON_URL + "employee/appraisal/RO/" + employeeId);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setROEmpData(res.data.employee_data);
                setROEmpKpData(res.data.employee_kp_data);
                setROEmpKpStatusData(res.data.employee_kpstatus_data);
                setEmpID(employeeId);
                setEmpFile(res.data.file_upload);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }

    useEffect(() => {
        GetROApprisalData();
    }, [dispatch, employeeId]);
    const [showallStatusData, setallStatusData] = useState([]);
    function Getoveralstatusdata() {
        let Url = (config.url.COMMON_URL + "employee/kpallstatus/" + showEmpID);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setallStatusData(res.data.employee_kp_status_data)
                setempoveralldata(res.data);
                formIk.setFieldValue("ioFinalRating", res.data.overall_rating)
                formIk.setFieldValue("roFinalRating", res.data.overall_rating)
                formIk.setFieldValue("ioFinalMarks", res.data.total_marks)
                formIk.setFieldValue("roFinalMarks", res.data.total_marks)
                formIk.setFieldValue("empSelfFinalMarks", res.data.self_total_marks)
                formIk.setFieldValue("empSelfFinalRating", res.data.self_overall_rating)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    const [showfinalratingmsg, setfinalratingmsg] = useState("");
    function Clearalldata(empindicatordat) {
        for (var i = 0; i <= empindicatordat.length; i++) {
            formIk.setFieldValue("empKpdata[" + i + "].rating", "");
            formIk.setFieldValue("empKpdata[" + i + "].kpindicatorDesc", "");
        }
    }
    const isPromoted = showROEmpData[0]?.promotion_status === true;
const promotionRemarks = showROEmpData[0]?.promotion_remarks || "Not Promoting";
    return (<>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <div class="card">
                    <div class="card-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/homepage">Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"RO"}`}>My Team Appraisal-RO</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                        </ol>
                    </div>
                </div>
                <div className="head-text mt-4"> <h1> Appraisal Form </h1></div>
                <div className="  mb-3" >
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  > {showROEmpData[0]?.emp_name}({showROEmpData[0]?.emp_id})</Card.Title>
                                    <Card.Text>
                                        Name (ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  >{showROEmpData[0]?.doj?.split("-")?.reverse()?.join("/")}</Card.Title>
                                    <Card.Text>
                                        Date of Joining
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card  >
                                <Card.Body>
                                    <Card.Title  >{showROEmpData[0]?.zone_name}</Card.Title>
                                    <Card.Text>
                                        Wing
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-success text-white">
                                <Card.Body>
                                    <Card.Title>{showROEmpData[0]?.io_emp_name}({showROEmpData[0]?.io_emp_id})</Card.Title>
                                    <Card.Text>
                                        IO Name(ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-primary text-white">
                                <Card.Body>
                                    <Card.Title  > {showROEmpData[0]?.ro_emp_name}({showROEmpData[0]?.ro_emp_id}) </Card.Title>
                                    <Card.Text>
                                        RO Name(ID)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <Card className="bg-secondary text-white">
                                <Card.Body>
                                    <Card.Title  > {showROEmpData[0]?.designation_name} </Card.Title>
                                    <Card.Text>
                                        Designation
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </jnb.Col>
                    </jnb.Row>
                    <br />
                </div>
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} onClick={(e) => { Clearalldata(showROEmpKpStatusData); }} style={{ fontSize: "12px" }}>
                    <TabList   >
                        {showROEmpKpData !== undefined && showROEmpKpData.map((row, i) => {
                            return (<React.Fragment key={uuidv4()}>
                                <Tab key={i}  >{row.kp_short_description} </Tab>
                            </React.Fragment>)
                        })}
                        <Tab onClick={(e) => { Getoveralstatusdata() }}>Overal Status</Tab>
                    </TabList>
                    {showROEmpKpData !== undefined && showROEmpKpData.map((row, i) => {
                        return (<React.Fragment key={uuidv4()}>
                            <TabPanel>
                                <div className="table-responsive">
                                    <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                                    <br></br>
                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Key Performance Areas Description</th>
                                                <th>Self Rating</th>
                                                <th>Self Remarks</th>
                                                <th>IO Rating</th>
                                                <th> IO Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {showROEmpKpStatusData !== undefined && showROEmpKpStatusData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                                    return (
                                                        <React.Fragment key={k}>
                                                            <tr>
                                                                <td>{k + 1}</td>
                                                                <td width="450px"> {kpidata.kp_indicator_description}</td>
                                                                <td>{kpidata.emp_self_rating}</td>
                                                                <td>{kpidata.emp_self_rating_remarks}</td>
                                                                <td>{kpidata.io_emp_rating}</td>
                                                                <td>{kpidata.io_emp_remarks}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                }
                                                )
                                            }
                                        </tbody>

                                    </table>
                                </div>

                            </TabPanel>
                        </React.Fragment>)
                    })}
                    <TabPanel><jnb.Row className="px-5 pt-3">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <div className="table-responsive">
                                <table className="table table-condensed table-bordered table-striped table-responsive"
                                    style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Key Performance Description</th>
                                            <th>Self Rating</th>
                                            <th>IO Rating</th>

                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {showallStatusData !== undefined && showallStatusData.map((alldata, j) => {
                                            return (<React.Fragment key={j}>
                                                <tr>
                                                    <td>{j + 1}</td>
                                                    <td>{alldata.kp_description}</td>
                                                    <td>{alldata.emp_self_final_marks}/{alldata.kp_weightage}</td>
                                                    <td>{alldata.io_final_marks}/{alldata.kp_weightage}</td>
                                                </tr>
                                            </React.Fragment>)
                                        })}
                                        <tr>
                                            <td></td>
                                            <td>Total</td>
                                            <td>{showallStatusData.reduce((a, v) => a + v.emp_self_final_marks, 0)}/{showallStatusData.reduce((a, v) => a + v.kp_weightage, 0)}</td>
                                            <td>{showallStatusData.reduce((a, v) => a + v.io_final_marks, 0)}/{showallStatusData.reduce((a, v) =>a + v.kp_weightage, 0)}</td>
                                        </tr>
                                    </tbody >
                                </table>
                            </div>
                        </jnb.Col>
                    </jnb.Row>
                        <jnb.Row className="px-5 pt-3">
                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Self remarks</th>
                                                <th> IO Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>  Strengths </td>
                                                <td><b>{showROEmpKpStatusData[0]?.self_strengths}</b></td>
                                                <td><b>{showROEmpKpStatusData[0]?.io_strengths}</b> </td>

                                            </tr>
                                            <tr>
                                                <td>Areas for Improvement</td>
                                                <td><b>{showROEmpKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                                <td><b>{showROEmpKpStatusData[0]?.io_areas_of_imporvement}</b></td>
                                            </tr>
                                            <tr>
                                                <td> Career Aspirations </td>
                                                <td><b>{showROEmpKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                                <td><b>{showROEmpKpStatusData[0]?.io_carrer_aspirations}</b></td>

                                            </tr>
                                            <tr>
                                                <td>Upload Any Relevant Document</td>
                                                <td>
                                                    {(empFile != undefined && empFile != "") ? (
                                                    <a href={empFile} target="_blank" download={"Employee_Document"}>  View</a>
                                                ) : null} </td>
                                                <td>{showROEmpData[0]?.io_file_remarks}</td>
                                            </tr>
                                            <tr>
                                                <td>Additional accomplishments not covered in Key Objectives above</td>
                                                <td>{showROEmpKpStatusData[0]?.self_addl_key_objective}</td>
                                                <td><b>{showROEmpKpStatusData[0]?.io_addl_key_objective}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Col>
                        </jnb.Row>
                        {showROEmpData[0]?.ro_final_status ? (<>
                            <jnb.Row className="px-5 pt-3">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                    <div className="table-responsive">
                                        <table className="table table-condensed table-bordered table-striped table-responsive"
                                            style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                            <thead>
                                                <th></th>
                                                <th></th>
                                                <th>Remarks</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>  Final Rating: </td>
                                                    <td><b>{showROEmpData[0]?.ro_final_rating}</b></td>

                                                    <td><b>{showROEmpData[0]?.ro_final_remarks}</b> </td>

                                                </tr>
                                                <tr>
                                                    <td>Referring for Promotion </td>
                                                    {isPromoted ? (<><td><b>Yes</b></td><td><b>{promotionRemarks}</b></td></>) : (
                                                        <td colSpan={2}><b>Not Promoting</b></td>)}
                                                    {/*  */}
                                                </tr>
                                                <tr>
                                                    <td>Salary Correction</td>
                                                    {showROEmpData[0]?.salary_correction === "Y" && (<>
                                                    <td><b>Yes</b></td>
                                                    <td><b> amount: {showROEmpData[0]?.salary_correction_amount} - {showROEmpData[0]?.salary_correction_remarks}</b></td>
                                                    </>)}
                                                    {showROEmpData[0]?.salary_correction === "N" && (<td colSpan={2}><b>No Salary Correction</b></td>)}
                                                    </tr>
                                                <tr>
                                                    <td>Strengths</td>
                                                    <td colSpan={2}><b>{showROEmpData[0]?.strengths_weaknesses}</b></td>
                                                </tr>
                                                <tr>
                                                    <td>Areas for Improvement</td>
                                                    <td colSpan={2}>{showROEmpData[0]?.weaknesses}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </jnb.Col>
                            </jnb.Row>
                        </>) : (<>
                            <jnb.Row className="px-5 pt-3">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>

                                    <jnb.Row className="px-5 pt-3">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                            <jnb.InputGroup className="mb-4p3">
                                                <span className="label-text" style={{ fontSize: "14px" }}><b>Final Rating:</b><font style={{ color: "red" }}>*</font></span>
                                            </jnb.InputGroup>
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                            <jnb.InputGroup className="mb-4p3">
                                                <Field as="select" className="form-control" name="roFinalRating" onChange={(e) => { e.target.value === showEmpoverallData.overall_rating ? (setfinalratingmsg("N")) : (setfinalratingmsg("Y")); formIk.setFieldValue("roFinalRemarks", "") }}>
                                                    <option value="">--Select--</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </Field>
                                                <ErrorMessage name="roFinalRating" component="div" className="text-error" />
                                            </jnb.InputGroup>
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                        {showfinalratingmsg === "Y" && (<>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <span className="label-text" style={{ fontSize: "14px" }}><b>Remarks:</b><font style={{ color: "red" }}>*</font></span>
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <Field as="textarea" className="form-control" name="roFinalRemarks" maxLength="400"></Field>

                                                </jnb.InputGroup>
                                                <ErrorMessage name="roFinalRemarks" component="div" className="text-danger" ></ErrorMessage>
                                            </jnb.Col>
                                        </>)}
                                    </jnb.Row>
                                    <jnb.Row className="px-5 pt-3">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                                            <jnb.InputGroup className="mb-4p3">
                                                <span style={{ fontSize: "14px" }}><b>I am Referring for Promotion </b></span>&nbsp;&nbsp;
                                                <Field type="checkbox" name="promotionStatus" onChange={(e) => { formIk.setFieldValue("promotionRemarks", "") }}></Field>

                                            </jnb.InputGroup>
                                            <ErrorMessage name="promotionStatus" component="div" className="text-error" />
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                        {formIk.values.promotionStatus === true && (<>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <span className="label-text" style={{ fontSize: "14px" }}><b>Remarks:</b><font style={{ color: "red" }}>*</font></span>
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <Field as="textarea" className="form-control" name="promotionRemarks" maxLength="400"></Field>
                                                </jnb.InputGroup>
                                                <ErrorMessage name="promotionRemarks" component="div" className="text-danger" ></ErrorMessage>
                                            </jnb.Col>
                                        </>)}
                                    </jnb.Row>
                                    <jnb.Row className="px-5 pt-3">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                            <jnb.InputGroup className="mb-4p4">
                                                <span className="label-text" style={{ fontSize: "14px" }}><b>Salary Correction:</b><font style={{ color: "red" }}>*</font></span>
                                            </jnb.InputGroup>
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>

                                            <jnb.InputGroup className="mb-4p3">
                                                <Field type="radio" name="salaryCorrection" value="Y" onChange={(e) => { formIk.setFieldValue("salaryCorrectionAmount", "") }} />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                                                <Field type="radio" name="salaryCorrection" value="N" />&nbsp;&nbsp;No

                                            </jnb.InputGroup>
                                            <ErrorMessage name="salaryCorrection" component="div" className="text-error" />

                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                        {formIk.values.salaryCorrection === "Y" && (<>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <span className="label-text" style={{ fontSize: "14px" }}><b>Remarks:</b><font style={{ color: "red" }}>*</font></span>
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <Field as="textarea" className="form-control" name="salaryCorrectionRemarks" onKeyPress={(e) => allowAlphabetAndSpacesOnly(e)} maxLength="400"></Field>
                                                    <ErrorMessage name="salaryCorrectionRemarks" component="div" className="text-danger" ></ErrorMessage>
                                                </jnb.InputGroup>
                                            </jnb.Col>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                                <jnb.InputGroup className="mb-4p3">
                                                    <span className="label-text-style"><b>Amount:</b><font style={{ color: "red" }}>*</font></span>
                                                    <Field type="text" className="form-control" name="salaryCorrectionAmount" onKeyPress={(e) => allowNumbersOnly(e)}
                                                        maxLength="5"></Field>

                                                </jnb.InputGroup>
                                                <ErrorMessage name="salaryCorrectionAmount" component="div" className="text-danger" ></ErrorMessage>
                                            </jnb.Col>
                                        </>)}
                                    </jnb.Row>
                                    <jnb.Row className="px-5 pt-3">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                            <span className="label-text"><b>Strengths </b><font style={{ color: "red" }}>*</font></span>
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                            <jnb.InputGroup className="mb-4p3">
                                                <Field as="textarea" className="form-control" name="strengthsWeaknesses" maxLength="400"></Field>
                                            </jnb.InputGroup>
                                            <ErrorMessage name="strengthsWeaknesses" component="div" className="text-danger" ></ErrorMessage>

                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}></jnb.Col>
                                    </jnb.Row>
                                    <jnb.Row className="px-5 pt-3">
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                            <span className="label-text"><b>Areas for Improvement</b><font style={{ color: "red" }}>*</font></span>
                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                            <jnb.InputGroup className="mb-4p3">
                                                <Field as="textarea" className="form-control" name="weaknesses" maxLength="400"></Field>
                                            </jnb.InputGroup>
                                            <ErrorMessage name="weaknesses" component="div" className="text-danger" ></ErrorMessage>

                                        </jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                    </jnb.Row>
                                    <jnb.Row>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={9} xxl={9}></jnb.Col>
                                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ textAlign: "right" }} >
                                            <button type="submit" className="btn btn-success btn-sm" style={{ float: "right" }}>
                                                Submit
                                            </button>
                                            <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}></jnb.Col>
                                            <br></br>
                                        </jnb.Col>
                                    </jnb.Row>

                                </jnb.Col>
                            </jnb.Row>
                        </>)}
                    </TabPanel>
                </Tabs>
            </Form>
        </FormikProvider >

    </>)
}
