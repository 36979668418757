import { ErrorMessage, Field, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { config } from "../../../../CommonUtils/CommonApis";
import Swal from "sweetalert2";
import { ROLE_IO } from "../../../../CommonUtils/ApplicationRoles";
import CommonPost from "../../../../CommonUtils/CommonPost";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import getPDFView from "../../../../CommonUtils/PdfView";
import { FaFilePdf } from "react-icons/fa6";

export default function IoPmsForm() {
    const emplogindataAtIoPmsForm = useSelector((statelogin) => statelogin.reducers.loginreducer.userLoginDetials);
    const employeeIdAtIoPmsForm = useSelector((stateempid) => stateempid.reducers.employeereducer.empid);
    const [showEmpDataAtIoPmsForm, setEmpDataAtIoPmsForm] = useState([]);
    const [showEmpKpDataAtIoPmsForm, setEmpKpDataAtIoPmsForm] = useState([]);
    const onlynumAtIoPmsForm = /^[0-9]+$/;
    const [showEmpInitialValuesAtIoPmsForm, setEmpInitialValuesAtIoPmsForm] = useState({
        strengthsWeaknesses: "",
        areas_of_imporvement: "",
        carrer_aspirations: "",
        addl_key_objective: "",
        file_upload: "",
        file_uploadhidden: "",
        io_upload_remarks: "",

        promotionStatus: false,
        promotionRemarks: "",
        salaryCorrection: "",
        salaryCorrectionAmount: 0,
        salaryCorrectionRemarks: ""
    });

    const [showEmployeeKpStatusDataAtIoPms, setEmployeeKpStatusDataAtIoPms] = useState([]);
    const [showEmployeeRatingDataAtIoPms, setEmployeeRatingDataAtIoPms] = useState([]);

    const validationAtIoPms = Yup.object().shape({
        kp1: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })),
        kp3: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })),
        kp2: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })),
        kp5: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })),
        kp4: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })),
        strengthsWeaknesses: Yup.string().required("Please Enter Strengths").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        areas_of_imporvement: Yup.string().required("Please Enter Areas of Improvements").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        carrer_aspirations: Yup.string().required("Please Enter Career Aspirations").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        addl_key_objective: Yup.string().required("Please Enter Additional accomplishments not covered in Key Objectives above").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),

        promotionRemarks: Yup.string().when("promotionStatus", {
            is: (promotionStatus) => (promotionStatus === true) ? true : false,
            then: Yup.string().required("Please Enter Promotion Remarks").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),

        salaryCorrection: Yup.string().required("Please Select Salary Correction").nullable(),
        salaryCorrectionRemarks: Yup.string().when("salaryCorrection", {
            is: (salaryCorrection) => (salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please Enter Remarks").trim().min(4, 'Minimum 4 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        }),

        salaryCorrectionAmount: Yup.string().when("salaryCorrection", {
            is: (salaryCorrection) => (salaryCorrection === "Y") ? true : false,
            then: Yup.string().required("Please Enter Salary Correction Amount").matches(onlynumAtIoPmsForm, "Amount must be digits only").nullable(),
        })

    });
    const ioPmsformIk = useFormik({
        enableReinitialize: true,
        initialValues: showEmpInitialValuesAtIoPmsForm,
        validationSchema: validationAtIoPms,
        onSubmit: (values) => {
            const payloadAtipPms = {
                empId: employeeIdAtIoPmsForm,
                userId: emplogindataAtIoPmsForm?.username, ...ioPmsformIk.values
            }

            const payload2Ippms = {
                empId: employeeIdAtIoPmsForm,
                userId: emplogindataAtIoPmsForm?.username,


                finalStatus: ioPmsformIk?.values?.finalStatus,

                empSelfFinalMarks: ioPmsformIk?.values?.empSelfFinalMarks,
                empSelfFinalRating: ioPmsformIk?.values?.empSelfFinalRating,
                ioFinalMarks: totalAverageAtIopms,
                ioFinalRating: gradeAtIP,

                strength: ioPmsformIk?.values?.strengthsWeaknesses,
                areas_of_imporvement: ioPmsformIk?.values?.areas_of_imporvement,
                carrer_aspirations: ioPmsformIk?.values?.carrer_aspirations,
                addl_key_objective: ioPmsformIk?.values?.addl_key_objective,
                file_upload: ioPmsformIk?.values?.file_upload,
                file_uploadhidden: ioPmsformIk?.values?.file_uploadhidden,
                io_upload_remarks: ioPmsformIk?.values?.io_upload_remarks,

                promotionStatus: ioPmsformIk?.values?.promotionStatus,
                promotionRemarks: ioPmsformIk?.values?.promotionRemarks,
                salaryCorrection: ioPmsformIk?.values?.salaryCorrection,
                salaryCorrectionAmount: ioPmsformIk?.values?.salaryCorrectionAmount,
                salaryCorrectionRemarks: ioPmsformIk?.values?.salaryCorrectionRemarks
            }
            EmpsubmitAlertAtIoPms(payloadAtipPms, payload2Ippms);
        },
     
    });
    const [showIOEmpKpStatusData, setIOEmpKpStatusData] = useState([]);
    function GetApprialDataAtIppms() {
        let Url = (config.url.COMMON_URL + "pms/employee/appraisal/IO/" + employeeIdAtIoPmsForm);
        CommonAxiosGet(Url).then((resAtiopms) => {
            if (resAtiopms.data.scode === "01") {
                setEmpDataAtIoPmsForm(resAtiopms.data.employee_data);
                let b = {};
                resAtiopms.data.employee_kp_data.forEach(el => {
                    b[el.kp_data_flag_status] = (b[el.kp_data_flag_status] || 0) + 1;
                })
                var result = resAtiopms.data.employee_kp_data.find(({ kp_data_flag_status }) => !kp_data_flag_status);
                if (result) { result.kp_data_flag_status = true };

                setEmpKpDataAtIoPmsForm(resAtiopms.data.employee_kp_data);
                setIOEmpKpStatusData(resAtiopms.data.employee_kpstatus_data)
                for (let i = 0; i < resAtiopms.data.employee_kp_data.length; i++) {
                    if (resAtiopms.data.employee_kp_data[i]?.kp_sub_indicator_flag === "Y") {

                        const formikDataYes = resAtiopms.data.employee_kpstatus_data.filter((testing) => testing.kp_id === resAtiopms.data.employee_kp_data[i].kp_id)
                        let kpindicatordata = resAtiopms.data.employee_kp_indicator_data.filter((rowdata) => rowdata.kp_id === resAtiopms.data.employee_kp_data[i].kp_id);//1,2

                        let currentArray = [];
                        for (var j = 0; j < kpindicatordata.length; j++) {
                            if (formikDataYes.length > 0) {
                                let rowObject = {
                                    rating: formikDataYes[j]?.io_emp_rating === null ? "" : formikDataYes[j]?.io_emp_rating?.trim(), kpremarks: formikDataYes[j]?.io_emp_remarks === null ? "" : formikDataYes[j]?.io_emp_remarks,
                                    kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id, kpindicatorDesc: kpindicatordata[j]?.kp_indicator_description
                                    , io_rating_weightage: formikDataYes[j]?.io_rating_weightage === null ? 0 : formikDataYes[j]?.io_rating_weightage, kp_weightage: resAtiopms.data.employee_kp_data[i]?.kp_weightage
                                };
                                currentArray.push(rowObject);
                            }
                            else {
                                let rowObject = {
                                    rating: "", kpremarks: "",
                                    kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id, kpindicatorDesc: kpindicatordata[j]?.kp_indicator_description,
                                    io_rating_weightage: 0, kp_weightage: resAtiopms.data.employee_kp_data[i]?.kp_weightage
                                };
                                currentArray.push(rowObject);
                            }
                        }
                        let kpid = resAtiopms.data.employee_kp_data[i]?.kp_id;
                        setEmpInitialValuesAtIoPmsForm((prevState) => ({ ...prevState, ["kp" + kpid]: currentArray,
                        strengthsWeaknesses: resAtiopms?.data?.employee_kpstatus_data[0]?.io_strengths === null ? "" : resAtiopms?.data?.employee_kpstatus_data[0]?.io_strengths,
                        areas_of_imporvement:resAtiopms?.data?.employee_kpstatus_data[0]?.io_areas_of_imporvement === null ? "" : resAtiopms?.data?.employee_kpstatus_data[0]?.io_areas_of_imporvement,
                        carrer_aspirations:resAtiopms?.data?.employee_kpstatus_data[0]?.io_carrer_aspirations === null ? "" : resAtiopms?.data?.employee_kpstatus_data[0]?.io_carrer_aspirations,
                        addl_key_objective:resAtiopms?.data?.employee_kpstatus_data[0]?.io_addl_key_objective === null ? '' : resAtiopms?.data?.employee_kpstatus_data[0]?.io_addl_key_objective,

                        promotionStatus:resAtiopms?.data?.employee_io_status_data[0]?.io_promotion_status === null ? '' : resAtiopms?.data?.employee_io_status_data[0]?.io_promotion_status,
                        promotionRemarks:resAtiopms?.data?.employee_io_status_data[0]?.io_promotion_remarks === null ? '' : resAtiopms?.data?.employee_io_status_data[0]?.io_promotion_remarks,
                         
                        salaryCorrection:resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction === null ? '' : resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction,
                        salaryCorrectionAmount:resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction_amount === null ? '' : resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction_amount,

                        salaryCorrectionRemarks:resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction_remarks === null ? '' : resAtiopms?.data?.employee_io_status_data[0]?.io_salary_correction_remarks,
                        
                        empSelfFinalMarks:resAtiopms?.data?.employee_ratings_data[0]?.total_avg_emp,
                        empSelfFinalRating:resAtiopms?.data?.employee_ratings_data[0]?.emp_rating,
                    }))

                    } else { console.log(null)}
                }
                setEmployeeKpStatusDataAtIoPms(resAtiopms?.data?.employee_kpstatus_data);
                setEmployeeRatingDataAtIoPms(resAtiopms?.data?.employee_ratings_data);
            }
            else if (resAtiopms.data.scode === "02") {
                Sweetalert(resAtiopms.data.sdesc, 'warning')
            }
        })
    }

    useEffect(() => {
        GetApprialDataAtIppms();
    }, []);
    function CalculationAtippms(gradeIopms, weightageIopms, indexIopms, rowidIopms) {
        let TotalAtio = 0;
        if (gradeIopms === "A") {
            TotalAtio = (parseFloat(weightageIopms)/100)*100;
        }
        else if (gradeIopms === "B") {
            TotalAtio = (parseFloat(weightageIopms)/100)*80;
        }
        else if (gradeIopms === "C") {
            TotalAtio = (parseFloat(weightageIopms)/100)*60;
        }
        else if (gradeIopms === "D") {
            TotalAtio = (parseFloat(weightageIopms)/100)*50;
        }
        ioPmsformIk.setFieldValue(`kp${rowidIopms}[${indexIopms}].io_rating_weightage`, TotalAtio);
    }
    const navigateAtIopms = useNavigate();
    const EmpsubmitAlertAtIoPms = (valuesAtio, payload2Atip) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let techFlagAtiopms = Object.keys(valuesAtio).includes("kp4");
                let techCountAtiopms = Object.keys(valuesAtio).includes("kp4") === true ? valuesAtio["kp4"] : [];
                let techFlagCountsAtiopms = {};
                techCountAtiopms.forEach(el => {
                    techFlagCountsAtiopms[el.rating] = (techFlagCountsAtiopms[el.rating] || 0) + 1;
                })
                if (techFlagAtiopms) {
                    if (techCountAtiopms.length === techFlagCountsAtiopms["NA"]) {
                        Sweetalert("Please give any one Technical Skill rating is needed, please check it once", 'warning');
                        return false;
                    }

                }
                CommonPost.kpAreasEmployeepostn(valuesAtio, ROLE_IO).then((resAtip) => {if (resAtip.data.scode === "01") {
                            CommonPost.ioPMSFinalStatusPost(payload2Atip, ROLE_IO).then((res) => {
                                if (res.data.scode === "01") {
                                    SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                        if (isConfirm.value) {
                                            navigateAtIopms(`/loginwisereport/${"IO"}`)
                                        }
                                    })
                                  
                                }
                                else {
                                    Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                                }
                            }).catch((err) => {console.log("err",err) });

                        }
                        else if (resAtip.data.scode === "02") {
                            Sweetalert(resAtip.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const calculateAverageAtIo = (kpAtippms) => {
        return parseFloat(
            (ioPmsformIk?.values[kpAtippms]?.reduce((a, v) => a + v?.io_rating_weightage, 0) / ioPmsformIk?.values[kpAtippms]?.filter((rowdata) => rowdata.rating !== "NA")?.length).toFixed(2)
        );
    }
    const totalAverageAtIopms = parseFloat(['kp1', 'kp2', 'kp3', 'kp4', 'kp5'].reduce((acc, kp) => acc + calculateAverageAtIo(kp), 0)).toFixed(2);

    const getGradeAtIopms = (averageAtiopms) => {
        if (averageAtiopms >= 90 && averageAtiopms <= 100) {
            return "A";
        } else if (averageAtiopms >= 80 && averageAtiopms < 90) {
            return "B";
        } else if (averageAtiopms >= 60 && averageAtiopms < 80) {
            return "C";
        } else if (averageAtiopms < 60 && averageAtiopms > 0) {
            return "D";
        }
        return "";
    };

    const gradeAtIP = getGradeAtIopms(totalAverageAtIopms);
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item">

                        <Link to={`/loginwisereport/${"IO"}`}>My Team Appraisal-IO</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                </ol>
            </div>
        </div>
        <div style={{ marginBottom: '48px' }}> <h2> Appraisal Form </h2></div>
        <div className="card" >
            <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span> Name (ID):</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.emp_name}({showEmpDataAtIoPmsForm[0]?.emp_id})</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Date of Joining:</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.doj.split("-").reverse().join("/")}</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Wing:</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.zone_name}</b></span>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span> IO Name(ID):</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.io_emp_name}({showEmpDataAtIoPmsForm[0]?.io_emp_id})</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>RO Name(ID):</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.ro_emp_name}({showEmpDataAtIoPmsForm[0]?.ro_emp_id})</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Designation</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.designation_name}</b></span>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Email:</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.email}</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Band:</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.band}</b></span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span>Contact:</span>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                    <span><b>{showEmpDataAtIoPmsForm[0]?.mobile_no}</b></span>
                </jnb.Col>
            </jnb.Row>
            <br />
        </div>
        <FormikProvider value={ioPmsformIk}>
            <Form onSubmit={ioPmsformIk.handleSubmit} onChange={ioPmsformIk.handleChange}>
                {showEmpKpDataAtIoPmsForm !== null && showEmpKpDataAtIoPmsForm.map((row, i) => {
                    return (<React.Fragment key={i}>

                        <div className="table-responsive">
                            <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                            <br></br>
                            <table className="table table-condensed table-bordered table-striped table-responsive"
                                style={{ border: '3px solid lightblue', width: '87%', borderCollapse: 'collapse', }}>
                                <thead>
                                    <tr>
                                        <th align="center" >S.No</th>
                                        <th>Key Performance Areas Description</th>
                                        <th>Self Rating</th>
                                        <th> Self Remarks</th>
                                        <th >Rating <font style={{ color: "red" }}>*</font></th>
                                        <th >Remarks <font style={{ color: "red" }}>*</font></th>

                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    {
                                        showIOEmpKpStatusData !== undefined && showIOEmpKpStatusData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                            return (
                                                <React.Fragment key={k}>
                                                    <tr>
                                                        <td >{k + 1}
                                                        </td>
                                                        <td>  {kpidata.kp_indicator_id >= 9990 ? (<>
                                                            <span style={{ color: "blue" }}>{kpidata.kp_indicator_description}</span></>)
                                                            : (<>{kpidata.kp_indicator_description}</>)}
                                                            {kpidata.mandatory_kp_flag === true ? (<> <font style={{ color: "red" }}>*</font>
                                                            </>) : (<></>)}
                                                        </td>
                                                        <td>{kpidata.emp_self_rating}</td>
                                                        <td>{kpidata.emp_self_rating_remarks}</td>
                                                        <td >
                                                            <Field as="select" className='form-control' name={`kp${row.kp_id}[${k}].rating`}
                                                                onChange={(e) => { CalculationAtippms(e.target.value, row.kp_weightage, k, row.kp_id) }}>
                                                                <option value="">--Select--</option>
                                                                <option value="A">A</option>
                                                                <option value="B">B</option>
                                                                <option value="C">C</option>
                                                                <option value="D">D</option>
                                                                <option value="NA">NA</option>
                                                            </Field>
                                                            <ErrorMessage name={`kp${row.kp_id}[${k}].rating`} component="div" className="text-danger" ></ErrorMessage>
                                                        </td>
                                                        <td >
                                                            <Field as="textarea" className="form-control" name={`kp${row.kp_id}[${k}].kpremarks`} ></Field>
                                                            <ErrorMessage name={`kp${row.kp_id}[${k}].kpremarks`} component="div" className="text-danger" ></ErrorMessage></td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>)
                })}
                <table className="table table-condensed table-bordered table-striped table-responsive"
                    style={{ border: '3px solid lightblue', width: '47%', borderCollapse: 'collapse', }}>
                    <tbody style={{ textAlign: "left" }}>
                        <tr>
                            <td>Employee Score :</td>
                            <td style={{ color: "green" }}>{(showEmployeeRatingDataAtIoPms[0]?.total_avg_emp)?.toFixed(2)}</td>
                            <td>IO Score :</td>
                            <td style={{ color: "green" }}>{totalAverageAtIopms && <b>{totalAverageAtIopms}</b>}</td>

                        </tr>
                        <tr>
                            <td> Employee Rating :</td>
                            <td style={{ color: "green" }}>{showEmployeeRatingDataAtIoPms[0]?.emp_rating}</td>
                            <td> IO Rating :</td>
                            <td style={{ color: "green" }}>{(gradeAtIP && <b>{gradeAtIP}</b>)}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="table-responsive">
                    <h4 style={{ fontSize: "16px" }}> </h4>
                    <br></br>
                    <table className="table table-condensed table-bordered table-striped table-responsive"
                        style={{ border: '3px solid lightblue', width: '87%', borderCollapse: 'collapse', }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Self remarks</th>
                                <th> IO Remarks</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "left" }}>
                            <tr>
                                <td> Employee Strengths <font style={{ color: "red" }}>*</font></td>
                                <td>{showEmployeeKpStatusDataAtIoPms[0]?.self_strengths}</td>
                                <td>  <Field as="textarea" className="form-control" name="strengthsWeaknesses" maxLength="400"></Field>
                                    <ErrorMessage name="strengthsWeaknesses" component="div" className="text-danger" ></ErrorMessage></td>


                            </tr>
                            <tr>
                                <td>Areas for Improvement<font style={{ color: "red" }}>*</font></td>
                                <td>{showEmployeeKpStatusDataAtIoPms[0]?.self_areas_of_imporvement}</td>
                                <td><jnb.InputGroup className="mb-3p5">
                                    <Field as="textarea" className="form-control" name="areas_of_imporvement" maxLength="400"></Field>
                                </jnb.InputGroup>
                                    <ErrorMessage name="areas_of_imporvement" component="div" className="text-danger" ></ErrorMessage></td>

                            </tr>
                            <tr>
                                <td>  Career Aspirations<font style={{ color: "red" }}>*</font> </td>
                                <td><b>{showEmployeeKpStatusDataAtIoPms[0]?.self_carrer_aspirations}</b></td>
                                <td>
                                    <jnb.InputGroup className="mb-3p5">
                                        <Field as="textarea" className="form-control" name="carrer_aspirations" maxLength="400"></Field>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="carrer_aspirations" component="div" className="text-danger" ></ErrorMessage>
                                </td>
                            </tr>
                            <tr>
                                <td>Additional accomplishments not covered in Key Objectives above<font style={{ color: "red" }}>*</font></td>
                                <td>{showEmployeeKpStatusDataAtIoPms[0]?.self_addl_key_objective}</td>
                                <td>
                                    <jnb.InputGroup className="mb-3p5">
                                        <Field as="textarea" className="form-control" name="addl_key_objective" maxLength="400"></Field>
                                    </jnb.InputGroup>
                                    <ErrorMessage name="addl_key_objective" component="div" className="text-danger" ></ErrorMessage>
                                </td>
                            </tr>
                            <tr>
                                <td>Upload Any Relevant Document</td>
                                {(showEmpDataAtIoPmsForm[0]?.file_upload != undefined && showEmpDataAtIoPmsForm[0]?.file_upload != "") ? (<>
                                    <td>  <FaFilePdf style={{ fontWeight: "bold", fontSize: "30px", color: "blue", cursor: "pointer" }}
                                        onClick={(e) => { getPDFView(showEmpDataAtIoPmsForm[0]?.file_upload) }} /></td>
                                    <td> <jnb.InputGroup className="mb-3p5">
                                        <Field as="textarea" className="form-control" name="io_upload_remarks" maxLength="400"></Field>
                                    </jnb.InputGroup>
                                        <ErrorMessage name="io_upload_remarks" component="div" className="text-danger" ></ErrorMessage></td>
                                </>) : (<><td colSpan={2}><b>Document not Uploaded</b></td>
                                </>)}

                            </tr>


                        </tbody>
                    </table>
                </div>
                <div className="table-responsive">
                    <h4 style={{ fontSize: "16px" }}> </h4>
                    <br></br>
                    <table className="table table-condensed table-bordered table-striped table-responsive"
                        style={{ border: '3px solid lightblue', width: '87%', borderCollapse: 'collapse', }}>
                        <tbody>
                            <tr >
                                <td width="250px" height={15}><span style={{ fontSize: "14px" }}><b>I am Referring for Promotion </b></span>&nbsp;&nbsp;</td>
                                <td>
                                    <Field type="checkbox" name="promotionStatus" onChange={(e) => { ioPmsformIk.setFieldValue("promotionRemarks", "") }}></Field>
                                    <ErrorMessage name="promotionStatus" component="div" className="text-error" />
                                </td>

                                {ioPmsformIk.values.promotionStatus === true && (<>
                                    <td><span className="label-text" style={{ fontSize: "14px" }}><b>Remarks:</b><font style={{ color: "red" }}>*</font></span></td>
                                    <td>
                                        <Field as="textarea" className="form-control" name="promotionRemarks" maxLength="400"></Field>
                                        <ErrorMessage name="promotionRemarks" component="div" className="text-danger" ></ErrorMessage>
                                    </td>
                                </>)}
                            </tr>
                            <tr>
                                <td>  <span className="label-text" style={{ fontSize: "14px" }}><b>Salary Correction:</b><font style={{ color: "red" }}>*</font></span></td>
                                <td>
                                    <Field type="radio" name="salaryCorrection" value="Y" onChange={(e) => {
                                        ioPmsformIk.setFieldValue("salaryCorrectionAmount", ""); ioPmsformIk.setFieldValue("salaryCorrectionRemarks", "")
                                    }} />&nbsp;&nbsp;Yes&nbsp;&nbsp;
                                    <Field type="radio" name="salaryCorrection" value="N" />&nbsp;&nbsp;No
                                    <ErrorMessage name="salaryCorrection" component="div" className="text-error" />
                                </td>
                                {ioPmsformIk.values.salaryCorrection === "Y" && (<>
                                    <td>   <span className="label-text" style={{ fontSize: "14px" }}><b>Remarks:</b><font style={{ color: "red" }}>*</font></span></td>
                                    <td>  <Field as="textarea" className="form-control" name="salaryCorrectionRemarks" maxLength="400"></Field>
                                        <ErrorMessage name="salaryCorrectionRemarks" component="div" className="text-danger" ></ErrorMessage></td>
                                    <td>   <span className="label-text"><b>Amount:( per Annum) </b><font style={{ color: "red" }}>*</font></span></td>
                                    <td>
                                        <Field type="text" className="form-control" name="salaryCorrectionAmount" onKeyPress={(e) => allowNumbersOnly(e)}
                                            maxLength="7"></Field>
                                        <ErrorMessage name="salaryCorrectionAmount" component="div" className="text-danger" ></ErrorMessage>
                                    </td>
                                </>)}
                            </tr>
                        </tbody>
                    </table>
                </div><br />
                {showEmpDataAtIoPmsForm[0]?.io_final_status ? (<>
                    <center> <h6 style={{ color: "green" }}>* Note: Your Appraisal Form forwarded to next level.</h6 ></center>
                </>) : (<>

                    <h6 style={{ color: "red" }}>* Note: If you checked below checkbox, it will be considered as final submit, it will go to next level and your data
                        has been freezed,modifications will not be allowed.</h6 >
                    <br />
                    <h4>
                        <Field type="checkbox" name="finalStatus"></Field>&nbsp;&nbsp;<b>Are you sure you want to confirm the data<font style={{ color: "red" }}>*</font></b>
                        <ErrorMessage name="finalStatus" component="div" className="text-error" /></h4>

                    <div className="text-end mb-3">
                        <button type="submit" className="btn rounded-0 btn-success btn-sm">{ioPmsformIk?.values?.finalStatus === true ? (<>Final Submit</>) : (<>Save</>)}</button>
                    </div>
                </>
                )}
            </Form>
        </FormikProvider>
    </>)
}