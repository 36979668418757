export function allowAlphabetAndSpacesOnly(e) {
  var keyCode = e.charCode;

  var value = Number(e.target.value + e.key) || 0;

  if (
    !(keyCode >= 65 && keyCode <= 123) &&
    keyCode !== 32 &&
    keyCode !== 0 &&
    keyCode !== 40 &&
    keyCode !== 41
  ) {
    e.preventDefault();
  } else {
    return value;
  }

  return false;
}

export default function allowNumbersOnly(e) {
  var keyCode = e.keyCode === 0 ? e.charCode : e.keyCode;

  var value = Number(e.target.value + e.key) || 0;

  if (keyCode >= 48 && keyCode <= 57) {
    return isValidNumber(value);
  } else {
    e.preventDefault();
  }

  return false;
}

const isValidNumber = (number) => {
  return 1 <= number && number <= 10;
};
export const AADHAR_VALIDATION = /^[1-9]{1}[0-9]{11}$/
export const PAN_VALIDATION = /^[A-Z]{3}P[A-Z]\d{4}[A-Z]$/;
export const IFSC_VALIDATION = /^[A-Z]{4}0[A-Z 0-9]{6}$/;
export const isValidPanNumber = (panNumber) => {
  const PAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  return PAN.test(panNumber);
};

export function allowIFSCCode(e) {
  const keyCode = e.keyCode === 0 ? e.charCode : e.keyCode; // Get the key code
  const value = e.target.value; // Current value of the input
  const key = e.key; // The key being pressed

  // Allow backspace, delete, and arrow keys
  if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
    return;
  }

  // Prevent input if length is already 11
  if (value.length >= 11) {
    e.preventDefault();
    return;
  }

  // Validate the character being input based on the current length of the value
  if (value.length < 4) {
    // First four characters must be uppercase letters (A-Z)
    if (!/^[A-Za-z]$/.test(key)) {
      e.preventDefault();
    }
  } else if (value.length === 4) {
    // Fifth character must be '0'
    if (key !== '0') {
      e.preventDefault();
    }
  } else if (value.length >= 5 && value.length < 11) {
    // Last six characters can be uppercase letters (A-Z) or digits (0-9)
    if (!/^[A-Za-z0-9]$/.test(key)) {
      e.preventDefault();
    }
  }
}
