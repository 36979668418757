import { useLocation, Link } from "react-router-dom";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import { config } from "../../../CommonUtils/CommonApis";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import { IoPrint } from "react-icons/io5";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_HR, ROLE_IO, ROLE_RO } from "../../../CommonUtils/ApplicationRoles";
export default function EmployeeProfilePrint() {
    const loaction = useLocation();
    const employeeId = loaction?.state?.employeeid;
    const pagenameFlag = loaction?.state?.pageFlag;
    const [showpageFlag, setpageFlag] = useState(pagenameFlag);
    const [showPrintEmpData, setPrintEmpData] = useState([]);
    const [showPrintEmpKpData, setPrintEmpKpData] = useState([]);
    const [showPrintEmpKpStatusData, setPrintEmpKpStatusData] = useState([]);
    const [empFile, setEmpFile] = useState('');
    useEffect(() => {
        let Url = (config.url.COMMON_URL + "employee/appraisal/" + pagenameFlag + "/" + employeeId);//old
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setPrintEmpData(res.data.employee_data);
                setPrintEmpKpData(res.data.employee_kp_data);
                setPrintEmpKpStatusData(res.data.employee_kpstatus_data)
                setEmpFile(res.data.file_upload);
                setpageFlag(pagenameFlag);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }, []);
    const ref1 = useRef();
    const ComponentToPrint = forwardRef((props, ref) => {
        return (<>
            <div ref={ref}> <>
                <legend><b>Employee Details</b></legend>
                <table className="table table-condensed table-bordered table-striped table-responsive">
                    <tbody>
                        <tr>
                            <th>Employee (ID):</th><td>{showPrintEmpData[0]?.emp_id}</td>
                            <th>Employee Name:</th><td>{showPrintEmpData[0]?.emp_name}</td>
                            <th>Designation:</th><td>{showPrintEmpData[0]?.designation_name}</td>
                        </tr>
                        <tr>
                            <th>Date of Joining:</th><td>{showPrintEmpData[0]?.doj?.split("-")?.reverse()?.join("/")}</td>
                            <th> IO Name(ID):</th><td>{showPrintEmpData[0]?.io_emp_name}({showPrintEmpData[0]?.io_emp_id})</td>
                            <th>RO Name(ID):</th><td>{showPrintEmpData[0]?.ro_emp_name}({showPrintEmpData[0]?.ro_emp_id})</td>
                        </tr>
                    </tbody>
                </table>
                {showPrintEmpKpStatusData.length === 0 ? (<center><span className="text-danger" >Pending at Appraise</span></center>) : (<>
                    {showPrintEmpKpData !== undefined && showPrintEmpKpData.map((row, i) => {
                        return (<React.Fragment key={i}>
                            <legend><b>{row.kp_short_description}</b></legend>
                            <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Key Performance Areas Description</th>
                                        <th>Self Rating</th>
                                        <th>Self Remarks</th>
                                        <th>IO Rating</th>
                                        <th>IO Remarks</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {showPrintEmpKpStatusData !== undefined && showPrintEmpKpStatusData.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <tr>
                                                    <td>{k + 1}</td>
                                                    <td>{kpidata.kp_indicator_description}</td>
                                                    <td>{kpidata.emp_self_rating}</td>
                                                    <td>{kpidata.emp_self_rating_remarks}</td>
                                                    <td>{kpidata.io_emp_rating}</td>
                                                    <td>{kpidata.io_emp_remarks}</td>

                                                </tr>
                                            </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </React.Fragment>)
                    })}
                    <table className="table table-condensed table-bordered table-striped table-responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Self remarks</th>
                                <th> IO Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>  Strengths </td>
                                <td><b>{showPrintEmpKpStatusData[0]?.self_strengths}</b></td>
                                <td><b>{showPrintEmpKpStatusData[0]?.io_strengths}</b> </td>
                            </tr>
                            <tr>
                                <td>Areas for Improvement</td>
                                <td><b>{showPrintEmpKpStatusData[0]?.self_areas_of_imporvement}</b></td>
                                <td><b>{showPrintEmpKpStatusData[0]?.io_areas_of_imporvement}</b></td>
                            </tr>
                            <tr>
                                <td> Career Aspirations </td>
                                <td><b>{showPrintEmpKpStatusData[0]?.self_carrer_aspirations}</b></td>
                                <td><b>{showPrintEmpKpStatusData[0]?.io_carrer_aspirations}</b></td>

                            </tr>
                            <tr>
                                <td>Upload Any Relevant Document</td>
                                <td>{(empFile != undefined && empFile != "") ? (<a href={empFile} target="_blank" download={"Employee_Document"}>View</a>) : null}</td>
                                <td><b>{showPrintEmpData[0]?.io_file_remarks}</b></td>
                            </tr>
                            <tr>
                                <td>Additional accomplishments not covered in Key Objectives above</td>
                                <td>{showPrintEmpKpStatusData[0]?.self_addl_key_objective}</td>
                                <td><b>{showPrintEmpKpStatusData[0]?.io_addl_key_objective}</b></td>
                            </tr>
                        </tbody>
                    </table>

                    {(showpageFlag === ROLE_RO || showpageFlag === "HR") && (<>
                        <table className="table table-condensed table-bordered table-striped table-responsive">
                            <thead>
                                <th></th>
                                <th></th>
                                <th>Remarks</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>  Final Rating: </td>
                                    <td><b>{showPrintEmpData[0]?.ro_final_rating}</b></td>
                                    <td><b>{showPrintEmpData[0]?.ro_final_remarks}</b> </td>

                                </tr>
                                <tr>
                                    <td>Referring for Promotion </td>
                                    {showPrintEmpData[0]?.promotion_status === true ? (<>
                                        <td><b>Yes</b></td>
                                        <td><b>{showPrintEmpData[0]?.promotion_remarks}</b></td></>) : (<>
                                            {showPrintEmpData[0]?.promotion_status === null ? null : (<td colSpan={2}><b>Not Promoting</b></td>)}
                                        </>)}
                                </tr>
                                <tr>
                                    <td>Salary Correction</td>
                                    {showPrintEmpData[0]?.salary_correction === "Y" ? (<><td><b>Yes</b></td>
                                        <td>amount:<b> {showPrintEmpData[0]?.salary_correction_amount}-{showPrintEmpData[0]?.salary_correction_remarks} </b></td>
                                    </>) : (<> {showPrintEmpData[0]?.salary_correction === null ? (<></>) : (<>
                                        <td colSpan={2}><b>No Salary Correction</b></td></>)}
                                    </>)}
                                </tr>
                                <tr>
                                    <td>Strengths</td>

                                    <td colSpan={2}><b>{showPrintEmpData[0]?.strengths_weaknesses}</b></td>
                                </tr>
                                <tr>
                                    <td>Areas for Improvement</td>
                                    <td colSpan={2}>{showPrintEmpData[0]?.weaknesses}</td>

                                </tr>
                            </tbody>
                        </table>
                    </>)}
                </>)}
            </>
            </div></>)
    });

    return (
        <>
            <div class="card">
                <div class="card-body">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        {showpageFlag === ROLE_IO ? (<>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"IO"}`}>My Team Appraisal-IO</Link>
                            </li>
                        </>) : (<>{showpageFlag === ROLE_RO ? (<>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"RO"}`}>My Team Appraisal-RO</Link>
                            </li>
                        </>) : (<>{showpageFlag === ROLE_HR ? (<>
                            <li className="breadcrumb-item">
                                <Link to={"/hradmindashboard"}>Key Performance Abstract Report</Link>
                            </li>
                        </>) : (<></>)}
                        </>)}</>)}

                        <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                    </ol>
                </div>
            </div>
            <ComponentToPrint ref={ref1} />
            {showPrintEmpKpStatusData.length === 0 ? null : (
                <ReactToPrint content={() => ref1.current}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <div className="text-center">
                                <button
                                    className="btn btn-md"
                                    style={{ backgroundColor: "skyblue" }}
                                    variant="primary"
                                    onClick={() => { handlePrint(); }
                                    }>
                                    <IoPrint />&nbsp;
                                    Print</button>
                            </div>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
            )}



        </>
    )
}