import { store } from "../store";
import { PRR_Docs_Pdf } from "./CommonApis";
import { CommonAjaxPostWithFileUpload } from "./CommonAxios";
import Sweetalert from "./SweetAlerts";
export function uploadPdf(eventAtUploadPDf, fieldNameAtUploadPDf, formikAtUploadPDf,yearAtUploadPDf,empidAtUploadPDf) {  
    let msgAtUploadPDf = '';
    let allowedExtensionsAtPdf = ['pdf'];
    let uploadedFileExtensionPdf = eventAtUploadPDf.currentTarget.files[0].name.split('.').pop();
    let checkFileSizeAtPdf = checkUploadFileSize(eventAtUploadPDf)
    const formDataAtPdf = new FormData();
    if (allowedExtensionsAtPdf.includes(uploadedFileExtensionPdf.toLowerCase()) && !checkFileSizeAtPdf) {
        formDataAtPdf.append('file', eventAtUploadPDf.currentTarget.files[0]);
        CommonAjaxPostWithFileUpload(PRR_Docs_Pdf+yearAtUploadPDf+"/"+empidAtUploadPDf, formDataAtPdf).then(responseAtPdf => {
            if (responseAtPdf !== undefined && responseAtPdf !== null && responseAtPdf !== '') {
                if(JSON.stringify(responseAtPdf.data).includes(uploadedFileExtensionPdf))
                {
                    formikAtUploadPDf.setFieldValue(fieldNameAtUploadPDf, responseAtPdf.data);//
                }  
            }
            else {
                store.dispatch({ type: 'HIDE_SPINNER', payload: { key: 'showSpinner', value: false } });
            }
        })
    }
    else {
        msgAtUploadPDf = checkFileSizeAtPdf ? "File size exceeds 1 MB" : "please upload file in Pdf format only";
        Sweetalert(msgAtUploadPDf, "warning")
        document.getElementById(fieldNameAtUploadPDf).value = '';
    }
}
export function checkUploadFileSize(eventAtFilesize) {
    var _sizeAtPsf = eventAtFilesize.currentTarget.files[0].size;
    let resultAtPdf = '';
    var exactSizeAtPdf = (Math.round(_sizeAtPsf / 1024));
    if (exactSizeAtPdf > 1000) {
        resultAtPdf = true;
    }
    else {
        resultAtPdf = false;
    }
    return resultAtPdf
}
