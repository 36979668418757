const modalPopupInitialVaues = {
  showViewModal: false,
  showFingerPrintPopup : false

};

export function ModalPopupReducer(state = modalPopupInitialVaues, action) {
  switch (action.type) {

    case "SHOW_VIEW_POPUP":
      return { ...state, showViewModal: action.payload };
    case "SHOW_FINGERPRINT_POPUP":
      return { ...state, showFingerPrintPopup: action.payload };
    default:
      return state;
  }
}