import { ErrorMessage, Field, FieldArray, Form, FormikProvider, useFormik } from "formik";
import React, { useState,useEffect} from "react";
import { BiTrash } from "react-icons/bi";
import { VscDiffAdded } from "react-icons/vsc";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import * as Yup from "yup";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { config } from "../../../CommonUtils/CommonApis";
import { Link } from "react-router-dom";
export default function KeyPerformanceMasterForm(){
    const Formvalidation = Yup.object().shape({
        kp: Yup.array().of(Yup.object().shape({
            kpDescription: Yup.string().required("Please Enter KP Descrption").nullable(),
            kpSubIndicatorFlag: Yup.string().required("Please Select Kp SubIndicatorFlag").nullable(),
            kpShortDescription: Yup.string().required("Please Enter Kp Short Descrption").nullable(),
        })
        ),
    });
    const uploadvalidation = Yup.object().shape({
        file: Yup.string().required("Please Upload Excel").nullable(),
    });
    const showEntryInitialValues = {
        kp: [{ kpDescription: "", kpSubIndicatorFlag: "", kpShortDescription: "" }],
        file: "",
    };
    const [showTypeSave, setTypeSave] = useState("");
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: showEntryInitialValues,
        onSubmit: (values) => {
            showTypeSave === "EntryForm" ? (EntryFormAlert(values)) : (UploadAlert(values))
        },
        validationSchema: showTypeSave === "EntryForm" ? Formvalidation : uploadvalidation,
    });
    const EntryFormAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.kpMasterFormPost(values.kp)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetKeyPerformanceData();
                                    formIk.setFieldValue("kp", [{ kpDescription: "", kpSubIndicatorFlag: "", kpShortDescription: "" }]);
                                }
                            })

                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {console.log("Exception Occured 71 ");});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const UploadAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let file = document.getElementById('file').files[0]
                var formData = new FormData()
                formData.append('file', file)
                CommonPost.kpUploadPost(formData)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            Swal.fire('Finished', 'Success', 'success',);
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {console.log("Exception Occured 71 ");});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showkeyperformancedata, setkeyperformancedata] = useState([]);
    function GetKeyPerformanceData() {
        CommonAxiosGet(config.url.COMMON_URL + "keyperformances/A").then((res) => {
            if (res.data.scode === "01") {
                setkeyperformancedata(res.data.data);
                setkeyperformancedata((data) => {
                    const dataToSort = [...data];
                    dataToSort.sort((a, b) => Number(a.kpId) - Number(b.kpId));
                    return dataToSort;
                });
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, "warning");
            }
        })
    }
    useEffect(() => {
        GetKeyPerformanceData();
    }, [])

    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Key Performance Form</li>
                </ol>
            </div>
        </div>
        <div className="head-text  mt-4">
            <h1>
                Key Performance
            </h1>

        </div>
        <div className="border p-3 pb-0 mb-3">
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
               <div className="table-responsive">
                    <table className="table table-condensed table-bordered table-striped table-responsive">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Key Performance Indicator Description<font style={{ color: "red" }}>*</font></th>
                                <th> Key Performance Short Description<font style={{ color: "red" }}>*</font></th>
                                <th>Key Performance Sub Indicator Flag<font style={{ color: "red" }}>*</font></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            <FieldArray name="kp">
                                {(formikFieldArray) => {
                                    return (
                                        formikFieldArray.form.values.kp.map(
                                            (qual, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td> <Field type="text" className="form-control" name={`[kp[${index}].kpDescription`} ></Field>
                                                                <ErrorMessage name={`[kp[${index}].kpDescription`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td>
                                                            <td>
                                                                <Field type="text" className="form-control" name={`[kp[${index}].kpShortDescription`} ></Field>
                                                                <ErrorMessage name={`[kp[${index}].kpShortDescription`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td>
                                                            <td>
                                                                <Field as="select" className='form-control' name={`[kp[${index}].kpSubIndicatorFlag`}>
                                                                    <option value="">--Select--</option>
                                                                    <option value="Y">Yes</option>
                                                                    <option value="N">No</option>
                                                                </Field>
                                                                <ErrorMessage name={`[kp[${index}].kpSubIndicatorFlag`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td>
                                                            <td width="1"> {(index) ? 
                                                                <button type="button" className="button-titile btn btn-sm btn-danger"
                                                                    onClick={() => { formikFieldArray.remove(index); }} >
                                                                    <BiTrash></BiTrash></button> : ''}</td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })
                                    )
                                }}
                            </FieldArray>
                            <tr className='table-active'>
                                <td align="right" colSpan={7}>
                                    <FieldArray name="kp">
                                        {(formikFieldArray) => {
                                            return formikFieldArray.form.values.kp.map(
                                                (LJP, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            {(index === 0) ? <>
                                                                <button type="button" className="button-titile btn btn-sm btn-success"
                                                                    onClick={() => {
                                                                        formikFieldArray.push({ kpDescription: "", kpSubIndicatorFlag: "", kpShortDescription: "" });
                                                                    }}>
                                                                    <VscDiffAdded></VscDiffAdded> </button> </> : ''}
                                                        </React.Fragment>
                                                    )
                                                })
                                        }}
                                    </FieldArray>
                                </td>
                            </tr>
                        </tbody >
                    </table>
                    <button type="submit" className="btn rounded-0 btn-success btn-sm" style={{ float: "right" }}
                        onClick={(e) => { setTypeSave("EntryForm") }}>Submit</button>
                </div>
            </Form>
                <legend >Report:</legend>
                <table className="table table-condensed table-bordered table-striped table-responsive">
                    <thead>
                        <tr>
                            <th align="center">S.No</th>
                            <th>Key Performance Indicator Description</th>
                            <th> Key Performance Short Description</th>
                            <th>Key Performance Sub Indicator Flag</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left" }}>
                        {showkeyperformancedata !== undefined && showkeyperformancedata.map((rowdata, i) => {
                            return (<React.Fragment key={i}>
                                <tr>
                                    <td width="100px" align="center">{i + 1}</td>
                                    <td>{rowdata.kpDescription}</td>
                                    <td>{rowdata.kpShortDescription}</td>
                                    <td>{rowdata.kpSubIndicatorFlag === "Y" ? "Yes" : "No"}</td>
                                </tr>
                            </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
        </FormikProvider>
        </div>
    </>)
}     