
import React, { useEffect, useState, useMemo } from "react";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { Link } from "react-router-dom";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
export default function AppraisalRevort() {
    const [showempstatusdata, setempstatusdata] = useState();
    function Getemployeesstatusdata() {
        CommonAxiosGet(config.url.COMMON_URL + "pms/hrstatusreport-new").then((response) => {
            if (response.data.scode === "01") {
                setempstatusdata(response.data.hrstatusreport);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    const RevertAlert = (emp, actiontype) => {
        Swal.fire({
            title: 'Are you sure you want to Revert(' + emp + ')',
            text: 'Please check it once before Reverting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowEnterKey: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                CommonPost.revorteback(emp, actiontype).then((res) => {
                    if (res.data.scode === "01") {
                        SweetalertOKFunction('Successfully Reverted', 'success').then(function (isConfirm) {
                            if (isConfirm.value) { Getemployeesstatusdata(); }
                        })
                    }
                    else if (res.data.scode === "02") { Sweetalert(res.data.sdesc, 'warning'); }
                    else {
                        Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                    }
                }).catch(() => { console.log(null) });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };

    const columns = useMemo(() => [
        {
            Header: " ",
            Footer: ' ',
            name: 'SL.No',
            cell: (index) => index + 1,
            selector: row => row.slno,
            width: '70px'
        },
        {
            name: 'ID',
            selector: row => row.emp_id,
            sortable: true,
            width: '97px'
        },
        {
            name: 'Name',
            selector: row => row.emp_name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Zone/Designation',
            selector: row => (<>{row.zone_name}({row.designation_name})</>),
            sortable: true,
            wrap: true,
        },

        {
            name: 'DOJ',
            selector: row => (<>{row.doj}</>),
            sortable: true,
        },

        {
            name: 'IO',
            selector: row => (<>{row.io}-{row.io_emp_name}</>),
            sortable: true,
            wrap: true
        },
        {
            name: 'RO',
            selector: row => (<>{row.ro}-{row.ro_emp_name}</>),
            sortable: true,
            wrap: true
        },
        {
            name: 'Self Status',
            selector: row => (<>{row.self_status_flag === "Y" ? (<button type="button" className="btn rounded btn-primary btn-sm"
                onClick={() => {
                    if (row.ro_status_flag === "Y" || row.io_status_flag === "Y") {
                        Swal.fire("Appraisal has completed in next (IO, RO) level", 'Please revert higher (IO, RO) level first', 'warning');
                    }
                    else { RevertAlert(row.emp_id, "SELF"); }
                }}>Self Revert</button>) : (<>{row.self_status}</>)}</>),
            sortable: true,
            wrap: true
        },

        {
            name: 'IO Status',
            selector: row => (<>{row.io_status_flag === "Y" ? (<button type="button" className="btn rounded btn-primary btn-sm"
                onClick={() => {
                    if (row.ro_status_flag === "Y") { Swal.fire("Appraisal has completed in RO level", 'Please revert RO level first', 'warning'); }
                    else { RevertAlert(row.emp_id, "IO"); }
                }}>IO Revert</button>) : (<>{row.io_status}</>)}</>),
            sortable: true,
            wrap: true
        },
        {
            name: 'RO Status',
            selector: row => (<>{row.ro_status_flag === "Y" ? (<button type="button" className="btn rounded btn-primary btn-sm"
                onClick={() => { RevertAlert(row.emp_id, "RO") }}>RO Revert</button>) : (<>{row.ro_status}</>)}</>),
            sortable: true,
            wrap: true
        },
    ], []);
    useEffect(() => {
        Getemployeesstatusdata();
    }, [])
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee Status Revert</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"> <h1>Employee Status Revert</h1></div>
        <div className="border p-3 pb-0 mb-3 ">
            <div className="my-custom-scrollbar">
                <div className="inner-herbpage-service-title-sub mb-4p5">
                    <h1>List of Data</h1>
                </div>
                <div >
                    <DataTableExtensions export={false} print={false}
                        columns={columns} data={showempstatusdata} filterPlaceholder='Search'>
                        <DataTable columns={columns} pagination={true}
                            paginationPerPage="10" data={showempstatusdata}
                            keyField="emp_id"
                        />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
    </>)
}