
// import React from "react";
import {FormikProvider, useFormik} from "formik";
import {Form} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
// import React,{ useEffect, useState } from "react";
import { useEffect, useState } from "react";
// import { config } from "../../CommonUtils/CommonApis";
// import { CommonAxiosGet } from "../../CommonUtils/CommonAxios";
import { BsEye } from "react-icons/bs";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";



export default function JobPostingFormDetails() {
    const [GetJobPostDetails,setJobPostDetails]=useState([])
    const navigate = useNavigate();
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {},
        onSubmit: (values) => {
            
            console.log(values)
        },
    });
    useEffect(() => {

        let Url = (config.url.COMMON_URL + "jobdtls");
        CommonAxiosGet(Url).then((res) => {
            console.log("res.data.alldata", res.data.alldata);
            if (res.data.status === true) {
                setJobPostDetails(res.data.alldata)
            }
            else {
                setJobPostDetails([])
                // Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }, []);
    function NavigateToCandidateEntryform(data){
        navigate("/JobApplication")
        localStorage.setItem("jobDetails", JSON.stringify(data));
    } 
    return (<>
     <jnb.Row className="m-0">
     <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
     <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Job Openings</li>
                </ol>
     </jnb.Col>
     </jnb.Row>
      
        <div className="head-text mt-3"> <h1> Job Openings </h1> </div>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <jnb.Row className="px-3 pt-2">
                <table className="table table-condensed table-bordered table-striped table-responsive">
                        <thead>
                            <tr>
                                <th align="center">S.No</th>
                                <th>Technologies</th>
                                <th>Requirement Years</th>
                                <th>Designation</th>
                                <th>Location</th>
                                <th>Immediate Joining</th>
                                <th>View Details</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "left" }}>
                           
                            {GetJobPostDetails !== undefined && GetJobPostDetails.map((data, i) => {
                                return (
                                // <React.Fragment key={i}>
                                < >
                                    <tr key={i}>
                                        <td width="100px" align="center">{i + 1}</td>
                                        <td>{data.zone_name}</td>
                                        <td>{data.requirement_years}</td>
                                        <td>{data.designation_name}</td>
                                        <td>{data.job_location}</td>
                                        <td>{data.immediate_joining}</td>
                                       <td align="center">
                                       {/* <FaEdit className="icon" size={'1.6rem'} color="skyblue" onClick={() => {
                                                                    NavigateToCandidateEntryform(data)
                                                                }} /> */}
                                        <button type="button" className=" btn btn-primary btn-sm  bg-opacity-50 "
                                        onClick={() => {NavigateToCandidateEntryform(data)}}><BsEye /></button>
                                        </td>
                                    </tr>
                                    </>
                                // {/* </React.Fragment> */}
                                )
                            })}
                        </tbody>
                    </table>
                </jnb.Row>
            </Form>
        </FormikProvider>
    </>)
}