import { ErrorMessage, Field, FormikProvider, useFormik,Form} from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useEffect, useState } from "react";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import * as Yup from "yup";
import Select from 'react-select';
import allowNumbersOnly, { allowAlphabetAndSpacesOnly } from "../../../CommonUtils/CommonValidations";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
export default function EmployeeEntryForm() {
    const validationAtempEntry = Yup.object().shape({
        empId: Yup.string().required("Please Enter Employee ID").nullable(),
        empName: Yup.string().required("Please Enter Employee Name").nullable(),
        dob: Yup.string().required("Please select Employee Date of Birth").nullable(),
        doj: Yup.string().required("Please select Employee Date of Joining").nullable(),
        gender: Yup.string().required("Please select gender").nullable(),
        email: Yup.string().required("Please Enter Email").nullable(),
        mobileNo: Yup.string().required("Please Enter Mobile No").nullable(),
        zoneId: Yup.string().required("Please select ZoneID").nullable(),
        designationId: Yup.string().required("Please select Designation Id").nullable(),
        ioEmpId: Yup.string().required("Please select IO Employee Id").nullable(),
        roEmpId: Yup.string().required("Please select RO Employee Id").nullable(),
    });
    const showEntryInitialValuesAtempEntry = {
        empId: "",
        empName: "",
        dob: "",
        doj: "",
        gender: "",
        email: "",
        mobileNo: "",
        zoneId: "",
        designationId: "",
        ioEmpId: "",
        roEmpId: "",
    };
    const [showZoneMasterAtempEntry, setZoneMasterAtempEntry] = useState([]);
    const [showDesignationsDataAtempEntry, setDesignationsDataAtempEntry] = useState([]);
    const empEntryformIk = useFormik({
        enableReinitialize: true,
        initialValues: showEntryInitialValuesAtempEntry,
        onSubmit: (values) => {
            EmpEntryFormSaveAlert(values)
        },
         validationSchema: validationAtempEntry,
    });
    const EmpEntryFormSaveAlert = (valuesAtentry) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.empEntryPost(valuesAtentry).then((resAtentry) => {
                        if (resAtentry.data.scode === "01") {
                            Swal.fire('Successfully Submitted', 'Success', 'success',);}
                        else if (resAtentry.data.scode === "02") {Sweetalert(resAtentry.data.sdesc, 'warning');}
                        else {Swal.fire('Canceled', 'Something went wrong,Please check', 'error');}
                    }).catch(() => {console.log("Exception Occured 71 ");});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    function ZonesAtEmpEntry() {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((responseAtempzone) => {
            if (responseAtempzone.data.scode === "01") {
                setZoneMasterAtempEntry(responseAtempzone.data.data);
            } else if (responseAtempzone.data.scode === "02") {
                Sweetalert(responseAtempzone.data.sdesc, "warning");
            }
        });

    }
    function GetdesignationsdataEmpentry(zoneidAtEntry) {
        if (zoneidAtEntry != null && zoneidAtEntry != "" && zoneidAtEntry != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneidAtEntry).then((resAtentryzoneid) => {
                if (resAtentryzoneid.data.scode === "01") {setDesignationsDataAtempEntry(resAtentryzoneid.data.data)}
                else if (resAtentryzoneid.data.scode === "02") {
                    Sweetalert(resAtentryzoneid.data.sdesc, 'warning')
                    setDesignationsDataAtempEntry([])
                }
            })
        }
    }
    const [showemployeelist, setemployeeList] = useState([]);

    function GetemployeesdataAtEmpEntry() {
        CommonAxiosGet(config.url.COMMON_URL + "employeedata").then((responseEmpdata) => {
            setemployeeList(responseEmpdata.data.emp_data);
        });
    }
    const handleZoneAtempEntryForm = (selectedOptionAtentry) => {
        if (selectedOptionAtentry !== null) {
            empEntryformIk.setFieldValue("zoneId", selectedOptionAtentry.zone_id)
            empEntryformIk.setFieldValue("designationId", "")
            GetdesignationsdataEmpentry(selectedOptionAtentry.zone_id);}
        else {empEntryformIk.setFieldValue("zoneId", "")}
    }
    const handleDesignationEntryForm = (selectedOptionEntry) => {
        if (selectedOptionEntry !== null) {empEntryformIk.setFieldValue("designationId", selectedOptionEntry.designation_id)}
        else {empEntryformIk.setFieldValue("designationId", "")}
    }
    useEffect(() => {
        GetemployeesdataAtEmpEntry();
        ZonesAtEmpEntry();
    }, [])
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Employee Entry Form</li>
                </ol>
            </div>
        </div>
        <div className="head-text  mt-4"> <h1> Employee Entry Form </h1> </div>
        <FormikProvider value={empEntryformIk}>
            <Form onSubmit={empEntryformIk.handleSubmit} onChange={empEntryformIk.handleChange}>
                <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee ID:<font style={{ color: "red" }}>*</font></b></span>
                            <Field type="text" name="empId" className="form-control"   onKeyPress={(e) => { allowNumbersOnly(e); }}maxLength="8"  />
                            <ErrorMessage name="empId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Employee Name:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="empName" className="form-control"   onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }}  />
                            <ErrorMessage name="empName" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>DOB:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="date" name="dob" className="form-control" />
                            <ErrorMessage name="dob" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>DOJ:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="date" name="doj" className="form-control" />
                            <ErrorMessage name="doj" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Gender:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="radio" name="gender" value="M" />
                            &nbsp;Male  &nbsp;
                            <Field type="radio" name="gender" value="F" />
                            &nbsp;Female
                            <ErrorMessage name="gender" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Email:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="email" name="email" className="form-control" />
                            <ErrorMessage name="email" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Mobile No:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="mobileNo" className="form-control" maxLength="10"    onKeyPress={(e) => { allowNumbersOnly(e); }} />
                            <ErrorMessage name="mobileNo" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Zone:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="zoneId" isSearchable={true} isClearable={true}
                                options={showZoneMasterAtempEntry}
                                getOptionLabel={optionforZone => optionforZone.zone_name} getOptionValue={optionforzonevalue => optionforzonevalue.zone_id}
                                value={showZoneMasterAtempEntry?.find(itzone => itzone?.zone_id === empEntryformIk?.values?.zoneId) || null}
                                onChange={handleZoneAtempEntryForm}/>
                            <ErrorMessage name="zoneId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Designation:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="designationId" isSearchable={true} isClearable={true}
                                options={showDesignationsDataAtempEntry}
                                getOptionLabel={optiondisglabel => optiondisglabel.designation_name} getOptionValue={optiondesgvalue => optiondesgvalue.designation_id}
                                value={showDesignationsDataAtempEntry?.find(itdesg => itdesg?.designation_id === empEntryformIk?.values?.designationId) || null}
                                onChange={handleDesignationEntryForm}/>
                            <ErrorMessage name="designationId" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>IO Emp:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="ioEmpId" isSearchable={true} isClearable={true}
                                options={showemployeelist}
                                getOptionLabel={optionatIO => optionatIO.emp_id+"-"+optionatIO.emp_name} getOptionValue={optionIovalue => optionIovalue.emp_id}
                                value={showemployeelist?.find(itioemp => itioemp?.emp_id === empEntryformIk?.values?.ioEmpId) || null}
                               onChange={(selectedOption) => {
                                if (selectedOption !== null) {empEntryformIk.setFieldValue("ioEmpId", selectedOption.emp_id);}
                                else{empEntryformIk.setFieldValue("ioEmpId","");}}}/>
                        </jnb.InputGroup>
                        <ErrorMessage name="ioEmpId" component="div" className="text-error" />
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>RO Emp:<font style={{ color: "red" }}>*</font> </b></span>
                            <Select className="form-control" name="roEmpId" isSearchable={true} isClearable={true}
                             options={showemployeelist}
                             getOptionLabel={optionrolabel => optionrolabel.emp_id+"-"+optionrolabel.emp_name} getOptionValue={optionrovalue => optionrovalue.emp_id}
                             value={showemployeelist?.find(itroEmp => itroEmp?.emp_id === empEntryformIk?.values?.roEmpId) || null}
                             onChange={(selectedOption) => {
                                if (selectedOption !== null) {empEntryformIk.setFieldValue("roEmpId", selectedOption.emp_id);}
                                else{empEntryformIk.setFieldValue("roEmpId","");}}}/>
                        </jnb.InputGroup>
                        <ErrorMessage name="roEmpId" component="div" className="text-error" />
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ float: "right" }}>
                        <button type="submit" className="btn rounded-0 btn-success btn-sm " >Submit</button>
                    </jnb.Col>
                </jnb.Row>
            </Form>
        </FormikProvider>
    </>)
}