
import { ErrorMessage, Field, FormikProvider, useFormik,Form} from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Sweetalert from "../../../../CommonUtils/SweetAlerts";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
export default function JobPostingForm() {
    const [getZoneData, setZoneData] = useState([]);
    const [getDesignationDetails, setDesignationDetails] = useState([])
    const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
    const validation = Yup.object().shape({
        requirement_years: Yup.string().required("Required").nullable(),
        no_of_persons: Yup.string().required("Required").nullable(),
        job_description: Yup.string().required("Required").nullable(),
        designation: Yup.string().required("Required").nullable(),
        location: Yup.string().required("Required").nullable(),
        immediate_joining: Yup.string().required("Required").nullable(),
        from_date: Yup.string().required("Required").nullable(),
        to_date: Yup.string().required("Required").nullable(),
    });
    const jobpostingInitialValues = {
        technology: "",
        insert_by: logindetails?.username,
        requirement_years: "",
        no_of_persons: "",
        job_description: "",
        designation: "",
        location: "",
        immediate_joining: "",
        from_date: "",
        to_date: ""
    };

    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: jobpostingInitialValues,
        onSubmit: (values) => {
            JobPostSave(values)
        },
        validationSchema: validation,
    });

    const JobPostSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.jobDetailsPost(values)
                    .then((res) => {
                        console.log("resonse data", res.data)
                        if (res.data.ResponseCode === "01") {
                            Swal.fire('Successfully Submitted', 'Success', 'success',);
                            window.location.href = "/jobpostreport";
                        }
                        else if (res.data.ResponseCode === "02") {
                            Sweetalert(res.data.ResponseDesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {console.log("Exception Occured 71 ");});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    useEffect(() => {
        let Url = (config.url.COMMON_URL + "zones");
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {setZoneData(res.data.data)}
            else if (res.data.scode === "02") {setZoneData([])}
        })
    }, []);
    function GetDesignationData(zoneid) {
        let Url = (config.url.COMMON_URL + "designations/" + zoneid);//+ employeeId)
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {setDesignationDetails(res.data.data)}
            else if (res.data.scode === "02") {setDesignationDetails([])}
        })
    }
    function clearFields() {
        formIk.setFieldValue("requirement_years", "");
        formIk.setFieldValue("no_of_persons", "");
        formIk.setFieldValue("job_description", "");
        formIk.setFieldValue("designation", "");
        formIk.setFieldValue("location", "");
        formIk.setFieldValue("immediate_joining", "");
        formIk.setFieldValue("from_date", "");
        formIk.setFieldValue("to_date", "");
    }
    const navigate = useNavigate()
    function NavigateToJobPosReport() {
        navigate("/jobpostreport")
    }
    const CustomSelectField = ({ label, name, options }) => (
        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
          <jnb.InputGroup className="mb-4p5">
            <span className="label-text-style">
              <b>{label}<font style={{ color: "red" }}>*</font></b>
            </span>
            <Field as="select" className="form-control mb-2" name={name}>
              <option value="">--select--</option>
              {options.map((optionValue, index) => (
                <option key={index} value={optionValue}>{optionValue}</option>
              ))}
            </Field>
            <ErrorMessage name={name} component="div" className="text-error" />
          </jnb.InputGroup>
        </jnb.Col>
      );
    return (<>
        <jnb.Row className=" pt-2">
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Job Details</li>
                </ol>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <button type="button" class="btn btn-secondary  btn-sm float-end" onClick={() => { NavigateToJobPosReport() }}>Back</button>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className=" pt-2">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className=" pt-5 border m-0">
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            <jnb.InputGroup className="mb-4p5">
                                <span className="label-text-style"><b>Job Category:<font style={{ color: "red" }}>*</font></b></span>
                                <Field as="select" className="form-control mb-2" name="technology" onChange={(e) => { clearFields(); GetDesignationData(e.target.value) }}>
                                    <option value="">--select--</option>
                                    {getZoneData !== undefined && getZoneData?.map((data, i) => {
                                        return <option key={i} value={data.zone_id}>{data.zone_name}</option>})}
                                </Field>
                                <ErrorMessage name="technology" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        </jnb.Col>
                        {formIk?.values.technology === "" ? null : (
                        <jnb.Row className=" pt-4 pe-0">
                           
                           <CustomSelectField  label="Requirement Years:" name="requirement_years" options={["1", "2", "3", "4", "5"]}  />
                           
                           <CustomSelectField  label="No.Of Persons:" name="no_of_persons"  options={["1", "2", "3", "4", "5"]} />
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Job Description:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field as="textarea" className="form-control mb-2" style={{height:'30px'}} name="job_description" ></Field>
                                    <ErrorMessage name="job_description"  component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup className="mb-4p5">
                                    <span className="label-text-style"><b>Designation:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field as="select" className="form-control mb-2" name="designation">
                                        <option value="">--select--</option>
                                        {getDesignationDetails !== undefined && getDesignationDetails?.map((data, i) => {
                                            return <option key={i} value={data.designationId}>{data.designationName}</option>
                                        })}
                                    </Field>
                                    <ErrorMessage name="designation" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                        
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup >
                                    <span className="label-text-style"><b>Location:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                                    <Field as="textarea" className="form-control mb-2" name="location"  style={{height:'30px'}}maxLength="400"></Field>
                                    <ErrorMessage name="location" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup >
                                    <span className="label-text-style"><b>Immediate Joining:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="radio" name="immediate_joining" value="YES" />
                                    &nbsp;Yes  &nbsp;
                                    <Field type="radio" name="immediate_joining" value="NO" />
                                    &nbsp;No
                                    <ErrorMessage name="immediate_joining" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup >
                                    <span className="label-text-style"><b>Job Post From:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="date" name="from_date" className="form-control mb-2" />
                                    <ErrorMessage name="from_date" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                <jnb.InputGroup >
                                    <span className="label-text-style"><b>Job Post To:<font style={{ color: "red" }}>*</font> </b></span>
                                    <Field type="date" name="to_date" className="form-control mb-2" />
                                    <ErrorMessage name="to_date" component="div" className="text-error" />
                                </jnb.InputGroup>
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={9} xl={9} xxl={9} className="mb-2">
                            </jnb.Col>
                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2 text-end">
                            <button type="submit" className="btn btn-success btn-sm float-nd">Save</button>
                            </jnb.Col>
                        </jnb.Row>
                    )}
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}