import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as jnb from "react-bootstrap";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
export default function HrmsDashboard(){
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {zone: "",},
        onSubmit: (values) => { console.log(null) },
    });
    const navigate = useNavigate();
    const [reportFlag, setReportFlag] = useState('');
    const [showReportStatus, setReportStatus] = useState(false);
    const [showEmpReportStatus, setEmpReportStatus] = useState(false);
    const [showdashboarddata, setdashboarddata] = useState([]);
    const [showEmpdata, setEmpdata] = useState([]);
    function loadDashboardReportFirst(type) {
        let Url = (config.url.COMMON_URL + "dashboard/" + type + "/" + 0 + "/" + 0);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setdashboarddata(res.data.data);
                setReportStatus(true);
                setEmpReportStatus(false)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setReportStatus(false);
                setEmpReportStatus(false)
            }
        })
    }
    function dashboardreporttype(reporttype, typeid, type, rodesignationid) {
        let Url = (config.url.COMMON_URL + "dashboard-report/" + reporttype + "/" + typeid + "/" + type + "/" + rodesignationid);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setEmpdata(res.data.data);
                setEmpReportStatus(true);
                setReportStatus(false)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setEmpReportStatus(false);
                setReportStatus(false)
            }
        })
    }

    function showEmpKPStatusReport(e, empid, pageflag) {
        console.log(empid,pageflag)
        navigate("/pmsempprintform", { state: { empid: empid, pageflag: pageflag } })
    }
    function getcatogorywisedata(empid) {
        let Url = (config.url.COMMON_URL + "dashboard/rodesignation/" + empid);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setReportFlag('rodesignation');

                setdashboarddata(res.data.data);
                setEmpReportStatus(false);
                setReportStatus(true)
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setEmpReportStatus(false);
                setReportStatus(false)
            }
        })
    }

    function getReportLabel(reportFlag1) {
        switch (reportFlag1) {
            case "zone":
                return <b>Zone Wise</b>;
            case "designation":
                return <b>Designation Wise</b>;
            case "ro":
                return <b>RO Wise</b>;
            case "rodesignation":
                return (
                    <>
                        <button
                            type="button"
                            className="btn rounded btn-primary btn-sm"
                            onClick={(e) => {
                                setReportFlag("ro");
                                loadDashboardReportFirst("ro");
                            }}
                            style={{ marginLeft: '55px' }}>
                            Back
                        </button>
                        <b> RO Designation Wise</b>
                    </>
                );
            default:
                return null;
        }
    }

    function handleLinkClick(id, designationId = 0) {
        dashboardreporttype("total", id, reportFlag, designationId);
        setReportStatus(false);
    }

    function handleCountClick(type, id, designationId = 0) {
        dashboardreporttype(type, id, reportFlag, designationId);
        setReportStatus(false);
    }

    
    return (<>

        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Key Performance Abstract Report</li>
                </ol>
            </div>
        </div>
        <FormikProvider value={formIk}>
            <jnb.Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <jnb.Row className="px-4 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}> Select Type:</jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <Field as="select" className="form-control" name="zone" onChange={(e) => { loadDashboardReportFirst(e.target.value); setReportFlag(e.target.value); }}>
                                <option value="0">--Select--</option>
                                <option value="zone">Zone</option>
                                <option value="designation">Designation</option>
                                <option value="ro">RO</option>
                            </Field>
                            <ErrorMessage name="zone" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                {showReportStatus === true ? (
                    <>
                        {showdashboarddata?.length > 0 ? (<>
                            <h4 style={{ fontSize: "20px", marginLeft: "55px" }}>
                                {getReportLabel(reportFlag)} &nbsp;
                            </h4>
                            <jnb.Row className="px-3 pt-2">

                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                        <thead>
                                            <tr>
                                                <th rowSpan={3}>Sl.No.</th>
                                                <th rowSpan={3}>Catogory</th>
                                                <th rowSpan={3} width="50px" >Total Employee's</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>Self</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>IO</th>
                                                <th colSpan={5} style={{ textAlign: "center" }}>RO</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                                <th colSpan={4} style={{ textAlign: "center" }}>Completed</th>
                                                <th rowSpan={2} style={{ textAlign: "center" }}>Pending</th>
                                            </tr>
                                            <tr>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                                <th rowSpan={1} style={{ textAlign: "center" }}>A</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>B</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>C</th>
                                                <th rowSpan={3} style={{ textAlign: "center" }}>D</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "left" }}>
                                            {showdashboarddata != undefined && showdashboarddata.map((row, i) => {
                                                const id = reportFlag === "zone" ? row.zone_id :
                                                reportFlag === "designation" ? row.designation_id :
                                                row.ro_emp_id;
                                     const designationId = reportFlag === "rodesignation" ? row.designation_id : 0;
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>
                                                            {reportFlag === "ro" ? <a href={() => false} className="atag" onClick={() => { getcatogorywisedata(row.ro_emp_id); setReportStatus(false); }}>{row.category_name} </a> : (row.category_name)}
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleLinkClick(id, designationId)}>
                                                                {row.total_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("SELF_A", id, designationId)}>
                                                                {row.self_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("SELF_B", id, designationId)}>
                                                                {row.self_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("SELF_C", id, designationId)}>
                                                                {row.self_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("SELF_D", id, designationId)}>
                                                                {row.self_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("SELF_PENDING", id, designationId)}>
                                                                {row.self_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("IO_A", id, designationId)}>
                                                                {row.io_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("IO_B", id, designationId)}>
                                                                {row.io_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("IO_C", id, designationId)}>
                                                                {row.io_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("IO_D", id, designationId)}>
                                                                {row.io_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("IO_PENDING", id, designationId)}>
                                                                {row.io_pending_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("RO_A", id, designationId)}>
                                                                {row.ro_a_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("RO_B", id, designationId)}>
                                                                {row.ro_b_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("RO_C", id, designationId)}>
                                                                {row.ro_c_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("RO_D", id, designationId)}>
                                                                {row.ro_d_rating_count}
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a href="#" className="atag" onClick={() => handleCountClick("RO_PENDING", id, designationId)}>
                                                                {row.ro_pending_count}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr><td style={{ textAlign: "left" }} colSpan={2}>Total</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.total_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.self_a_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.self_b_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.self_c_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.self_d_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.self_pending_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.io_a_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.io_b_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.io_c_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.io_d_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.io_pending_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.ro_a_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.ro_b_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.ro_c_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.ro_d_rating_count, 0)}</td>
                                                <td>{showdashboarddata.reduce((a, v) => a + v.ro_pending_count, 0)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Row>
                        </>) : (<></>)}

                    </>
                ) : ("")}

                {showEmpReportStatus ? (
                    <>
                        <button type="button" className="btn rounded btn-primary btn-sm" onClick={(e) => {
                            setReportStatus(true); setEmpReportStatus(false)
                        }} style={{ marginLeft: '55px', }}>Back</button>
                        {showEmpdata.length > 0 ? (<>
                            <jnb.Row className="px-3 pt-2">
                                <div className="table-responsive">
                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                        style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                        <thead>
                                            <tr>
                                                <th>Sl.No</th>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                                <th>Mobile Number</th>
                                                <th>EmailId</th>

                                            </tr>
                                        </thead>
                                        <tbody style={{ textAlign: "center" }}>
                                            {showEmpdata != undefined && showEmpdata.map((iodata, i) => {
                                                return (<React.Fragment key={i}>
                                                    <tr >
                                                        <td>{i + 1}</td>
                                                        <td><a href={() => false} className="atag" onClick={(e) => { showEmpKPStatusReport(e, iodata.emp_id, "HR"); }}>{iodata.emp_id}</a></td>
                                                        <td>{iodata.emp_name}</td>
                                                        <td>{iodata.designation_name}</td>
                                                        <td>{iodata.mobile_no}</td>
                                                        <td>{iodata.email}</td>
                                                    </tr>
                                                </React.Fragment>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </jnb.Row>
                        </>) : (<><br /> <center><b>No Data Found </b></center> </>)}
                    </>
                ) : ("")}
            </jnb.Form>
        </FormikProvider>
    </>)
}