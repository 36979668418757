import { ErrorMessage, Field, FormikProvider, useFormik, Form } from "formik";
import moment from "moment";
import { useRef, useState, useEffect } from "react";
import * as jnb from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as  Yup from "yup";
import Swal from "sweetalert2";
import { FaCircleCheck } from "react-icons/fa6";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import CommonPost from "../../../CommonUtils/CommonPost";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
function LeavesOrMovementRegistration() {
    const [getEmpLeaveDetails, setEmpLeavDetails] = useState([]);
    const logindetailsAtLeaveReg = useSelector((stateAtLeaveReg) => stateAtLeaveReg?.reducers?.loginreducer?.userLoginDetials);
    const [getSuccessMessage, setSuccessMessage] = useState(false);
    const [selectedLeaveAtReg, setSelectedLeaveAtReg] = useState(null);
    const LeaveRegformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            typeOfLeave: "",
            no_of_days: "",
            one_day_from_date: "",
            morethan_one_day_from_date: "",
            morethan_one_day_to_date: "",
            halfday_from_date: "",
            halfday_fromTime_toTime: "",
            total_no_of_days: "",
            office_name: "",
            remarks: "",
            loss_of_pay: "",
            leave_type_id: "",
            leaves: [
                {
                    leaveforApply: "",
                    doctor_certificatehiddenfile: "",
                    doctor_certificatefile: "",
                    type_of_leave: "",
                }
            ],
        },

        validationSchema: Yup.object().shape({
            typeOfLeave: Yup.string().required("Required"),
            no_of_days: Yup.string().required("Required"),
            one_day_from_date: Yup.string().when("no_of_days", {
                is: (leave) => leave === "One Day" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),
            morethan_one_day_from_date: Yup.string().when("no_of_days", {
                is: (leave) => leave === "More Than One Day" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),
            morethan_one_day_to_date: Yup.string().when("no_of_days", {
                is: (leave) => leave === "More Than One Day" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),

            halfday_from_date: Yup.string().when("no_of_days", {
                is: (leave) => leave === "One Half Day" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),
            halfday_fromTime_toTime: Yup.string().when("no_of_days", {
                is: (leave) => leave === "One Half Day" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),
            remarks: Yup.string().when(["no_of_days", "typeOfLeave"], (no_of_days, typeOfLeave, schema) => {
                return (no_of_days === "One Half Day" || no_of_days === "One Day" || no_of_days === "More Than One Day")
                    && (typeOfLeave === "Movement" || typeOfLeave === "WFH" || typeOfLeave === "Leave")
                    ? schema.required("Required")
                    : schema;
            }),
            loss_of_pay: Yup.string().when("loss_of_pay_request", {
                is: (leave) => leave === "true" ? true : false,
                then: Yup.string().required("Required"), otherwise: Yup.string()
            }),
        }),

        onSubmit: (values) => {
            const allowedFromDate = new Date(getEmpLeaveDetails[0]?.from_date);
            const allowedToDate = new Date(getEmpLeaveDetails[0]?.to_date);
            const oneDayDate = new Date(values.one_day_from_date);
            const halfDayDate = new Date(values.halfday_from_date);
            const fromDateMoreThanOneDay = new Date(values.morethan_one_day_from_date);
            const toDateMoreThanOneDay = new Date(values.morethan_one_day_to_date);
            if (values.no_of_days === "One Day" && (oneDayDate < allowedFromDate || oneDayDate > allowedToDate)) {
                Swal.fire({
                    title: 'Invalid Date',
                    text: 'The selected date for "One Day" leave must be within the allowed range.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }
            if (values.no_of_days === "One Half Day" && (halfDayDate < allowedFromDate || halfDayDate > allowedToDate)) {
                Swal.fire({
                    title: 'Invalid Date',
                    text: 'The selected date for "Half Day" leave must be within the allowed range.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }
            if (values.no_of_days === "More Than One Day") {
                if (fromDateMoreThanOneDay < allowedFromDate || fromDateMoreThanOneDay > allowedToDate) {
                    Swal.fire({
                        title: 'Invalid From Date',
                        text: 'The "From Date" must be within the allowed range.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
                if (toDateMoreThanOneDay < allowedFromDate || toDateMoreThanOneDay > allowedToDate) {
                    Swal.fire({
                        title: 'Invalid To Date',
                        text: 'The "To Date" must be within the allowed range.',
                        icon: 'error',
                        confirmButtonText: 'OK',
                    });
                    return;
                }
            }
            if (fromDateMoreThanOneDay > toDateMoreThanOneDay) {
                Swal.fire({
                    title: 'Invalid Dates',
                    text: 'The "From Date" cannot be greater than the "To Date". Please select a valid date range.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
                return;
            }

            const finalvaluesAtLeaveReg = {
                "emp_id": logindetailsAtLeaveReg?.username,
                "typeOfLeave": values?.typeOfLeave,
                "no_of_days": values?.no_of_days,
                "one_day_from_date": values?.one_day_from_date,
                "morethan_one_day_from_date": values?.morethan_one_day_from_date,
                "morethan_one_day_to_date": values?.morethan_one_day_to_date,
                "halfday_from_date": values?.halfday_from_date,
                "halfday_fromTime_toTime": values?.halfday_fromTime_toTime,
                "total_no_of_days": values?.total_no_of_days,
                "office_name": "",
                "remarks": values?.remarks,
                "loss_of_pay": values?.loss_of_pay_request === true ? values?.total_no_of_days : "",
                "loss_of_pay_request": values?.loss_of_pay_request,
                "leave_type_id": values?.leave_type_id
            };
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.LeaveDetailsSave(finalvaluesAtLeaveReg).then((resReg) => {
                        if (resReg?.data?.ResponseCode === "01") {
                            Swal.fire('Successfully Submitted', 'Success', 'success',);
                            setSuccessMessage(true)
                        }
                        else if (resReg?.data?.ResponseCode === "02") {
                            Sweetalert(resReg?.data?.ResponseDesc, 'warning');
                        }
                        else if (resReg?.data?.ResponseCode === "03") {
                            Sweetalert(resReg?.data?.ResponseDesc, 'warning');
                        }
                        else if (resReg?.data?.ResponseCode === "05") {
                            Sweetalert("Already you have applied/availed leave for the given period.", 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                        .catch((err) => { console.log("Exception Occured 71 ", err); });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        }
    });

    function ClearFieldsAtLeaveReg() {
        if (LeaveRegformIk?.values?.typeOfLeave === "Leave") {
            LeaveRegformIk.setFieldValue("no_of_days", "")
        }
        else if (LeaveRegformIk?.values?.typeOfLeave === "Movement") {
            LeaveRegformIk.setFieldValue("no_of_days", "")
        }
        else {
            LeaveRegformIk.setFieldValue("no_of_days", "")
            LeaveRegformIk.setFieldValue("remarks", "")
        }
    }
    function ClrNoOfDays() {
        if (LeaveRegformIk?.values?.no_of_days === "One Day") {
            LeaveRegformIk.setFieldValue("one_day_from_date", "");
            LeaveRegformIk.setFieldValue("office_name", "");
            LeaveRegformIk.setFieldValue("remarks", "")
        }
        else if (LeaveRegformIk?.values?.no_of_days === "More Than One Day") {
            LeaveRegformIk.setFieldValue("morethan_one_day_from_date", "");
            LeaveRegformIk.setFieldValue("morethan_one_day_to_date", "");
            LeaveRegformIk.setFieldValue("office_name", "");
            LeaveRegformIk.setFieldValue("remarks", "")
        }
        else {
            LeaveRegformIk.setFieldValue("halfday_from_date", "");
            LeaveRegformIk.setFieldValue("halfday_fromTime_toTime", "");
            LeaveRegformIk.setFieldValue("office_name", "");
            LeaveRegformIk.setFieldValue("remarks", "")
        }

    }

    function SelectTypeOfLeave() {
        let alertMessageAtReg = "";
        if (LeaveRegformIk?.values?.typeOfLeave === "") { alertMessageAtReg = "Leave Type is required"; }
        else if (LeaveRegformIk?.values?.typeOfLeave === "Leave" && selectedLeaveAtReg === null) { alertMessageAtReg = "Specific Leave Type is Required"; }
        if (alertMessageAtReg !== "") {
            Swal.fire(alertMessageAtReg).then(res => {
                if (res?.isConfirmed === true) { LeaveRegformIk.setFieldValue("no_of_days", ""); }
            });
        } else { console.log(null); }
    }

    const resetTotalDays = () => { LeaveRegformIk.setFieldValue("total_no_of_days", ""); };
    function GetLeaveDetails() {
        let Url = (config.url.COMMON_URL + "leavesdatanew/" + logindetailsAtLeaveReg?.username);
        CommonAxiosGet(Url).then((restotaldays) => {
            if (restotaldays?.data?.status === true) { setEmpLeavDetails(restotaldays.data.leavesdatanew) }
            else { setEmpLeavDetails([]) }
        })
    }
    const generalHolidays = [
        new Date('2024-08-15'),
        new Date('2024-08-26'),
        new Date('2024-09-07'),
        new Date('2024-09-16'),
        new Date('2024-10-02'),
        new Date('2024-10-11'),
        new Date('2024-10-31'),
        new Date('2024-12-25')
    ];

    const isGeneralHoliday = (dateIsholiday) => {
        const dateToCheck = new Date(dateIsholiday).setHours(0, 0, 0, 0);
        const isHoliday = generalHolidays.some(holiday => {
            const holidayDate = new Date(holiday).setHours(0, 0, 0, 0);
            return holidayDate === dateToCheck;
        });
        return isHoliday;
    };

    function isSecondSaturday(dateIssat) {
        const dayISsat = dateIssat.getDay();
        const dateNumber = dateIssat.getDate();
        return dayISsat === 6 && Math.ceil(dateNumber / 7) === 2;
    }

    function calculateDaysDifference(sdatefordiff, edatefordiff, halfdayfordiff) {
        const selectedoptionfordiff = LeaveRegformIk?.values?.no_of_days;
      
        if (selectedoptionfordiff === "One Day") {
            const selectedDatefordiff = new Date(sdatefordiff);
          
            const dayOfWeek = selectedDatefordiff.getDay();
            const isInvalidDate = isNaN(selectedDatefordiff.getTime()) || dayOfWeek === 0 || isGeneralHoliday(selectedDatefordiff) || isSecondSaturday(selectedDatefordiff);

            if (isInvalidDate) {
                let alertMessagefordiff = "Selected date is not considered. Please select another date.";

                if (dayOfWeek === 0) {
                    alertMessagefordiff = "You can't apply on Sunday.";
                }
                Swal.fire({
                    text: alertMessagefordiff,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    backdrop: false,
                }).then((resfordiff) => {
                    if (resfordiff?.isConfirmed === true) {
                        LeaveRegformIk.setFieldValue("one_day_from_date", "");
                        LeaveRegformIk.setFieldValue("total_no_of_days", "");
                    }
                });

            } else { LeaveRegformIk.setFieldValue("total_no_of_days", 1); }
        }
        else if (selectedoptionfordiff === "More Than One Day") {
            let startDatefordiff = moment(sdatefordiff).startOf('day');
            let endDatefordiff = moment(edatefordiff).startOf('day');
            let totalDaysfordiff = 0;

            while (startDatefordiff?.isSameOrBefore(endDatefordiff)) {
                const currentDayfordiff = startDatefordiff.toDate();
                const dayOfWeekfordiff = currentDayfordiff.getDay();
                if (
                    dayOfWeekfordiff !== 0 &&
                    !isSecondSaturday(currentDayfordiff) &&
                    !isGeneralHoliday(currentDayfordiff)
                ) {
                    totalDaysfordiff++;
                }
                startDatefordiff = startDatefordiff.add(1, 'days');
            }

            LeaveRegformIk.setFieldValue("total_no_of_days", totalDaysfordiff);
        }
        else if (selectedoptionfordiff === "One Half Day") {

            const selectedDatedayfordiff = new Date(sdatefordiff);
            const dayOfWeekdayfordiff = selectedDatedayfordiff.getDay();
            const isInvalidDatedayfordiff = isNaN(selectedDatedayfordiff.getTime()) || dayOfWeekdayfordiff === 0 || isGeneralHoliday(selectedDatedayfordiff) || isSecondSaturday(selectedDatedayfordiff);

            if (isInvalidDatedayfordiff) {
                let alertMessagedayfordiff = "Selected date is not considered. Please select another date.";

                if (dayOfWeekdayfordiff === 0) {
                    alertMessagedayfordiff = "You can't apply on Sunday.";
                }
                Swal.fire({
                    text: alertMessagedayfordiff,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    backdrop: false,
                }).then((resdayfordiff) => {
                    if (resdayfordiff?.isConfirmed === true) {
                        LeaveRegformIk.setFieldValue("halfday_from_date", "");
                        LeaveRegformIk.setFieldValue("total_no_of_days", "");
                    }
                });

            } else {
                LeaveRegformIk.setFieldValue("total_no_of_days", halfdayfordiff);
            }
        }
    }

    function GetToDateAtleavereg(dateAtleavereg) {
        const fromDateAtleavereg = new Date(dateAtleavereg);
        const nextDayAtleavereg = new Date(fromDateAtleavereg);
        nextDayAtleavereg.setDate(fromDateAtleavereg.getDate() + 1);
        const todateAtleavereg = nextDayAtleavereg.toISOString().split("T")[0];

        const todateInputAtleavereg = document.getElementsByName("morethan_one_day_to_date")[0];
        if (todateInputAtleavereg) {
            todateInputAtleavereg.min = todateAtleavereg;
        }
    }
    const handleLeaveClick = (leaveType, leavetypeID) => {

        setSelectedLeaveAtReg(leaveType);

        LeaveRegformIk.setFieldValue("leave_type_id", leavetypeID)
        LeaveRegformIk.setFieldValue("morethan_one_day_from_date", "");
        LeaveRegformIk.setFieldValue("morethan_one_day_to_date", "");
        LeaveRegformIk.setFieldValue("one_day_from_date", "");
        LeaveRegformIk.setFieldValue("halfday_from_date", "");
        LeaveRegformIk.setFieldValue("halfday_fromTime_toTime", "");
        LeaveRegformIk.setFieldValue("total_no_of_days", "");
        LeaveRegformIk.setFieldValue("no_of_days", "");
        LeaveRegformIk.setFieldValue("remarks", "");
        LeaveRegformIk.setFieldValue("office_name", "");

        if (leavetypeID === "5") {
            LeaveRegformIk.setFieldValue("loss_of_pay_request", true)
        }

        if (leavetypeID === "8") {
            const leavetypeidvalue = getEmpLeaveDetails[0]?.leave_type === "Maternity" ? "9" : "8"
            LeaveRegformIk.setFieldValue("leave_type_id", leavetypeidvalue)
        }
    };

    const navigateAtLeaveReg = useNavigate()
    function NavigateToLeaveDetailsReport() { navigateAtLeaveReg("/leavesDetailsReport") }

    const fetchDataAtLeaveRe = useRef(false)
    useEffect(() => {
        if (!fetchDataAtLeaveRe.current) {
            fetchDataAtLeaveRe.current = true
            GetLeaveDetails()

        }
    }, []);


    return (<>
        {getSuccessMessage === false ? (<>
            <jnb.Row className=" pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Leave Request Form
                        </li>
                    </ol>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <button type="button" class="btn btn-secondary  btn-sm float-end" btn-sm onClick={() => { NavigateToLeaveDetailsReport() }}>Leaves History</button>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                <FormikProvider value={LeaveRegformIk}>
                    <Form onSubmit={LeaveRegformIk.handleSubmit} onChange={LeaveRegformIk.handleChange}>
                        <h5 className="leavetypeheading py-3">Leave Type</h5>
                        <jnb.Row className="pt-3 pb-2 border m-0">
                            <jnb.Row className="m-0">
                                <div className="d-flex">
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" name="typeOfLeave" value="Leave" onChange={() => {
                                            ClearFieldsAtLeaveReg(); ClrNoOfDays(); resetTotalDays(); setSelectedLeaveAtReg(null)
                                        }} /> &nbsp;
                                        <img src="../img/leave.svg" width="20px" alt="leaveimage" />
                                        <label class="form-check-label" for="inlineRadio1"><b> &nbsp;Leave</b>&nbsp;&nbsp;</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" name="typeOfLeave" value="Movement" onChange={() => {
                                            ClearFieldsAtLeaveReg(); ClrNoOfDays(); resetTotalDays(); setSelectedLeaveAtReg(null);
                                            LeaveRegformIk.setFieldValue("leave_type_id", "7");
                                        }} />&nbsp;
                                        <img src="../img/movement.svg" width="20px" alt="movementimage" />
                                        &nbsp;
                                        <label class="form-check-label" for="inlineRadio2"><b> &nbsp;Regularization</b>&nbsp;&nbsp;</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" name="typeOfLeave" value="WFH" onChange={() => {
                                            LeaveRegformIk.setFieldValue("leave_type_id", "6");
                                            ClearFieldsAtLeaveReg(); ClrNoOfDays(); resetTotalDays(); setSelectedLeaveAtReg(null)
                                        }} />&nbsp;
                                        <img src="../img/workfrom home.svg" width="20px" alt="workfromhomeimage" /> &nbsp;
                                        <label class="form-check-label" for="inlineRadio2"> <b>Work From Home</b>&nbsp;</label>
                                    </div>
                                    <ErrorMessage component="div" className="text-danger" name="typeOfLeave" />
                                </div>
                            </jnb.Row>
                            {LeaveRegformIk.values.typeOfLeave === "Leave" ? (
                                <div className="table-responsive">
                                    <jnb.Row className="typeofleave">
                                        <div className="d-flex">
                                            <div>
                                                <div className="ps-0 pe-5 pt-3 pb-3">
                                                    <div className="casualleavesection">
                                                        <div style={{ display: 'contents' }}>
                                                            <div className="card" onClick={() => { handleLeaveClick('Sick Leave', "2"); }}
                                                                style={{
                                                                    border: selectedLeaveAtReg === 'Sick Leave' ? '2px solid #C7624C' : 'none',
                                                                    backgroundColor: selectedLeaveAtReg === 'Sick Leave' ? '#F3E2DD' : 'transparent'
                                                                }}>
                                                                <div className="card-body text-center p-2">
                                                                    <p className="mb-0 sickleave">Sick Leave</p>
                                                                    <div><img src="../img/sick.svg" width="45px" alt="sickimage" className="img-fluid mb-2" /></div>
                                                                    <p className="mb-0"> <span className="available">Available :
                                                                        <b style={{ fontSize: "18px", color: "#C7624C" }}>
                                                                            {getEmpLeaveDetails[0]?.sl_bal === null ? "0" : getEmpLeaveDetails[0]?.sl_bal}</b>
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ps-0 pe-5 pt-3 pb-3">
                                                    <div className="casualleavesection">
                                                        <div style={{ display: 'contents' }}>
                                                            <div className="card" onClick={() => { handleLeaveClick('Casual Leave', "3"); }}
                                                                style={{
                                                                    border: selectedLeaveAtReg === 'Casual Leave' ? '2px solid #C7624C' : 'none',
                                                                    backgroundColor: selectedLeaveAtReg === 'Casual Leave' ? '#F3E2DD' : 'transparent'
                                                                }}>
                                                                <div className="card-body text-center p-2">
                                                                    <p className="mb-0 sickleave">Casual Leave</p>
                                                                    <div><img src="../img/casual.svg " width="45px" alt="casualimage" className="img-fluid mb-2" /></div>
                                                                    <p className="mb-0"> <span className="available">Available :
                                                                        <b style={{ fontSize: "18px", color: "#C7624C" }}>
                                                                            {getEmpLeaveDetails[0]?.cl_bal === null ? "0" : getEmpLeaveDetails[0]?.cl_bal}</b>
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ps-0 pe-5 pt-3 pb-3">
                                                    <div className="casualleavesection">
                                                        <div style={{ display: 'contents' }}>
                                                            <div className="card" onClick={() => { handleLeaveClick('Earned Leave', "4"); }}
                                                                style={{
                                                                    border: selectedLeaveAtReg === 'Earned Leave' ? '2px solid #C7624C' : 'none',
                                                                    backgroundColor: selectedLeaveAtReg === 'Earned Leave' ? '#F3E2DD' : 'transparent'
                                                                }}>
                                                                <div className="card-body text-center p-2">
                                                                    <p className="mb-0 sickleave">Earned Leave</p>
                                                                    <div><img src="../img/earned.svg " width="45px" alt="earnedimage" className="img-fluid mb-2" /></div>
                                                                    <p className="mb-0"> <span className="available">Available :
                                                                        <b style={{ fontSize: "18px", color: "#C7624C" }}>
                                                                            {getEmpLeaveDetails[0]?.el_bal === null ? "0" : getEmpLeaveDetails[0]?.el_bal}</b>
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ps-0 pe-5 pt-3 pb-3">
                                                    <div className="lossofleavesection">
                                                        <div style={{ display: 'contents' }}>
                                                            <div className="card" onClick={() => { handleLeaveClick('Loss of Pay', "5"); }}
                                                                style={{
                                                                    border: selectedLeaveAtReg === 'Loss of Pay' ? '2px solid #C7624C' : 'none',
                                                                    backgroundColor: selectedLeaveAtReg === 'Loss of Pay' ? '#F3E2DD' : 'transparent'
                                                                }}>
                                                                <div className="card-body text-center p-2">
                                                                    <p className="mb-0 sickleave">Loss of Pay</p>
                                                                    <div><img src="../img/lop.svg " width="45px" alt="lopimage" className="img-fluid mb-2" /></div>
                                                                    <p className="mb-0"> <span className="booked">Used  :
                                                                        <b style={{ fontSize: "18px", color: "#C7624C" }}>
                                                                            {getEmpLeaveDetails[0]?.lop === null ? "0" : getEmpLeaveDetails[0]?.lop}</b>
                                                                    </span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {getEmpLeaveDetails[0]?.pm_bal > 0 ?
                                                <div>
                                                    <div className="ps-0 pe-5 pt-3 pb-3">
                                                        <div className="casualleavesection">
                                                            <div style={{ display: 'contents' }}>
                                                                <div className="card" onClick={() => { handleLeaveClick('PM Leave', "8"); }}
                                                                    style={{
                                                                        border: selectedLeaveAtReg === 'PM Leave' ? '2px solid #C7624C' : 'none',
                                                                        backgroundColor: selectedLeaveAtReg === 'PM Leave' ? '#F3E2DD' : 'transparent'
                                                                    }}>
                                                                    <div className="card-body text-center p-2">
                                                                        <p className="mb-0 sickleave"> {getEmpLeaveDetails[0]?.leave_type}</p>
                                                                        <div>{getEmpLeaveDetails[0]?.leave_type === "Maternity" ?
                                                                            <img src="../img/maternity.svg" width="45px" alt="maternityimage" className="img-fluid mb-2" /> :
                                                                            <img src="../img/paternity.svg" width="45px" alt="paternityimage" className="img-fluid mb-2" />}
                                                                        </div>
                                                                        <p className="mb-0"> <span className="available">Available :
                                                                            <b style={{ fontSize: "18px", color: "#C7624C" }}>
                                                                                {getEmpLeaveDetails[0]?.pm_bal === null ? "0" : getEmpLeaveDetails[0]?.pm_bal}</b>
                                                                        </span></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </jnb.Row>
                                </div>
                            ) : ""}
                        </jnb.Row>
                        <h5 className=" daytypeheading pt-4 pb-3"> Day Type</h5>
                        <jnb.Row className="border m-0 mt-2 pt-1 pb-2">
                            <jnb.Row className="pt-3 m-0">
                                <div className="d-flex">
                                    <div class="form-check form-check-inline ps-0">
                                        <span className="w-100 mb-2"><b>No.Of Days : </b></span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <Field type="radio" name="no_of_days" value="One Day" onChange={() => { ClrNoOfDays(); resetTotalDays(); SelectTypeOfLeave(); }} />
                                        <label class="form-check-label" for="inlineRadio1"> &nbsp;One Day</label>
                                    </div>
                                    {LeaveRegformIk.values.typeOfLeave === "Movement" ? null : (<>
                                        <div class="form-check form-check-inline">
                                            <Field type="radio" name="no_of_days" value="More Than One Day" onChange={() => {
                                                ClrNoOfDays(); resetTotalDays(); SelectTypeOfLeave();
                                            }} />
                                            <label class="form-check-label" for="inlineRadio2"> &nbsp;More Than One Day(Full Days) </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <Field type="radio" name="no_of_days" value="One Half Day" onChange={() => {
                                                ClrNoOfDays(); resetTotalDays(); SelectTypeOfLeave();
                                            }} />
                                            <label class="form-check-label" for="inlineRadio2"> &nbsp;Half Day </label>
                                        </div>
                                    </>)}
                                    <ErrorMessage component="div" className="text-danger" name="no_of_days" />
                                </div>
                            </jnb.Row>
                            <jnb.Row className="pt-1 m-0">
                                {LeaveRegformIk?.values?.no_of_days === "One Day" ? (<>
                                    {LeaveRegformIk?.values?.typeOfLeave === "Movement" ? (<>
                                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                            <jnb.InputGroup className="mb-3">
                                                <label className="w-100 mb-2"><b>Date </b></label>
                                                <Field type="date" name="one_day_from_date" className="form-control mb-2"
                                                    max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0]}
                                                    // onChange={(e) => { calculateDaysDifference(e.target.value,"",""); }} 
                                                      onBlur={(e) => {
                                                        calculateDaysDifference(e.target.value,"","");
                                                      }}/>
                                            </jnb.InputGroup>
                                            <ErrorMessage component="div" className="text-danger" name="one_day_from_date" />
                                        </jnb.Col></>) : (<>
                                            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                                <jnb.InputGroup className="mb-3">
                                                    <label className="w-100 mb-2"><b>Date</b></label>
                                                    <Field type="date" name="one_day_from_date" className="form-control mb-2"
                                                        // onChange={(e) => { calculateDaysDifference(e.target.value,"",""); }} 
                                                        onBlur={(e) => {
                                                            calculateDaysDifference(e.target.value,"","");
                                                          }}
                                                        />
                                                </jnb.InputGroup>
                                                <ErrorMessage component="div" className="text-danger" name="one_day_from_date" />
                                            </jnb.Col></>)}
                                </>) : ""}
                                {LeaveRegformIk?.values?.no_of_days === "More Than One Day" ? (<>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-3">
                                            <label className="w-100 mb-2"><b>From Date </b></label>
                                            <Field type="date" name="morethan_one_day_from_date"
                                                className="form-control mb-2"
                                                onChange={(e) => {
                                                    LeaveRegformIk.setFieldValue("morethan_one_day_to_date", "");
                                                    resetTotalDays(); GetToDateAtleavereg(e.target.value)
                                                }} />
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="morethan_one_day_from_date" />
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-3">
                                            <label className="w-100 mb-2"><b>To Date </b></label>
                                            <Field type="date" name="morethan_one_day_to_date" className="form-control mb-2"
                                                onChange={(e) => {
                                                    const toDateValue = e.target.value;
                                                    calculateDaysDifference(LeaveRegformIk.values.morethan_one_day_from_date, toDateValue);
                                                }} />
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="morethan_one_day_to_date" />
                                    </jnb.Col>
                                </>) : null}
                                {LeaveRegformIk?.values?.no_of_days === "One Half Day" ? (<>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                        <jnb.InputGroup className="mb-3">
                                            <label className="w-100 mb-2"><b>Date</b></label>
                                            <Field type="date" name="halfday_from_date" className="form-control mb-2"
                                                onBlur={(e) => {
                                                    resetTotalDays(); LeaveRegformIk.setFieldValue("halfday_fromTime_toTime", "");
                                                    calculateDaysDifference(e.target.value, "", "0.5");
                                                }}
                                                
                                                 />
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="halfday_from_date" />
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3} className="mb-2">
                                        <jnb.InputGroup className="mb-3">
                                            <label className="w-100 mb-2"><b>Timings</b></label>
                                            <Field as="select" className="form-control mb-2" name="halfday_fromTime_toTime">
                                                <option value="">--select--</option>
                                                <option value="Morning">Morning Session</option>
                                                <option value="AfterNoon">Afternoon Session</option>
                                            </Field>
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="halfday_fromTime_toTime" />
                                    </jnb.Col>
                                </>) : null}
                                {((LeaveRegformIk?.values?.no_of_days === "One Half Day" || LeaveRegformIk?.values?.no_of_days === "One Day" || LeaveRegformIk?.values?.no_of_days === "More Than One Day")
                                    && (LeaveRegformIk?.values?.typeOfLeave === "Movement" || LeaveRegformIk?.values?.typeOfLeave === "WFH" || LeaveRegformIk?.values?.typeOfLeave === "Leave")) ? (
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-3">
                                            <label className="w-100 mb-2"><b> Remarks</b></label>
                                            <Field as="textarea" className="form-control mb-2" name="remarks" style={{ height: '30px' }} maxLength="400"></Field>
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="remarks" />
                                    </jnb.Col>
                                ) : ""}

                            </jnb.Row>
                        </jnb.Row>
                        {LeaveRegformIk?.values?.total_no_of_days !== "" ? (
                            <jnb.Row className="border pt-2 mt-4 m-0">
                                <jnb.Row className="m-0 pt-2 ">
                                    <jnb.InputGroup className="mb-3">
                                        <div class="alert alert-danger w-100">
                                            <span ><b style={{ color: "red", fontSize: "19px" }}>No.Of days applying for  {LeaveRegformIk?.values?.typeOfLeave === "Movement" ? "Regularization" : LeaveRegformIk?.values?.typeOfLeave}  -</b>&nbsp;&nbsp;<b style={{ fontSize: "19px" }}>{LeaveRegformIk.values.total_no_of_days} days</b></span>
                                            <Field type="hidden" name="total_no_of_days" className="form-control mb-2" readable={true} />
                                        </div>
                                    </jnb.InputGroup>
                                </jnb.Row>

                            </jnb.Row>
                        ) : ""}
                        <jnb.Row className="pt-2 m-0">
                            <div className='d-flex flex-row justify-content-end pe-0'>
                                <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4">Save</button>
                            </div>
                        </jnb.Row>
                        <jnb.Row className="pt-0 m-0 mb-5">
                            <center> 
                                {/* {getEmpLeaveDetails[0]?.leave_type !== "" &&<b>{getEmpLeaveDetails[0]?.leave_type} leave start from {moment(getEmpLeaveDetails[0]?.from_date).format('DD-MM-YYYY')} to {moment(getEmpLeaveDetails[0]?.to_date).format('DD-MM-YYYY')}</b>} */}
                                <pre></pre>
                                <b>Note :</b><b style={{ color: "red" }}>Does not include Sundays and Second Saturday</b>

                            </center>
                        </jnb.Row>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </>) : (<>
            <jnb.Row className=" pt-2">
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Leave Request Form
                        </li>
                    </ol>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
                    <button type="button" className=" p-2  text-dark bg-opacity-50  backbutton" style={{ float: "right" }}
                        onClick={() => { NavigateToLeaveDetailsReport() }}>Previous Leaves Status</button>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                <FormikProvider value={LeaveRegformIk}>
                    <Form onSubmit={LeaveRegformIk.handleSubmit} onChange={LeaveRegformIk.handleChange}>
                        <jnb.Row className=" pt-3 border">
                            <div style={{ paddingTop: "100px", paddingBottom: "100px" }}>
                                <jnb.Row className="  m-0">
                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}></jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="">
                                        <p className="ms-5"><FaCircleCheck fontSize={80} className=" text-success ms-5" /></p>
                                        <span ><b style={{ color: "red" }}>
                                            {LeaveRegformIk?.values?.typeOfLeave === "Movement" ? "Regularization" : LeaveRegformIk?.values?.typeOfLeave}
                                        </b><b> Request  Successfully Submitted.</b>
                                            <pre></pre>Check <b>Previous Leaves Status</b> for further Details</span><br />
                                    </jnb.Col>
                                </jnb.Row>
                            </div>
                        </jnb.Row>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </>)}
    </>)
}
export default LeavesOrMovementRegistration;
