const empInitial = {
    empid: null,
    empdata:[],
    empkpdata:[],
    empkpindicatordata:[],
    empkpstatusdata:[],
    empstatusflag:false,
}
function EmployeeReducer(state = empInitial, action) {
    switch (action.type) {
        case "REPORT_EMP_ID": {
            return { ...state, empid: action.payload };
        }
        case "EMPLOYEE_DATA": {
            return { ...state, empdata: action.payload };
        }
        case "EMPLOYEE_KP_DATA": {
            return { ...state, empkpdata: action.payload };
        }
        case "EMPLOYEE_KP_INDICATOR_DATA": {
            return { ...state, empkpindicatordata: action.payload };
        }
        case "EMPLOYEE_KP_STATUS_DATA": {
            return { ...state, empkpstatusdata: action.payload };
        }
        case "EMP_STATUS": {
            return { ...state, empstatusflag: action.payload };
        }
        default:
            return state;
    }
}
export default EmployeeReducer;
