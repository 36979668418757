import { useDispatch, useSelector } from "react-redux";
import { config } from "../../../CommonUtils/CommonApis";
import React, { useEffect, useState } from "react";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import { Link,  useNavigate, useParams } from "react-router-dom";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_IO, ROLE_RO } from "../../../CommonUtils/ApplicationRoles";
export default function LoginWiseReport() {
    const logindetailsatloginReport = useSelector((stateloginreport) => stateloginreport.reducers.loginreducer.userLoginDetials);
    const { roleId } = useParams();
    const current_role_id = roleId;
    const [userRoleAtLoginReport, setUserRoleAtLoginReport] = useState(current_role_id);
    const [showiodata, setiodata] = useState([]);
    useEffect(() => {
        setUserRoleAtLoginReport(current_role_id);
        CommonAxiosGet(config.url.COMMON_URL + "employee/" + current_role_id + "/" + logindetailsatloginReport?.username).then((res) => {
            if (res.data.scode === "01") {
                setiodata(res.data.employee_data_list);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }, [roleId]);
    const navigateAtLoginReport = useNavigate();
    const dispatchAtLoginReport = useDispatch();
    function redirectToPage(e, empid, pagename) {
        dispatchAtLoginReport({ type: "REPORT_EMP_ID", payload: empid });
        navigateAtLoginReport("/" + pagename);}
        function showEmpKPStatusReportAtLoginReport(e, empid, pageflag, status) {
            navigateAtLoginReport("/pmsempprintform", { state: { empid: empid, pageFlag: pageflag, status: status } })}
    return (<>

                <div className="card">
                    <div className="card-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/homepage">Home</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">My Team Appraisal-{userRoleAtLoginReport}</li>
                        </ol>
                    </div>
                </div>
                <div className="head-text mt-4"> <h1>My Team Appraisal-{userRoleAtLoginReport}</h1></div>
                <div className="border p-3 pb-0 mb-3">
                    <div className="table-responsive">
                        
                        <table className="table table-condensed table-bordered table-striped table-responsive">
    <thead>
        <tr>
            <th>Sl.No</th>
            <th>ID</th>
            <th>Name</th>
            <th>Designation</th>
            <th>Mobile Number</th>
            <th>EmailId</th>
            <th>Rating Status</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody style={{ textAlign: "left" }}>
        {showiodata !== undefined && showiodata?.map((iodataAtLoginReport, LoginReportIndex) => (
            <tr key={LoginReportIndex}>
                <td>{LoginReportIndex + 1}</td>
                <td>{iodataAtLoginReport.emp_id}</td>
                <td>{iodataAtLoginReport.emp_name}</td>
                <td>{iodataAtLoginReport.designation_name}</td>
                <td>{iodataAtLoginReport.mobile_no}</td>
                <td>{iodataAtLoginReport.email}</td>
                {userRoleAtLoginReport === ROLE_IO && (<td>
                    {iodataAtLoginReport.self_rating_status === "Pending at Reporting Officer" ? "Pending at Reviewing Officer" : iodataAtLoginReport.self_rating_status}</td>)}
                {userRoleAtLoginReport === ROLE_RO && (<td>
                    {iodataAtLoginReport.self_rating_status === "Pending at Reporting Officer" ? "Pending at Reviewing Officer" : iodataAtLoginReport.self_rating_status}
                    </td>)}
                {userRoleAtLoginReport === ROLE_IO && (<td>
                    {iodataAtLoginReport.self_rating_status === "Pending at Immediate Officer" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                    onClick={(e) => redirectToPage(e, iodataAtLoginReport.emp_id, 'iopmsform')}>Action</button>)}
                        {iodataAtLoginReport.self_rating_status === "Pending at Reporting Officer" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                                onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_IO, "PENDING_AT_RO")}>View</button>)}
                        {iodataAtLoginReport.self_rating_status === "Completed" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                        onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_IO, "COMPLETED")}>View</button>)}
                        {iodataAtLoginReport.self_rating_status === "Pending at Appraise" && (<button type="button"className="btn rounded-0 btn-primary btn-sm"
                        onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_IO, "SELF")}>View</button>)}
                    </td>
                )}

                {userRoleAtLoginReport === ROLE_RO && (<td>{iodataAtLoginReport.self_rating_status === "Pending at Reporting Officer" && (<button type="button"
                className="btn rounded-0 btn-primary btn-sm" onClick={(e) => redirectToPage(e, iodataAtLoginReport.emp_id, 'ropmsform')}>Action</button>)}

                {iodataAtLoginReport.self_rating_status === "Completed" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_RO, "COMPLETED")}>View</button>)}
                
                {iodataAtLoginReport.self_rating_status === "Pending at Appraise" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_RO, "SELF")}>View</button>)}
                
                {iodataAtLoginReport.self_rating_status === "Pending at Immediate Officer" && (<button type="button" className="btn rounded-0 btn-primary btn-sm"
                onClick={(e) => showEmpKPStatusReportAtLoginReport(e, iodataAtLoginReport.emp_id, ROLE_RO, "PENDING_AT_RO")}>View</button>)}
                    </td>)}
                </tr>
            ))}
        </tbody>
    </table>
    </div>
    </div>
           
    </>)
}
