import { Field, FormikProvider, useFormik,Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import { config } from "../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
export default function DashboardOfPayDetails() {
  const [showDashboardOfPayDetails, setDashboardOfPayDetails] = useState([])
  const navigateAtPayDeshboard = useNavigate()
  const DashboardOfPayformIk = useFormik({
    enableReinitialize: true,
    initialValues: {technology:""},
    onSubmit: (values) => {console.log(null);},
  });

  function GetDashboardOfPayData() {
    let Url = (config.url.COMMON_URL + "employeepaydetailsreport");
    CommonAxiosGet(Url).then((resdashboard) => {
      if (resdashboard?.data?.status === true) {setDashboardOfPayDetails(resdashboard?.data?.employeepaydetailsreport)}
      else {setDashboardOfPayDetails([])}
    })
  }
  useEffect(() => {
    GetDashboardOfPayData()
  }, []);
  function navigatetoInofofPayDetials(category_id_dashboard, type_dashboard) {
    if (category_id_dashboard === 1) {
      localStorage.setItem("category_id", category_id_dashboard);
      localStorage.setItem("category", "reguler");
      localStorage.setItem("type", type_dashboard);
      navigateAtPayDeshboard("/infoofpaydetails")
    } else if (category_id_dashboard === 2) {
      localStorage.setItem("category_id", category_id_dashboard);
      localStorage.setItem("category", "contract");
      localStorage.setItem("type", type_dashboard);
      navigateAtPayDeshboard("/infoofpaydetails")
    }
    else if (category_id_dashboard === 4) {
      localStorage.setItem("category_id", category_id_dashboard);
      localStorage.setItem("category", "intern");
      localStorage.setItem("type", type_dashboard);
      navigateAtPayDeshboard("/infoofpaydetails")
    }
    else if (category_id_dashboard === "3") {
      localStorage.setItem("category_id", category_id_dashboard);
      localStorage.setItem("category", "govt");
      navigateAtPayDeshboard("/infoofpaydetails")
    }
  }
  const renderCategoryLink = (categoryIdDashboard, typeDashboard, valueDashboard, isEmptyCheck = false) => {
    return (
      <a href={() => false} className="atag" onClick={(e) => {
          e.preventDefault();
          if (isEmptyCheck && valueDashboard === 0) {Swal.fire("No Data Found", "info");} 
            else {navigatetoInofofPayDetials(categoryIdDashboard, typeDashboard);}}}>
        {valueDashboard}
      </a>
    );
  };
  return (<>

    <jnb.Row className="px-3 mb-2 pt-3" >
      <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/homepage">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Active Employees</li>
        </ol>
      </jnb.Col>
    </jnb.Row>
    <FormikProvider value={DashboardOfPayformIk}>
      <Form onSubmit={DashboardOfPayformIk.handleSubmit} onChange={DashboardOfPayformIk.handleChange}>
        <Field type="hidden" name="technology" className="form-control" />
        <jnb.Row className="px-3 pt-1">
          <div className="table-responsive">
            <table className="table table-condensed table-bordered table-striped table-responsive">
              <thead>
                <tr>
                  <th rowSpan={2}>Sl.No new</th>
                  <th colSpan={3}>Government</th>
                  <th colSpan={3}>Regular</th>
                  <th colSpan={3}>Contract</th>
                  <th colSpan={3}>Intern</th>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>Updated</th>
                  <th>Pending</th>
                  <th>Total</th>
                  <th>Updated</th>
                  <th>Pending</th>
                  <th>Total</th>
                  <th>Updated</th>
                  <th>Pending</th>
                  <th>Total</th>
                  <th>Updated</th>
                  <th>Pending</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td>1</td>
                  <td>{renderCategoryLink("3", "total", 1)}</td>
                  <td>{renderCategoryLink("3", "updated", 0)}</td>
                  <td>{renderCategoryLink("3", "pending", 1)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.reg_cat_id, "total", showDashboardOfPayDetails[0]?.reg_tot, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.reg_cat_id, "updated", showDashboardOfPayDetails[0]?.reg_updated, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.reg_cat_id, "pending", showDashboardOfPayDetails[0]?.reg_pending, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.con_cat_id, "total", showDashboardOfPayDetails[0]?.con_tot, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.con_cat_id, "updated", showDashboardOfPayDetails[0]?.con_updated, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.con_cat_id, "pending", showDashboardOfPayDetails[0]?.con_pending, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.int_cat_id, "total", showDashboardOfPayDetails[0]?.int_tot, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.int_cat_id, "updated", showDashboardOfPayDetails[0]?.int_updated, true)}</td>
                  <td>{renderCategoryLink(showDashboardOfPayDetails[0]?.int_cat_id, "pending", showDashboardOfPayDetails[0]?.int_pending, true)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </jnb.Row>
      </Form>
    </FormikProvider>
  </>)
}
