import Swal from "sweetalert2";
import { config } from "./CommonApis";
import { CommonAxiosGet } from "./CommonAxios";

export function viewImage(fileName) {
    let url = config.url.COMMON_URL + "file-retrival?fileName=" + fileName;
    CommonAxiosGet(url).then((res) => {
        if (res.data.status === true) {
            let base64String = res.data.file;
            const [dataType, data] = base64String.split(",");
            const finalFileType = dataType.match(/\/(.+);/)[1];
            if (finalFileType === "pdf") {
                const newTab = window.open();
                newTab.document.body.innerHTML =
                    `<iframe src="${base64String}" width="100%" height="100%"></iframe>`;
            }
            else if (finalFileType === "text") {
                const newTab = window.open();
                newTab.document.body.innerHTML =
                    `<iframe src="${base64String}" width="100%" height="100%"></iframe>`;
            }
            else {
                const swalPopup = Swal.fire({
                    html: `<img src="${base64String}" class="img-thumbnail" />`,
                    width: '80%',
                    showCloseButton: true,
                    showConfirmButton: false,
                    customClass: {
                        content: 'custom-modal-content',
                    },
                });
                window.addEventListener('popstate', () => {
                    swalPopup.close();
                });
            }
        }
        else {
            alert("image not found")
        }
    })
}