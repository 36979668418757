import { ErrorMessage, Formik, Field, FieldArray, useFormik, FormikProvider,Form} from "formik";
import React, { useEffect, useState } from "react";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { config } from "../../../../CommonUtils/CommonApis";
import { BiTrash } from "react-icons/bi";
import { VscDiffAdded } from "react-icons/vsc";
import Swal from "sweetalert2";
import { ROLE_EMPLOYEE } from "../../../../CommonUtils/ApplicationRoles";
import CommonPost from "../../../../CommonUtils/CommonPost";
import { uploadPdf } from "../../../../CommonUtils/UploadPdf";
import { FaFilePdf } from "react-icons/fa6";
import getPDFView from "../../../../CommonUtils/PdfView";
export default function EmployeePMSForm() {
    const emplogindataAtemppms = useSelector((stateemp) => stateemp.reducers.loginreducer.userLoginDetials);
    const handleCloseAtemppms = () => setShowAtemppms(false);
    const handleShowAtemppms = () => setShowAtemppms(true);
    const [showAtemppms, setShowAtemppms] = useState(false);
    const [showFormPageemppms, setFormPageemppms] = useState(false);
    const [showEmpDataemppms, setEmpDataemppms] = useState([]);
    const [showEmpKpDataemppms, setEmpKpDataemppms] = useState([]);
    const [showEmpInitialValuesemppms, setEmpInitialValuesemppms] = useState({
        finalStatus: false,
        strength: "",
        areas_of_imporvement: "",
        carrer_aspirations: "",
        addl_key_objective: "",
        file_upload: "",
        file_uploadhidden: "",
        upload_remarks: "",
    });
    const [showEmpKpIndicatorDataemppms, setEmpKpIndicatorDataemppms] = useState([]);

    const validationemppms = Yup.object().shape({
        kp1: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })
        ),
        kp2: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })
        ),
        kp3: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })
        ),
        kp4: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })
        ),
        kp5: Yup.array().of(Yup.object().shape({
            kpindicatorDesc: Yup.string().required("Please Enter Description").nullable(),
            kpremarks: Yup.string().required("Please Enter Remarks").nullable(),
            rating: Yup.string().required("Please Select Rating").nullable(),
        })
        ),

        strength: Yup.string().required("Please Enter Strengths").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        areas_of_imporvement: Yup.string().required("Please Enter Areas of Improvements").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        carrer_aspirations: Yup.string().required("Please Enter Career Aspirations").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
        addl_key_objective: Yup.string().required("Please Enter Key Objective").trim().min(2, 'Minimum 2 Characters required').max(400, 'Maximum 400 Characters allowed').nullable(),
    });
    const EmployeePMSFormformIk = useFormik({
        enableReinitialize: true,
        initialValues: showEmpInitialValuesemppms,
        onSubmit: (values) => {
            const payload = {
                empId: emplogindataAtemppms?.username,
                userId: emplogindataAtemppms?.username, ...EmployeePMSFormformIk.values
            }
            const payload2 = {
                empId: emplogindataAtemppms?.username,
                userId: emplogindataAtemppms?.username,
                finalStatus: EmployeePMSFormformIk?.values?.finalStatus,
                strength: EmployeePMSFormformIk?.values?.strength,
                areas_of_imporvement: EmployeePMSFormformIk?.values?.areas_of_imporvement,
                carrer_aspirations: EmployeePMSFormformIk?.values?.carrer_aspirations,
                addl_key_objective: EmployeePMSFormformIk?.values?.addl_key_objective,
                file_upload: EmployeePMSFormformIk?.values?.file_upload,
                file_uploadhidden: EmployeePMSFormformIk?.values?.file_uploadhidden,
                upload_remarks: EmployeePMSFormformIk?.values?.upload_remarks,
            }
            EmpsubmitAlertAtippms(payload, payload2);
        },
        validationSchema: validationemppms,
    });

    function GetApprialData() {
        let Url = (config.url.COMMON_URL + "pms/employee/appraisal/" + emplogindataAtemppms?.username);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setEmpDataemppms(res.data.employee_data);
                let b = {};
                res.data.employee_kp_data.forEach(el => {
                    b[el.kp_data_flag_status] = (b[el.kp_data_flag_status] || 0) + 1;
                })
                var result = res.data.employee_kp_data.find(({ kp_data_flag_status }) => !kp_data_flag_status);
                if (result) { result.kp_data_flag_status = true }

                setEmpKpDataemppms(res.data.employee_kp_data);
                setEmpKpIndicatorDataemppms(res.data.employee_kp_indicator_data);

                for (let i = 0; i < res.data.employee_kp_data.length; i++) {
                    if (res.data.employee_kp_data[i]?.kp_sub_indicator_flag === "Y") {

                        const formikDataYes = res.data.employee_kpstatus_data.filter((testing) => testing.kp_id === res.data.employee_kp_data[i].kp_id)
                        let kpindicatordata = res.data.employee_kp_indicator_data.filter((rowdata) => rowdata.kp_id === res.data.employee_kp_data[i].kp_id);//1,2

                        let currentArray = [];
                        for (var j = 0; j < kpindicatordata.length; j++) {
                         
                            if (formikDataYes.length > 0) {
                                let rowObject = {
                                    rating: formikDataYes[j]?.emp_self_rating?.trim(), kpremarks: formikDataYes[j]?.emp_self_rating_remarks,
                                    kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id, kpindicatorDesc: kpindicatordata[j]?.kp_indicator_description
                                    , emp_rating_weightage: formikDataYes[j]?.emp_rating_weightage === null ? 0 : formikDataYes[j]?.emp_rating_weightage, kp_weightage: res.data.employee_kp_data[i]?.kp_weightage

                                };
                                currentArray.push(rowObject);
                            }
                            else {
                                let rowObject = {
                                    rating: "", kpremarks: "",
                                    kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id, kpindicatorDesc: kpindicatordata[j]?.kp_indicator_description,
                                    emp_rating_weightage: 0, kp_weightage: res.data.employee_kp_data[i]?.kp_weightage
                                };
                                currentArray.push(rowObject);
                            }
                        }
                        
                        let kpid = res.data.employee_kp_data[i]?.kp_id;
                        setEmpInitialValuesemppms((prevState) => ({ ...prevState, ["kp" + kpid]: currentArray,
                        strength: res?.data?.employee_data[0]?.self_strengths === null ? "" : res?.data?.employee_data[0]?.self_strengths,
                        areas_of_imporvement:res?.data?.employee_data[0]?.self_areas_of_imporvement === null ? "" : res?.data?.employee_data[0]?.self_areas_of_imporvement,
                        carrer_aspirations:res?.data?.employee_data[0]?.self_carrer_aspirations === null ? "" : res?.data?.employee_data[0]?.self_carrer_aspirations,
                        addl_key_objective:res?.data?.employee_data[0]?.self_addl_key_objective === null ? '' : res?.data?.employee_data[0]?.self_addl_key_objective
                         }))
                        EmployeePMSFormformIk.setFieldValue(`kp${kpid}`, currentArray);
                        EmployeePMSFormformIk.setFieldValue("strength", res?.data?.employee_data[0]?.self_strengths == null ? "" : res?.data?.employee_data[0]?.self_strengths)
                        EmployeePMSFormformIk.setFieldValue("areas_of_imporvement", res?.data?.employee_data[0]?.self_areas_of_imporvement === null ? "" : res?.data?.employee_data[0]?.self_areas_of_imporvement)
                        EmployeePMSFormformIk.setFieldValue("carrer_aspirations", res?.data?.employee_data[0]?.self_carrer_aspirations === null ? "" : res?.data?.employee_data[0]?.self_carrer_aspirations)
                        EmployeePMSFormformIk.setFieldValue("addl_key_objective", res?.data?.employee_data[0]?.self_addl_key_objective === null ? '' : res?.data?.employee_data[0]?.self_addl_key_objective)


                    } else {console.log(null) }
                }
                if (res?.data?.employee_data[0]?.self_confirmation_status === true) {
                    setShowAtemppms(false);
                    setFormPageemppms(true);
                } else {
                    handleShowAtemppms();
                }
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    useEffect(() => {

        GetApprialData();

    }, []);
    function Calculation(gradeEmppms, weightage, index, rowid) {
        let Total = 0;
      
        if (gradeEmppms === "A") {
            Total = (parseFloat(weightage) / 100) * 100;
        }
        else if (gradeEmppms === "B") {
            Total = (parseFloat(weightage) / 100) * 80;
        }
        else if (gradeEmppms === "C") {
            Total = (parseFloat(weightage) / 100) * 60;
        }
        else if (gradeEmppms === "D") {
            Total = (parseFloat(weightage) / 100) * 50;
        }
        EmployeePMSFormformIk.setFieldValue(`kp${rowid}[${index}].emp_rating_weightage`, Total);
    }
    const EmpsubmitAlertAtippms = (values, payload2) => {
        Swal.fire({
            title: EmployeePMSFormformIk?.values?.finalStatus === true ? 'Are you sure you want to Final Submit?' : 'Are you sure you want to Save?',
            text: EmployeePMSFormformIk?.values?.finalStatus === true ? 'Please check it once before Final Submiting?' : 'Please check it once before Save?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: EmployeePMSFormformIk?.values?.finalStatus === true ? 'Final Submit' : 'Save',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                let techFlagEmppms = Object.keys(values).includes("kp4");
                let techCountEmppms = Object.keys(values).includes("kp4") === true ? values["kp4"] : [];
                let techFlagCountsEmppms = {};
                techCountEmppms?.forEach(ell => {
                    techFlagCountsEmppms[ell.rating] = (techFlagCountsEmppms[ell.rating] || 0) + 1;
                })
                if (techFlagEmppms) {
                    if (techCountEmppms?.length === techFlagCountsEmppms["NA"]) {
                        Sweetalert("Please give any one Technical Skill rating is needed, please check it once", 'warning');
                        return false;
                    }
                }
                CommonPost.kpAreasEmployeepostn(values, ROLE_EMPLOYEE).then((resKP) => {
                        if (resKP.data.scode === "01") {
                            CommonPost.employeenewPost(payload2, ROLE_EMPLOYEE).then((res) => {
                                if (res.data.scode === "01") {
                                    SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                        if (isConfirm.value) {
                                            window.location.reload();
                                        }
                                    })
                                }
                                else {
                                    Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                                }
                            }).catch(() => {console.log(null) });
                        }
                        else if (resKP.data.scode === "02") {
                            Sweetalert(resKP.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {console.log("err")});

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };

    const calculateAverageAtemppms = (kp) => {
        return parseFloat(
            (EmployeePMSFormformIk?.values[kp]?.reduce((a, v) => a + v.emp_rating_weightage, 0) / EmployeePMSFormformIk?.values[kp]?.filter((rowdata) => rowdata.rating !== "NA")?.length).toFixed(2)
        );
    }
    const totalAverage = parseFloat(['kp1', 'kp2', 'kp3', 'kp4', 'kp5'].reduce((acc, kp) => acc + calculateAverageAtemppms(kp), 0));

    const getGrade = (average) => {
        if (average >= 90 && average <= 100) {
            return "A";
        } else if (average >= 80 && average < 90) {
            return "B";
        } else if (average >= 60 && average < 80) {
            return "C";
        } else if (average < 60 && average > 0) {
            return "D";
        }
        return "";
    };

    const grade = getGrade(totalAverage);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return (<>
        <jnb.Modal size="xl" dialogClassName="my-modal" show={showAtemppms} onHide={handleCloseAtemppms} backdrop="static" keyboard={false}>
            <Formik initialValues={{ acceptFlag: false }} enableReinitialize={true}
                onSubmit={(values) => {
                    handleCloseAtemppms();
                    setFormPageemppms(true);
                }}>
                {props => (
                    <Form onSubmit={props.handleSubmit}>
                        <jnb.Modal.Header className="modal-header" >
                            <jnb.Modal.Title>  <b style={{ color: "white" }}>HRMS </b></jnb.Modal.Title>
                        </jnb.Modal.Header>
                        <jnb.Modal.Body>
                            <jnb.Card.Body >
                                <div className="table-responsive">
                                    <h4 style={{ fontSize: "16px" }}>General Instructions:</h4>
                                    <table className="table table-condensed table-bordered table-striped table-responsive">
                                        <tbody style={{ fontSize: "12px" }}>
                                            <tr><td>1</td>
                                                <td>The Annual Performance Appraisal shall be reviewed by SELF, IMMEDIATE OFFICER and REVIEWING OFFICER for the period 1st April 2023 - 31st March 2024.</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>The Key Result Areas shall be carried out for measuring the individual performance / achievement in 6 perspectives with
                                                    total weightage of 100 marks -  1. Customer / Project Management (Weightage 25) / 2. Internal Process Improvement (Weightage 25) /
                                                    3. Learning and Personal Development ( Weightage 20) /
                                                    4. Technical Skills (Weightage 15) and 5. Behavioural Skills (Weightage 15).
                                                    The references are attached under each perspective for ease of reference.</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>The annual appraisal proforma is formula enabled and scores shall  automatically populate based on the inputs entered.</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>The following process shall be followed for effective method of finalizing the rating scale to avail Annual Increment for
                                                    the year 2023-2024.<br />a) Employee should discuss with Immediate officer / Reviewing officer to fill up the Key result area under different perspectives with weightage of marks, whichever applicable
                                                    <br />to the Designated Role of the respective functions together with Functional and Behavioural Skills. <br />
                                                    b) An employee shall initiate his / her assessment against each KRA by mentioning the activities carried out during the period and assigning self-rating together with remarks.
                                                    <br /> The employee scores will sum up to an average under each perspective. <br />
                                                    c) The Immediate Officer upon receipt of ratings from the concerned employee shall review the Employees’ self-rating together with remarks and submit the
                                                    <br />IO rating together with remarks for each perspective. The IO scores will sum up to an average of each perspective. <br />
                                                    d) The employee rating and IO rating will be calculated based on the following range
                                                    <table className="table table-condensed table-bordered table-striped table-responsive"
                                                        style={{ border: '1px solid', width: '50%', fontSize: "10px" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>S.No</th>
                                                                <th>Rating Scale</th>
                                                                <th>Definition</th>
                                                                <th>% of Achievement of the KRA</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ textAlign: "center" }}>
                                                            <tr><td>1</td>
                                                                <td>A</td>
                                                                <td>Exceptional Performance</td>
                                                                <td> <b>&#8805; </b> 90% and  <b>&#8804;</b> 100%</td></tr>
                                                            <tr><td>2</td>
                                                                <td>B</td>
                                                                <td>Very Good Performance</td>
                                                                <td><b>&#8805; </b> 80% <b>&#60;</b> 90%</td></tr>
                                                            <tr><td>3</td>
                                                                <td>C</td>
                                                                <td>Fair Performance</td>
                                                                <td><b>&#8805; </b> 60% <b>&#60;</b> 80%</td></tr>
                                                            <tr><td>4</td>
                                                                <td>D</td>
                                                                <td>Under Performance</td>
                                                                <td><b >&#60; </b> 60%</td></tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>In case of any queries, please contact your Project Manager / HR team </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Field type="checkbox" name="acceptFlag" />&nbsp;I Accept all
                            </jnb.Card.Body>
                        </jnb.Modal.Body>
                        <jnb.Modal.Footer> <div className="d-grid">
                            {props.values.acceptFlag === true && (<button type="submit" className="btn btn-success">Proceed</button>)}
                        </div>
                        </jnb.Modal.Footer>
                    </Form>)}
            </Formik>
        </jnb.Modal>
        {showFormPageemppms && (<>
            <div className="card">
                <div className="card-body">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Employee Appraisal Form</li>
                    </ol>
                </div>
            </div>

            <div style={{ marginBottom: '48px' }}> <h2> Appraisal Form </h2></div>
            <div className="card" >
                <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span> Name (ID):</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.emp_name}({showEmpDataemppms[0]?.emp_id})</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Date of Joining:</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.doj.split("-").reverse().join("/")}</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Wing:</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.zone_name}</b></span>
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span> IO Name(ID):</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.io_emp_name}({showEmpDataemppms[0]?.io_emp_id})</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>RO Name(ID):</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.ro_emp_name}({showEmpDataemppms[0]?.ro_emp_id})</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Designation</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.designation_name}</b></span>
                    </jnb.Col>
                </jnb.Row>
                <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Email:</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.email}</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Band:</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.band}</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span>Contact:</span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                        <span><b>{showEmpDataemppms[0]?.mobile_no}</b></span>
                    </jnb.Col>
                </jnb.Row>

            </div>
            <br />
            <FormikProvider value={EmployeePMSFormformIk}>
                <Form onSubmit={EmployeePMSFormformIk.handleSubmit} onChange={EmployeePMSFormformIk.handleChange}>
                    {showEmpKpDataemppms !== null && showEmpKpDataemppms.map((row, i) => {
                        return (<React.Fragment key={i}>
                            <div className="table-responsive">
                                <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>

                                <table className="table table-condensed table-bordered table-striped table-responsive"
                                    style={{ border: '3px solid lightblue', width: '87%', borderCollapse: 'collapse', }}>
                                    <thead>
                                        <tr>
                                            <th width="1" style={{ textAlign: "left" }} >S.No</th>
                                            <th width="170" style={{ textAlign: "left" }}>Key Performance Areas Description</th>
                                            <th width="50">Rating <font style={{ color: "red" }}>*</font></th>
                                            <th width="50">Remarks <font style={{ color: "red" }}>*</font></th>
                                            <th width="1"></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "center" }}>
                                        {
                                            showEmpKpIndicatorDataemppms !== undefined && showEmpKpIndicatorDataemppms.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                                return (
                                                    <React.Fragment key={k}>
                                                        <tr>
                                                            <td >{k + 1}
                                                            </td>
                                                            <td>
                                                                {kpidata.kp_indicator_id >= 9990 ? (<>
                                                                    <span style={{ color: "red" }}>{kpidata.kp_indicator_description}</span></>)
                                                                    : (<>{kpidata.kp_indicator_description}</>)}
                                                                {kpidata.mandatory_kp_flag === true ? (<> <font style={{ color: "red" }}>*</font>
                                                                </>) : (<></>)}
                                                            </td>
                                                            <td width="50" >
                                                                <Field as="select" className='form-control' name={`kp${row.kp_id}[${k}].rating`}
                                                                    onChange={(e) => { Calculation(e.target.value, row.kp_weightage, k, row.kp_id) }}>
                                                                    <option value="">--Select--</option>
                                                                    <option value="A">A</option>
                                                                    <option value="B">B</option>
                                                                    <option value="C">C</option>
                                                                    <option value="D">D</option>
                                                                    <option value="NA">NA</option>
                                                                </Field>

                                                                <ErrorMessage name={`kp${row.kp_id}[${k}].rating`} component="div" className="text-danger" ></ErrorMessage>
                                                            </td>
                                                            <td >
                                                                <Field as="textarea" className="form-control" name={`kp${row.kp_id}[${k}].kpremarks`} ></Field>
                                                                <ErrorMessage name={`kp${row.kp_id}[${k}].kpremarks`} component="div" className="text-danger" ></ErrorMessage></td>
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                            )
                                        }

                                        <FieldArray name={`kp${row.kp_id}`}>
                                            {(formikFieldArray) => {
                                                return (
                                                    formikFieldArray.form.values[`kp${row.kp_id}`].map(
                                                        (qual, index) => {
                                                            return (

                                                                
                                                                <React.Fragment key={index}>
                                                                    {(index < showEmpKpIndicatorDataemppms.filter((kpi) => kpi.kp_id === row.kp_id)?.length) ? (<>
                                                                    
                                                                    </>) : (<>

                                                                        <tr>
                                                                            <td>{(index + 1)}</td>
                                                                            <td> <Field type="text" className="form-control" name={`[kp${row.kp_id}][${index}].kpindicatorDesc`} ></Field>
                                                                                <ErrorMessage name={`[kp${row.kp_id}][${index}].kpindicatorDesc`} component="div" className="text-danger" ></ErrorMessage>
                                                                            </td>
                                                                            <td width="100">
                                                                                <Field as="select" className='form-control' name={`[kp${row.kp_id}][${index}].rating`}
                                                                                    onChange={(e) => { Calculation(e.target.value, row.kp_weightage, index, row.kp_id) }}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="A">A</option>
                                                                                    <option value="B">B</option>
                                                                                    <option value="C">C</option>
                                                                                    <option value="D">D</option>
                                                                                    <option value="NA">NA</option>
                                                                                </Field>
                                                                                <ErrorMessage name={`[kp${row.kp_id}][${index}].rating`} component="div" className="text-danger" ></ErrorMessage>
                                                                            </td>
                                                                            <td >
                                                                                <Field as="textarea" className="form-control" name={`[kp${row.kp_id}][${index}].kpremarks`}></Field>&nbsp;
                                                                                <ErrorMessage name={`[kp${row.kp_id}][${index}].kpremarks`} component="div" className="text-danger" ></ErrorMessage>&nbsp;
                                                                            </td>

                                                                            <td> {(index !== 0) ? <>
                                                                                <button type="button" className="button-titile btn btn-sm btn-danger"
                                                                                    onClick={() => { formikFieldArray.remove(index); }} >
                                                                                    <BiTrash></BiTrash>
                                                                                </button> </> : ''}</td>
                                                                        </tr>

                                                                    </>)}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                )
                                            }}
                                        </FieldArray>
                                        <tr className='table-active'>
                                           
                                            <td colSpan={3}></td>
                                            <td>
                                                {showEmpDataemppms[0]?.self_confirmation_status ? (<> </>) : (<>
                                                    {(EmployeePMSFormformIk.values[`kp${row.kp_id}`].length < (showEmpKpIndicatorDataemppms.filter((kpi) => kpi.kp_id === row.kp_id && kpi.kp_indicator_id < 9990)?.length + 5)) ? (
                                                        <>
                                                            <FieldArray name={`kp${row.kp_id}`}>
                                                                {(formikFieldArray) => {
                                                                    return formikFieldArray.form.values[`kp${row.kp_id}`]?.map(
                                                                        (LJP, indexLJP) => {
                                                                            return (
                                                                                <React.Fragment key={indexLJP}>

                                                                                    {(indexLJP === 0) ? <>
                                                                                        <button type="button" className="button-titile btn btn-sm btn-success"
                                                                                            onClick={() => {
                                                                                                formikFieldArray.push({
                                                                                                    rating: "", kpremarks: "", kpIndicatorFlag: "N", kpIndicatorId: 999, kpindicatorDesc: "", kpId: row.kp_id
                                                                                                    , emp_rating_weightage: 0, kp_weightage: row.kp_weightage
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            <VscDiffAdded></VscDiffAdded>
                                                                                        </button> </> : ''}
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                }}
                                                            </FieldArray>
                                                        </>
                                                    ) : (<></>)}
                                                </>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>)
                    })}
                   

                    <table className="table table-condensed table-bordered table-striped table-responsive"
                        style={{ border: '3px solid lightblue', width: '47%', borderCollapse: 'collapse', }}>
                        <tbody style={{ textAlign: "left" }}>
                           
                            <tr>
                                <td> Employee Rating :</td>
                                <td style={{ color: "green" }}>{(grade && <b>{grade}</b>)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="table-responsive">
                        <br></br>
                        <table className="table table-condensed table-bordered table-striped table-responsive"
                            style={{ border: '3px solid lightblue', width: '87%', borderCollapse: 'collapse', }}>
                            <tbody style={{ textAlign: "left" }}>
                                <tr>
                                    <td>Strengths<font style={{ color: "red" }}>*</font></td>
                                    <td>  <Field as="textarea" className="form-control" name="strength" maxLength="400"></Field>
                                        <ErrorMessage name="strength" component="div" className="text-danger" ></ErrorMessage></td>
                                    <td>Areas for Improvement<font style={{ color: "red" }}>*</font></td>
                                    <td>    <Field as="textarea" className="form-control" name="areas_of_imporvement" maxLength="400"></Field>
                                        <ErrorMessage name="areas_of_imporvement" component="div" className="text-danger" ></ErrorMessage></td>
                                </tr>
                                <tr>
                                    <td> Career Aspirations<font style={{ color: "red" }}>*</font> </td>
                                    <td>  <Field as="textarea" className="form-control" name="carrer_aspirations" maxLength="400"></Field>
                                        <ErrorMessage name="carrer_aspirations" component="div" className="text-danger" ></ErrorMessage></td>
                                    <td>Upload Any Relevant Document</td>
                                    <td> <Field type="hidden" id="file_uploadhidden" name="file_uploadhidden" maxLength="15" className="form-control mb-3" />
                                        <Field type="file" id="file_upload" name="file_upload" maxLength="15" className="form-control mb-3"
                                            onChange={(event) => uploadPdf(event, 'file_uploadhidden', EmployeePMSFormformIk, currentYear, showEmpDataemppms[0]?.emp_id)}
                                        
                                        />
                                        
                                        {(showEmpDataemppms[0]?.file_upload != undefined && showEmpDataemppms[0]?.file_upload != "") ? (<>
                                            <FaFilePdf style={{ fontWeight: "bold", fontSize: "30px", color: "blue", cursor: "pointer" }}
                                                onClick={(e) => { getPDFView(showEmpDataemppms[0]?.file_upload) }} />
                                        </>) : ("")}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Additional accomplishments not covered in Key Objectives above<font style={{ color: "red" }}>*</font></b></td>
                                    <td>
                                        <Field as="textarea" className="form-control" name="addl_key_objective" maxLength="400"></Field>
                                        <ErrorMessage name="addl_key_objective" component="div" className="text-danger" ></ErrorMessage>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div><br />


                    {showEmpDataemppms[0]?.self_confirmation_status ? (<>
                        <center> <h6 style={{ color: "green" }}>* Note: Your Appraisal Form forwarded to next level.</h6 ></center>
                    </>) : (<>
                        <br />
                        <h6 style={{ color: "red" }}>* Note: If you checked below checkbox, it will be considered as final submit, it will go to next level and your data
                            has been freezed,modifications will not be allowed.</h6 >
                        <br />
                        <h4>
                            <Field type="checkbox" name="finalStatus"></Field>&nbsp;&nbsp;<b>Are you sure you want to confirm the data<font style={{ color: "red" }}>*</font></b>
                         
                        </h4>
                        <div className="text-end mb-3">
                            <button type="submit" className="btn rounded-0 btn-success btn-sm"> {EmployeePMSFormformIk?.values?.finalStatus === true ? (<>Final Submit</>) : (<>Save</>)}</button>
                        </div>
                    </>
                    )}

                </Form>
            </FormikProvider >

        </>)
        }



    </>)
}