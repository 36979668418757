import { ErrorMessage, Field, Formik } from "formik";
import { Form } from "react-bootstrap";
import * as jnb from "react-bootstrap";
import { useSelector } from "react-redux";
import { config } from "../../../CommonUtils/CommonApis";
import React, { useEffect, useState } from "react";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as Yup from "yup";
import Swal from "sweetalert2";
import CommonPost from "../../../CommonUtils/CommonPost";
import GetPreview from "../CommonPages/GetPreview";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_IO } from "../../../CommonUtils/ApplicationRoles";
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { allowAlphabetAndSpacesOnly } from "../../../CommonUtils/CommonValidations";

export default function IOAppraisalForm() {
    const [showIOInitialValues, setIOInitialValues] = useState({});
    const iologindata = useSelector((state) => state.reducers.loginreducer.userLoginDetials);
    const employeeId = useSelector((state) => state.reducers.employeereducer.empid);
    const [showIOTabFilledCount, setIOTabFilledCount] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [showIOEmpData, setIOEmpData] = useState([]);
    const [showIOEmpKpData, setIOEmpKpData] = useState([]);
    const [showIOEmpKpStatusData, setIOEmpKpStatusData] = useState([]);
    const IOsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                const kptitle = Object.keys(values).filter(item => item.includes("kp"));//kp3
                if (values.rating === "O") {
                    for (let ko = 1; ko < values[kptitle].length; ko++) {
                        values[kptitle][ko].rating = values[kptitle][0].rating;
                        values[kptitle][ko].kpremarks = values[kptitle][0].kpremarks;
                    }
                }
                CommonPost.kpAreasEmployeepost(values, ROLE_IO)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetioData();
                                }
                            })
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    }; const [showEmpKpIndicatorData, setEmpKpIndicatorData] = useState([]);

    function GetioData() {
        let Url = (config.url.COMMON_URL + "employee/appraisal/IO/" + employeeId);
        CommonAxiosGet(Url).then((res) => {
            if (res.data.scode === "01") {
                setIOEmpData(res.data.employee_data);
                let b = {};
                res.data.employee_kp_data.forEach(el => {
                    b[el.io_data_flag_status] = (b[el.io_data_flag_status] || 0) + 1;
                })
                setIOTabFilledCount(b.true);
                var result = res.data.employee_kp_data.find(({ io_data_flag_status }) => !io_data_flag_status);
                if (result) { result.io_data_flag_status = true };
                setIOEmpKpData(res.data.employee_kp_data);

                setEmpKpIndicatorData(res.data.employee_kp_indicator_data)
                setIOEmpKpStatusData(res.data.employee_kpstatus_data)
                for (let i = 0; i < res.data.employee_kp_data.length; i++) {
                    if (res.data.employee_kp_data[i]?.kp_sub_indicator_flag === "Y") {
                        const formikDataYes = res.data.employee_kpstatus_data.filter((testing) => testing.kp_id === res.data.employee_kp_data[i].kp_id)
                        let kpindicatordata = res.data.employee_kp_indicator_data.filter((rowdata) => rowdata.kp_id === res.data.employee_kp_data[i].kp_id);//1,2
                        let currentArray = [];
                        for (var j = 0; j < kpindicatordata.length; j++) {
                            if (formikDataYes.length > 0) {
                                let rowObject = { rating: formikDataYes[j]?.io_emp_rating?.trim(), kpremarks: formikDataYes[j]?.io_emp_remarks, kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id };//kpindicatorDesc: "",
                                currentArray.push(rowObject);
                            }
                            else {
                                let rowObject = { rating: "", kpremarks: "", kpIndicatorFlag: "Y", kpIndicatorId: kpindicatordata[j]?.kp_indicator_id, kpId: kpindicatordata[j]?.kp_id };//kpindicatorDesc: "",
                                currentArray.push(rowObject);
                            }
                        }
                        let kpid = res.data.employee_kp_data[i]?.kp_id;
                        setIOInitialValues((prevState) => ({ ...prevState, ["kp" + kpid]: currentArray }))
                    }
                }
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
            }
        })
    }
    useEffect(() => {
        GetioData();
    }, []);
    return (<>
        <div class="card">
            <div class="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item">

                        <Link to={`/loginwisereport/${"IO"}`}>My Team Appraisal-IO</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                </ol>
            </div>
        </div>

        <div className="head-text mt-4"> <h1> Appraisal Form </h1></div>
        <div className="  mb-3" >
            <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <Card >
                        <Card.Body>
                            <Card.Title  > {showIOEmpData[0]?.emp_name}({showIOEmpData[0]?.emp_id})</Card.Title>
                            <Card.Text>
                                Name (ID)
                            </Card.Text>
                        </Card.Body>
                    </Card>

                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <Card  >
                        <Card.Body>
                            <Card.Title  >{showIOEmpData[0]?.doj?.split("-")?.reverse()?.join("/")}</Card.Title>
                            <Card.Text>
                                Date of Joining
                            </Card.Text>
                        </Card.Body>
                    </Card>


                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <Card  >
                        <Card.Body>
                            <Card.Title  >{showIOEmpData[0]?.zone_name}</Card.Title>
                            <Card.Text>
                                Wing
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className="px-3 pt-4" style={{ fontSize: "15px" }}>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <Card className="bg-success text-white">

                        <Card.Body>
                            <Card.Title>{showIOEmpData[0]?.io_emp_name}({showIOEmpData[0]?.io_emp_id})</Card.Title>
                            <Card.Text>
                                IO Name(ID)
                            </Card.Text>
                        </Card.Body>

                    </Card>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>

                    <Card className="bg-primary text-white">

                        <Card.Body>

                            <Card.Title  > {showIOEmpData[0]?.ro_emp_name}({showIOEmpData[0]?.ro_emp_id}) </Card.Title>
                            <Card.Text>
                                RO Name(ID)
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                    <Card className="bg-secondary text-white">
                        <Card.Body>
                            <Card.Title  > {showIOEmpData[0]?.designation_name} </Card.Title>
                            <Card.Text>
                                Designation
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </jnb.Col>

            </jnb.Row>
            <br />
        </div>


        <Tabs  style={{ fontSize: "12px" }}>
            <TabList   >
                {showIOEmpKpData !== undefined && showIOEmpKpData.map((iomapdata, i) => {
                    return (<React.Fragment key={i}>
                        <Tab onClick={(e) => {
                        }} key={i} disabled={iomapdata.io_data_flag_status === true ? false : true} >{iomapdata.kp_short_description} </Tab>
                    </React.Fragment>)
                })}
                <Tab
                    disabled={showIOTabFilledCount === showIOEmpKpData.length ? false : true}
                >Preview</Tab>
            </TabList>
            {showIOEmpKpData !== undefined && showIOEmpKpData.map((iomapdata, i) => {
                return (<React.Fragment key={i}>
                    <TabPanel>
                        {iomapdata.kp_sub_indicator_flag === "Y" ? (
                            <React.Fragment>
                                <Formik initialValues={
                                    {
                                        empId: employeeId,
                                        userId: iologindata?.username,
                                        [`kp${iomapdata.kp_id}`]: showIOInitialValues[`kp${iomapdata.kp_id}`],
                                    }
                                }

                                    validationSchema={Yup.object().shape({
                                        [`kp${iomapdata.kp_id}`]: Yup.array().of(Yup.object().shape({
                                            kpremarks: Yup.string().required("Please Enter Remarks").trim().min(4, 'Minimum 4 Character required').max(500, 'Maximum 500 Characters allowed').nullable(),
                                           rating: Yup.string().when('psmappedFlag', {
                                               is: (psmappedFlag) => {
                                                   return psmappedFlag;
                                               },
                                               then: Yup.string()
                                                   .test('psmappedFlag', 'Please Select Rating, skill has already mapped as primary, it should not be empty and NA', (value) => {
                                                       return value !== 'NA' && value?.trim() !== '' && value !== undefined;//(value !== 'NA');//&& value.trim() !== ''
                                                   }),
                                               otherwise: Yup.string().required("Please Select Rating").nullable(),})
                                        })
                                        ),
                                    })}

                                    onSubmit={(values) => {
                                        IOsubmitAlert(values);
                                    }
                                    }>
                                    {props => (
                                        <Form onSubmit={props.handleSubmit}>
                                            <br />
                                            <h4 style={{ fontSize: "16px" }}>{iomapdata.kp_description}</h4>
                                            <br></br>
                                            <div className="table-responsive">
                                                <table className="table table-condensed table-bordered table-striped table-responsive"
                                                    style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Key Performance Areas Description</th>
                                                            <th>Self Rating</th>
                                                            <th> Self Remarks</th>

                                                            <th width="50">Rating<font style={{ color: "red" }}>*</font></th>
                                                            <th width="150">Remarks<font style={{ color: "red" }}>*</font></th>

                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ textAlign: "left" }}>
                                                        {
                                                            showIOEmpKpStatusData !== undefined && showIOEmpKpStatusData.filter((kpi) => kpi.kp_id === iomapdata.kp_id).map((kpidata, k) => {
                                                                return (
                                                                    <React.Fragment key={k}>
                                                                        <tr>
                                                                            <td>{k + 1}
                                                                            </td>
                                                                            <td> {kpidata.kp_indicator_description}
                                                                              
                                                                                {showEmpKpIndicatorData.filter((kpindi) => kpindi.kp_indicator_id === kpidata.kp_indicator_id)[0].mandatory_kp_flag === true ? (
                                                                                <font style={{ color: "red" }}>*</font>) : null}
                                                                            </td>
                                                                            <td>{kpidata.emp_self_rating}</td>
                                                                            <td>{kpidata.emp_self_rating_remarks}</td>
                                                                            <td width="100" >
                                                                                <Field as="select" className='form-control' name={`kp${iomapdata.kp_id}[${k}].rating`}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="A">A</option>
                                                                                    <option value="B">B</option>
                                                                                    <option value="C">C</option>
                                                                                    <option value="D">D</option>
                                                                                    {iomapdata.kp_description === "Technical Skills" && (<option value="NA">NA</option>)}

                                                                                </Field>
                                                                                <ErrorMessage name={`kp${iomapdata.kp_id}[${k}].rating`} component="div" className="text-danger" ></ErrorMessage></td>
                                                                            <td >
                                                                                <Field as="textarea" className="form-control" name={`kp${iomapdata.kp_id}[${k}].kpremarks`} onKeyPress={(e) => allowAlphabetAndSpacesOnly(e)} maxLength="500"></Field>
                                                                                <ErrorMessage name={`kp${iomapdata.kp_id}[${k}].kpremarks`} component="div" className="text-danger" ></ErrorMessage></td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </tbody>

                                                </table>
                                                {showIOEmpData[0].io_final_status ? null : (
                                                    <button type="submit" className="btn rounded btn-success btn-sm" style={{ float: "right" }}>Save & continue</button>
                                                )}
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </React.Fragment>
                        ) : (<>
                        </>)}
                    </TabPanel>
                </React.Fragment>)
            })}
            <TabPanel>
                <GetPreview previewempid={employeeId} type={ROLE_IO} />
            </TabPanel>
        </Tabs>
    </>)
}
