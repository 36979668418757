import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { config } from '../../../../CommonUtils/CommonApis'
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios'
import allowNumbersOnly from '../../../../CommonUtils/CommonValidations'
import * as jnb from "react-bootstrap"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Yup from "yup"
import CommonPost from '../../../../CommonUtils/CommonPost'
import Sweetalert from '../../../../CommonUtils/SweetAlerts'
export default function PaternityOrMaternityAddLeaves() {
    const addleavehrlogindetails = useSelector((frsstate) => frsstate?.reducers?.loginreducer?.userLoginDetials);
    const [showEmpDetailsAtAddLeave, setEmpDetailsAtAddLeave] = useState([])
    const [showMsgforAddLeave, setMsgforAddLeave] = useState("")
    function GetEmployeeDataAtAddLeaves(empidAtaddleave) {
        if (!empidAtaddleave) { Swal.fire("Employee ID Required") }
        else {
            let Url = (config.url.COMMON_URL + "empdetailsFrs/" + empidAtaddleave);
            CommonAxiosGet(Url).then((restoaddleave) => {
                if (restoaddleave?.data?.status === true) {
                    const empdetailsAtaddLeave = Array.isArray(restoaddleave?.data?.empdetailsFrs) ? restoaddleave?.data?.empdetailsFrs[0] : {};
                    setEmpDetailsAtAddLeave(empdetailsAtaddLeave)
                    setMsgforAddLeave(restoaddleave?.data?.message);
                    const addleaveOption = restoaddleave?.data?.empdetailsFrs[0]?.gender === "F" ? "Maternity" : "Paternity"
                    const noofdaysValue = addleaveOption === "Maternity" ? 180 : 5
                    addLeaveFormik?.setFieldValue("add_leave", addleaveOption)
                    addLeaveFormik?.setFieldValue("no_of_days", noofdaysValue)
                    addLeaveFormik?.setFieldValue("msg", restoaddleave?.data?.message)
                }
                else { setEmpDetailsAtAddLeave([]); setMsgforAddLeave("") }
            })
        }
    }
    const addLeaveFormik = useFormik({
        initialValues: {
            msg: showMsgforAddLeave,
            emp_id: "",
            add_leave: "",
            from_date: "",
            to_date: "",
            no_of_days: "",
            entered_by: addleavehrlogindetails?.username
        },
        validationSchema: Yup.object().shape({
            from_date: Yup.string().when('msg', {
                is: (dropout) => dropout === "Data Found",
                then: Yup.string().required("Required").nullable(),
                otherwise: Yup.string().notRequired().nullable(),
            }),
            to_date: Yup.string().when('msg', {
                is: (dropout) => dropout === "Data Found",
                then: Yup.string().required("Required").nullable(),
                otherwise: Yup.string().notRequired().nullable(),
            }),
        }),
        onSubmit: (values) => {
            console.log(values)
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.AddPaternityOrMaternityLeavesSave(values).then((resSave) => {
                        if (resSave?.data?.SCODE === "01") {
                            Swal.fire({
                                text: resSave?.data?.SDESC,
                                icon: "success",
                                backdrop: false,
                            }).then((refresh) => {
                                if (refresh.isConfirmed) {
                                    setEmpDetailsAtAddLeave([]); setMsgforAddLeave("");
                                    addLeaveFormik.setFieldValue("emp_id", "");
                                    addLeaveFormik.setFieldValue("add_leave", "");
                                    addLeaveFormik.setFieldValue("from_date", "");
                                    addLeaveFormik.setFieldValue("to_date", "");
                                    addLeaveFormik.setFieldValue("no_of_days", "");
                                }
                            })
                        }
                        else if (resSave?.data?.SCODE === "02") {
                            Sweetalert(resSave?.data?.SDESC, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    }).catch(() => { console.log("Exception Occured 71 "); });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        }
    })

    function GetToDate(dateAtleavereg) {
        const fromDateAtleavereg = new Date(dateAtleavereg);
        const nextDayAtleavereg = new Date(fromDateAtleavereg);
        nextDayAtleavereg.setDate(fromDateAtleavereg.getDate() + 1);
        const todateAtleavereg = nextDayAtleavereg.toISOString().split("T")[0];

        const todateInputAtleavereg = document.getElementsByName("to_date")[0];
        if (todateInputAtleavereg) {
            todateInputAtleavereg.min = todateAtleavereg;
        }
    }
    return (
        <div>
            <jnb.Row className="m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"> <Link to="/homepage">Home</Link> </li>
                        <li className="breadcrumb-item active" aria-current="page">Add Paternity Or Maternity Leave</li> </ol>
                </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                <FormikProvider value={addLeaveFormik}>
                    <Form onSubmit={addLeaveFormik.handleSubmit} onChange={addLeaveFormik.handleChange} autoComplete="off"  >
                        <div className="border p-3 pb-3 mb-4">
                            <jnb.Row className="px-3">
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-1">
                                        <label className="w-100 mb-2"><b>Employee ID</b></label>
                                        <Field type="text" name="emp_id" className="form-control" onKeyPress={(e) => { allowNumbersOnly(e); }} maxLength="10"
                                            onChange={() => {
                                                setEmpDetailsAtAddLeave([]); setMsgforAddLeave("");
                                                addLeaveFormik.setFieldValue("add_leave", "");
                                                addLeaveFormik.setFieldValue("from_date", "");
                                                addLeaveFormik.setFieldValue("to_date", "");
                                                addLeaveFormik.setFieldValue("no_of_days", "");
                                            }} />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                    <button type="button" className='btn btn-success mt-4' onClick={() => {
                                        GetEmployeeDataAtAddLeaves(addLeaveFormik?.values?.emp_id)
                                    }}>Get Details</button>
                                </jnb.Col>
                            </jnb.Row>
                            {showMsgforAddLeave === "Data Found" && <>
                                <jnb.Row className="px-2 mb-2 pt-2 mb-2">
                                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <div class="card">
                                            <div class="card-body RowColorForLeave">
                                                <jnb.Row>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                        <label className="w-100"> <b>ID-Employee Name:</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.cfms_id}-{showEmpDetailsAtAddLeave?.emp_name}</i></label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <label className="w-100"> <b>Email:</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.email}</i></label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                                        <label className="w-100"><b>Mobile:</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.mobileno}</i> </label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                                        <label className="w-100"> <b>Gender:</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.gender === "F" ? "FEMALE" : "MALE"}</i></label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                                                        <label className="w-100"> <b>RO Name(ID):</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.reporting_manager_name}({showEmpDetailsAtAddLeave?.ro_id})</i></label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                                        <label className="w-100"><b>Designation:</b>&nbsp;<i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.new_designation}</i></label>
                                                    </jnb.Col>
                                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                                                        <label className="w-100"> <b>DOJ:</b>&nbsp; <i style={{ textAlign: "justify" }}>{showEmpDetailsAtAddLeave?.doj}</i> </label>
                                                    </jnb.Col>
                                                </jnb.Row>
                                            </div>
                                        </div>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
                                </jnb.Row>
                                <jnb.Row className="px-3 pt-2">
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>Add Leave</b></label>
                                            <Field as="select" name="add_leave" className="form-control" disabled={true}>
                                                <option value="Paternity">Paternity</option>
                                                <option value="Maternity">Maternity</option>
                                            </Field>
                                        </jnb.InputGroup>
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>From Date </b></label>
                                            <Field type="date" name="from_date"
                                                className="form-control" max={new Date().toISOString().split("T")[0]}
                                                onChange={(e) => {
                                                    GetToDate(e.target.value);
                                                    addLeaveFormik.setFieldValue("to_date", "");
                                                }} />
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="from_date" /> </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        <jnb.InputGroup className="mb-1">
                                            <label className="w-100 mb-2"><b>To Date </b></label>
                                            <Field type="date" name="to_date" className="form-control" />
                                        </jnb.InputGroup>
                                        <ErrorMessage component="div" className="text-danger" name="to_date" />
                                    </jnb.Col>
                                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                                        {addLeaveFormik?.values?.from_date !== "" && addLeaveFormik?.values?.to_date !== "" ?
                                            <b> Note: No.of <b style={{ color: "red" }}>{addLeaveFormik?.values?.add_leave}</b>&nbsp; leaves
                                                can avail <b style={{ color: "red" }}>{addLeaveFormik?.values?.no_of_days}</b>&nbsp;days  </b> : null}
                                        <div className='d-flex flex-row justify-content-end pe-0 mt-1'>
                                            <button type="submit" className="btn btn-success btn-sm float-end ms-2 mb-4">Confirm</button>
                                        </div>
                                    </jnb.Col>
                                </jnb.Row>
                            </>}
                        </div>
                    </Form>
                </FormikProvider>
            </jnb.Row>
        </div>
    )
}
