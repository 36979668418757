import { FormikProvider, useFormik, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { config } from "../../../../CommonUtils/CommonApis";
import ContractPayRollEntryForm from "./ContractPayRollEntryForm";
import RegularEmpPayRollEntryForm from "./RegularEmpPayRollEntryForm";
import InternPayRollEntryForm from "./InternPayRollEntryForm";
import PayRollEntryFormGovt from "./PayRollEntryFormGovt";
export default function PayDetailsForm() {
    const [searchAtPayDetailsForm, setSearchAtPayDetailsForm] = useState("");
    const [GetPayFormDetails, setPayFormDetails] = useState([])
    const category_type_payForm = localStorage?.getItem("category");
    const category_id_payForm = localStorage?.getItem("category_id");
    const type_payForm = localStorage?.getItem("type")
    const PayDetailsFormformIk = useFormik({
        enableReinitialize: true,
        initialValues: {search:""},
        onSubmit: (values) => {console.log(null) },
    });
    const navigateAtPayForm = useNavigate()
    const [regularPopupFlag, setregularPopupFlag] = useState(false);
    const [categoryAtPayform, setcategoryAtPayform] = useState();
    const [regularviewdataAtPayrollForm, setregularviewdataAtPayrollForm] = useState([])
    const [contractPopupFlagAtPayrollForm, setcontractPopupFlagAtPayrollForm] = useState(false);
    const [contractviewdataPayrollform, setcontractviewdataPayrollform] = useState([]);
    const [InternPopupFlagPayrollform, setInternPopupFlagPayrollform] = useState(false);
    const [InternviewdataPayrollform, setInternviewdataPayrollform] = useState([]);
    const [govtPopupFlagPayrollform, setGovtPopupFlagPayrollform] = useState(false);
    const [govtviewdataPayrollform, setgovtviewdataPayrollform] = useState([]);
    function regularPopStatus() {setregularPopupFlag(false);}
    function InternPopStatus() {setInternPopupFlagPayrollform(false);}
    function ContractPopStatus() {setcontractPopupFlagAtPayrollForm(false);}
    function GovtPopStatus() {setGovtPopupFlagPayrollform(false);}
    function navigateToActiveemployees() {navigateAtPayForm("/dashboradofpaydetils")}
    function PayrollformGetData() {
        let Url = (config.url.COMMON_URL + "employeepaydetailsreportregular/" + category_id_payForm + "/" + type_payForm);
        CommonAxiosGet(Url).then((resAtpayrll) => {
            if (resAtpayrll?.data?.ResponseCode === "01") {
                setPayFormDetails(resAtpayrll?.data?.employeepaydata)
            }
            else {
                setPayFormDetails([])
            }
        })
    }
    const [GovtEmpPayFormData,setGovtEmpPayFormData]=useState([])
    function GetemployeesdataPayForm() {
        CommonAxiosGet(config.url.COMMON_URL + "employeepaydetailsreportgovt").then((responseAtpayRoll) => {
            if (responseAtpayRoll?.data?.status === true) {
                setGovtEmpPayFormData(responseAtpayRoll?.data?.employeepaydetailsreportgovernment);
            }else{
                setGovtEmpPayFormData([])
            }
          
        });
    }
    const govtGetDataAtPayRollform=GovtEmpPayFormData;
    function showpopupAtPayrllform(viewdataPayrll, categoryPayrll) {
        if (categoryPayrll === 1) {
            localStorage.setItem("data", JSON.stringify(viewdataPayrll))
            setregularPopupFlag(true);
            setcategoryAtPayform(categoryPayrll)
            setregularviewdataAtPayrollForm(viewdataPayrll);
        }
        else if (categoryPayrll === 2) {
            setcontractPopupFlagAtPayrollForm(true);
            setcategoryAtPayform(categoryPayrll)
            setcontractviewdataPayrollform(viewdataPayrll);
            localStorage.setItem("data", JSON.stringify(viewdataPayrll))
        }
        else if (categoryPayrll === 4) {
            setInternPopupFlagPayrollform(true);
            setcategoryAtPayform(categoryPayrll)
            setInternviewdataPayrollform(viewdataPayrll);
            localStorage.setItem("data", JSON.stringify(viewdataPayrll))
        }
        else if (categoryPayrll === "3") {
            console.log("category",categoryPayrll)
            setGovtPopupFlagPayrollform(true);
            setcategoryAtPayform("3")
            setgovtviewdataPayrollform(govtGetDataAtPayRollform);
            localStorage.setItem("data", JSON.stringify(viewdataPayrll))
        }

    }

    useEffect(() => {
        GetemployeesdataPayForm();
        PayrollformGetData();
    }, [])

    return (<>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Payroll Details</li>
                </ol>
            </jnb.Col>
        </jnb.Row>
        <jnb.Row className=" pt-2">
            {category_type_payForm !== "" ? (<>
                    <jnb.Row className="m-0 pt-2 pb-2">
                        <jnb.Col xs={12} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            <jnb.InputGroup>
                                <input className="form-control" id="search" name={`search_${Date.now()}`} type="text" placeholder="search" autoComplete="off"
                                    onChange={event => setSearchAtPayDetailsForm(event.target.value)} />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                            <button type="button" className="bg-success p-2 text-white  btn-sm  rounded" style={{ float: "right" }}
                                onClick={() => { navigateToActiveemployees() }}>Back</button>
                        </jnb.Col>
                    </jnb.Row>
            </>) : (<></>)}

            <FormikProvider value={PayDetailsFormformIk}>
                <Form onSubmit={PayDetailsFormformIk.handleSubmit} onChange={PayDetailsFormformIk.handleChange} autoComplete="off"  >
                    <jnb.Row className="px-3">
                        {category_type_payForm !== "" ? (<>
                            {category_type_payForm === "reguler" && (<>
                                <table className="table  table-bordered  table-responsive">
                                    <thead>
                                        <tr>
                                            <th colSpan={18}>Regular</th>
                                        </tr>
                                        <tr>
                                            <th width="15px" rowSpan={2}>S.No</th>
                                            <th width="200px" rowSpan={2}>ID (Name)</th>
                                            <th width="125px" rowSpan={2}>DOJ</th>
                                            <th width="200px"rowSpan={2}>Email</th>
                                            <th width="200px"rowSpan={2}>Designation</th>
                                            <th width="60px"rowSpan={2}>Band</th>
                                            <th width="70px"rowSpan={2}>Monthly Gross</th>
                                            <th width="70px"rowSpan={2}>Basic Pay</th>
                                            <th width="70px"rowSpan={2}>HRA</th>
                                            <th width="70px"rowSpan={2}>FA</th>
                                            <th width="70px"rowSpan={2}>Incentive / Bonus</th>
                                            <th width="70px"rowSpan={2}>Salary Revision</th>
                                            <th width="70px"colSpan={2}>Employee </th>
                                            <th width="70px" colSpan={2}>Employer</th>
                                            <th width="70px"rowSpan={2}>Professional Tax</th>
                                            <th width="88px"rowSpan={2}>Edit</th>
                                        </tr>
                                        <tr>
                                        <th width="70px"> ESIC Contribution<small>(13)</small></th>
                                            <th width="70px" > EPF Contribution<small>(14)</small></th>
                                            <th width="70px" > ESIC Contribution<small>(15)</small></th>
                                            <th width="70px" > EPF Contribution<small>(16)</small></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: "left" }}>
                                        {GetPayFormDetails && GetPayFormDetails?.filter((elementReg) =>
                                            elementReg?.emp_name?.toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase()) ||
                                            elementReg?.emp_id?.toString().toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase())
                                        )?.map((dataReg, Regindex) => (
                                            <tr key={Regindex}>
                                                <td>{Regindex + 1}</td>
                                                <td>{dataReg?.emp_id} ({dataReg?.emp_name})</td>
                                                <td>{dataReg?.doj}</td>
                                                <td>{dataReg?.email}</td>
                                                <td>{dataReg?.designation_name}</td>
                                                <td>{dataReg?.band}</td>
                                                <td>{dataReg?.monthly_gross}</td>
                                                <td>{dataReg?.basic_pay}</td>
                                                <td>{dataReg?.hra}</td>
                                                <td>{dataReg?.flexible_allowance}</td>
                                                <td>{dataReg?.incentive_bonus}</td>
                                                <td>{dataReg?.salary_revision_date}</td>
                                                <td>{dataReg?.employee_esic_contribution}</td>
                                                <td>{dataReg?.employee_epf_contribution}</td>
                                                <td>{dataReg?.employer_contribution_esic}</td>
                                                <td>{dataReg?.employer_contribution_epf}</td>
                                                <td>{dataReg?.professional_tax}</td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-primary btn-sm rounded"
                                                        onClick={() => {showpopupAtPayrllform(dataReg, dataReg?.category);}}>Edit</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>)}
                            {category_type_payForm === "contract" && (<>
                                <jnb.Row className="px-5">
                                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <table className="table  table-bordered  table-responsive">
                                            <thead>
                                                <tr><th colSpan={14}>Contract</th></tr>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th width="200px" >ID (Name)</th>
                                                    <th width="89px">DOJ</th>
                                                    <th>Email</th>
                                                    <th>Designation</th>
                                                    <th width="60px">Band</th>
                                                    <th width="70px">Consolidated Pay Earned</th>
                                                    <th width="70px">TDS</th>
                                                    <th width="88px">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {GetPayFormDetails && GetPayFormDetails?.filter((elementCntrct) =>
                                                    elementCntrct?.emp_name?.toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase()) ||
                                                    elementCntrct?.emp_id?.toString().toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase())
                                                ).map((Cntrctdata, Cntrctindex) => (
                                                    <tr key={Cntrctindex}>
                                                        <td>{Cntrctindex + 1}</td>
                                                        <td>{Cntrctdata?.emp_id} ({Cntrctdata?.emp_name})</td>
                                                        <td>{Cntrctdata?.doj}</td>
                                                        <td>{Cntrctdata?.email}</td>
                                                        <td>{Cntrctdata?.designation_name}</td>
                                                        <td>{Cntrctdata?.band}</td>
                                                        <td>{Cntrctdata?.consilated_total}</td>
                                                        <td>{Cntrctdata?.tds}</td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn btn-primary btn-sm rounded"
                                                                onClick={() => {
                                                                    showpopupAtPayrllform(Cntrctdata, Cntrctdata?.category);}}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </jnb.Col>
                                </jnb.Row>
                            </>)}
                            {category_type_payForm === "intern" && (<>
                                <jnb.Row className="px-5">
                                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <table className="table  table-bordered  table-responsive" style={{ width: "80%" }}>
                                            <thead>
                                                <tr><th colSpan={14}>Intern</th></tr>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th width="200px" >ID (Name)</th>
                                                    <th width="89px">DOJ</th>
                                                    <th>Email</th>
                                                    <th>Designation</th>
                                                    <th width="60px">Band</th>
                                                    <th width="70px">Consolidated Pay Earned</th>
                                                    <th width="88px">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                                {GetPayFormDetails && GetPayFormDetails?.filter((elementIntrn) =>
                                                    elementIntrn?.emp_name?.toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase()) ||
                                                    elementIntrn?.emp_id?.toString().toLowerCase().includes(searchAtPayDetailsForm?.toLowerCase())
                                                ).map((Intrndata, IntrnIndex) => (
                                                    <tr key={IntrnIndex}>
                                                        <td>{IntrnIndex+ 1}</td>
                                                        <td>{Intrndata?.emp_id} ({Intrndata?.emp_name})</td>
                                                        <td>{Intrndata?.doj}</td>
                                                        <td>{Intrndata?.email}</td>
                                                        <td>{Intrndata?.designation_name}</td>
                                                        <td>{Intrndata?.band}</td>
                                                        <td>{Intrndata?.consilated_total}</td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn btn-primary btn-sm rounded"
                                                                onClick={() => {
                                                                    showpopupAtPayrllform(Intrndata, Intrndata?.category);
                                                                }}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </jnb.Col>
                                </jnb.Row></>)}
                     
                        {/* {category_type_payForm === "govt" && (<>
                                <jnb.Row className="px-5">
                                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <table className="table  table-bordered  table-responsive" style={{ width: "80%" }}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={14}>Government</th>
                                                </tr>
                                                <tr>
                                                    <th>S.No</th>
                                                    <th width="200px" >ID (Name)</th>
                                                    <th width="89px">DOJ</th>
                                                    <th>Email</th>
                                                    <th>Designation</th>
                                                    <th width="60px">Band</th>
                                                    <th width="88px">Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ textAlign: "left" }}>
                                            <tr>
                                                    <td>1</td>
                                                    <td width="200px">1421-Lakshmi</td>
                                                    <td width="89px">12-12-2023</td>
                                                    <td>Lucky@gmail.com</td>
                                                    <td>TSD</td>
                                                    <td width="60px">5</td>
                                                    <td className="text-center">
                                                            <button type="button" className="btn btn-primary btn-sm rounded"
                                                                onClick={() => {showpopupAtPayrllform("viewdata","3");}}>Edit</button>
                                                        </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </jnb.Col>
                                </jnb.Row></>)} */}
                        </>) : (<></>)}

                    </jnb.Row>
                </Form>
            </FormikProvider>

           <RegularEmpPayRollEntryForm popupflagvalue={regularPopupFlag} regularpopup={regularPopStatus}
                category={categoryAtPayform} viewdata={regularviewdataAtPayrollForm} />

             <ContractPayRollEntryForm contractpopupflagvalue={contractPopupFlagAtPayrollForm} contractpopup={ContractPopStatus}
                contractcategory={categoryAtPayform} contractviewdata={contractviewdataPayrollform} />

            <InternPayRollEntryForm Internpopupflagvalue={InternPopupFlagPayrollform} Internpopup={InternPopStatus}
                Interncategory={categoryAtPayform} Internviewdata={InternviewdataPayrollform} />

            <PayRollEntryFormGovt Govtpopupflagvalue={govtPopupFlagPayrollform} Govtnpopup={GovtPopStatus}
            govtcategory={categoryAtPayform} govtviewdata={govtviewdataPayrollform}/>
           
        </jnb.Row>
    </>)
}

