import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import CommonReactTable from '../../../../CommonUtils/CommonReactTable';
export default function TotalEmployeeAssetsDetails() {
    const [showTotalDuesemployeedetails, setTotalDuesEmployeeDetails] = useState([]);
    const [showErrMsgAtTotalDues,setErrMsgAtTotalDues]=useState(false)
    const navigateAtTotalDues = useNavigate();
    function navigateToEmployeeDetails(commonempid, commonempname, commonesignation_name, commondesignation_id, count, commonasset_id, backbuttonValue) {
        localStorage.setItem("employee_id", commonempid);
        localStorage.setItem("employee_name", commonempname);
        localStorage.setItem("designation_name", commonesignation_name);
        localStorage.setItem("designation_id", commondesignation_id);
        localStorage.setItem("asset_id", commonasset_id);
        localStorage.setItem("backbutton", backbuttonValue);
            navigateAtTotalDues("/withduesorwithoutduesdetails", { state: { count: count } });
    }    
    function navigatetoEmpWithOrWithoutDuesAtTotalDues(empidOfWithoutDues, empnameOfWithoutDues, designation_nameOfWithoutDues, designation_idOfWithoutDues, count, asset_idOfWithoutDues) {
        navigateToEmployeeDetails(empidOfWithoutDues, empnameOfWithoutDues, designation_nameOfWithoutDues, designation_idOfWithoutDues, count, asset_idOfWithoutDues, "total_withoutdues");}

    function navigatetoEmpWithOrWithDuesAtTotalDues(empidOfWithDues, empnameOfWithDues, designation_nameOfWithDues, designation_idOfWithDues, count, asset_idOfWithDues) {
        navigateToEmployeeDetails(empidOfWithDues, empnameOfWithDues, designation_nameOfWithDues, designation_idOfWithDues, count, asset_idOfWithDues, "total_withdues");}

    function GetDataAtTotalDues() {
        let Url = (config?.url?.COMMON_URL + "assetsdeatilsbyemptotal");
        CommonAxiosGet(Url).then((resTotal) => {
            if (resTotal?.data?.status === true) {
                setTotalDuesEmployeeDetails(resTotal?.data?.assetsdeatilsbyemptotal);
                setErrMsgAtTotalDues(false)}
            else {setTotalDuesEmployeeDetails([]);setErrMsgAtTotalDues(true)}
        })
    }

    const fetchDataTotalDues = useRef(false)
    useEffect(() => {
        if(!fetchDataTotalDues.current){
            GetDataAtTotalDues()
            fetchDataTotalDues.current = true    
        }
    }, []);
    function navigateToActiveemployeesAtAssetes() {navigateAtTotalDues("/employeesinfo")}

    const TotalDuescolumns = [
        {Header: "S.No",accessor: (row,TotalIndex) => (<>{TotalIndex+ 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,},
        {Header: 'ID',accessor: "cfms_id",},
        {Header: 'Employee Name',accessor: "emp_name",},
        {Header: 'Designation',accessor: "designation_name",},
        {Header: 'No.Of Dues',accessor: (Totalrow,TotalIndex) => (
                <div style={{ fontSize: "15px" }} className=" p-1">
                    {Totalrow?.withduescount === null ? (
                    <button type="button" class="btn btn-link btn-sm" 
                    onClick={(e) => {navigatetoEmpWithOrWithoutDuesAtTotalDues(Totalrow?.cfms_id, Totalrow?.emp_name, Totalrow?.designation_name, Totalrow?.desg_id, Totalrow?.withduescount, Totalrow?.asset_id) }}>
                        0</button>) : (
                        <button type="button" class="btn btn-link btn-sm" 
                        onClick={(e) => { navigatetoEmpWithOrWithDuesAtTotalDues(Totalrow?.cfms_id, Totalrow?.emp_name, Totalrow?.designation_name, Totalrow?.desg_id, Totalrow?.withduescount, Totalrow?.asset_id) }}>
                            {Totalrow?.withduescount}
                        </button>
                    )}</div>),},];
    return (
        <div>
            <jnb.Row className="m-0">
                <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Employee Dues Report</li>
                    </ol>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                        <button type="button" className="bg-success p-2 text-white  btn-sm rounded " style={{ float: "right" }}
                            onClick={() => {navigateToActiveemployeesAtAssetes()}}>Back</button>
                    </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" pt-2">
                        <jnb.Row className="px-3">
                            {showTotalDuesemployeedetails?.length > 0 ? (
                                <CommonReactTable data={showTotalDuesemployeedetails} columns={TotalDuescolumns} showFooter="false"
                                filename="Employee Dues Report" headerName="Employee Dues Report"/>
                            ) : (showErrMsgAtTotalDues && (
                            <center><b style={{ color: "red" }}>*********No Data Found*********</b></center>))}
                        </jnb.Row>

            </jnb.Row>
        </div>
    ) 
}