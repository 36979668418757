
import React from 'react';
import { Row, Col } from 'react-bootstrap'; // Assuming you're using Bootstrap or jnb (adjust if needed)

const CommonEmployeeDetailsForPayroll = ({ employeeDetails }) => {
    const {
        emp_id,
        emp_name,
        ro_name,
        ro_emp_id,
        zone_name,
        designation_name,
        category_name,
        department_name,
        band_name
    } = employeeDetails;

    return (
        <div className="card">
            <div className="card-header" style={{ backgroundColor: '#2980B9', color: '#ffffff' }}>
                Employee Details:-
            </div>
            <div className="card-body RowColorForLeave">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <label className="w-100">
                            <b>ID-Employee Name:</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{emp_id}-{emp_name}</i>
                        </label>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <label className="w-100">
                            <b>RO Name(ID):</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{ro_name}-{ro_emp_id}</i>
                        </label>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <label className="w-100">
                            <b>Zone-Designation:</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{zone_name}-{designation_name}</i>
                        </label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <label className="w-100">
                            <b>Category:</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{category_name}</i>
                        </label>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <label className="w-100">
                            <b>Department:</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{department_name}</i>
                        </label>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                        <label className="w-100">
                            <b>Band:</b>&nbsp; 
                            <i style={{ textAlign: "justify" }}>{band_name}</i>
                        </label>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CommonEmployeeDetailsForPayroll;
