
import { FormikProvider, useFormik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { BsEye } from "react-icons/bs";
import { config } from "../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import CommonPost from "../../../CommonUtils/CommonPost";
import Sweetalert, { failureAlert, successAlert2 } from "../../../CommonUtils/SweetAlerts";
import moment from 'moment';
export default function LeaveRequestApproveOrRejectByIO() {
    const [GetLeaveReqDetails, setLeaveReqDetails] = useState([]);
    const [showReadOnlyLeaveReqData, setShowReadOnlyLeaveReqData] = useState([]);
    const [showLeaveReqErrMsg, setLeaveReqErrMsg] = useState(false);
    const [isCheckAll, setCheckAll] = useState(false);
    const logindetailsLeaveReq = useSelector((stateleavereq) => stateleavereq?.reducers?.loginreducer?.userLoginDetials);
    const LeaveApproveIOformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            typeofleave: "",
            leave_status_updated_by: "",
            leave_status: "",
            requestcomfirmation: []
        },
        onSubmit: (values) => {
            const isCheckedLeaveReq = values?.requestcomfirmation?.some(reqforsome => reqforsome?.confirmation);
            if (!isCheckedLeaveReq) {
                failureAlert("Please select at least one employee.");
                return;
            }
            let uncheckedItemsStatus = [];
            let uncheckedItemsRemarks = [];
            values?.requestcomfirmation?.forEach(reqforeach => {
                if (reqforeach?.confirmation) {
                    if (!reqforeach?.leave_status?.trim()) {
                        uncheckedItemsStatus?.push(reqforeach?.emp_id);
                    }
                    if (reqforeach?.leave_status === "false" && !reqforeach?.leave_status_remakrs?.trim()) {
                        uncheckedItemsRemarks.push(reqforeach?.emp_id);
                    }
                }
            });
            if (uncheckedItemsStatus?.length > 0) {
                const errorMessageforunchecked = `Please select status for all checked employees at emp_id(s): ${uncheckedItemsStatus?.join(', ')}`;
                failureAlert(errorMessageforunchecked);
                return;
            }
            if (uncheckedItemsRemarks?.length > 0) {
                const errorMessageforremarks = `Please provide remarks for all rejected employees at emp_id(s): ${uncheckedItemsRemarks?.join(', ')}`;
                failureAlert(errorMessageforremarks);
                return;
            }
            const filteredItemsAtleaveReq = values?.requestcomfirmation?.filter(reqfilter => reqfilter?.confirmation === true && reqfilter?.leave_status !== "");
            const formattedItemsAtleaveReq = {
                requestcomfirmation: filteredItemsAtleaveReq?.map(req => ({
                    leave_status: req?.leave_status,
                    leave_status_remakrs: req?.leave_status_remakrs,
                    emp_id: req?.emp_id,
                    leave_status_updated_by: req?.leave_status_updated_by,
                    leave_type_id: req?.leaves_type_id,
                    type_of_leave: req?.type_of_leave,
                    no_of_days: req?.total_no_of_days,
                    slno: req?.slno
                }))
            };
            RequestAprroveOrRejectedSave(formattedItemsAtleaveReq);
        },
    });


    function GetLeaveRequsetData() {
        let Url = (config.url.COMMON_URL + "leavesdataFrsRoscreen/" + logindetailsLeaveReq?.username);
        CommonAxiosGet(Url).then((resingetleaves) => {
            if (resingetleaves?.data?.leavesdataFrsReportingOfficerStatus === true) {
                const filterdata = resingetleaves?.data?.leavesdataFrsReportingOfficer?.filter((status) => status?.leave_final_status === "pending")
                if (filterdata?.length > 0) {
                    setLeaveReqDetails(filterdata)
                    setLeaveReqErrMsg(false)
                }
                else {
                    setLeaveReqDetails([])
                    setLeaveReqErrMsg(true)
                }
            }
            else {
                setLeaveReqErrMsg(true)
                setLeaveReqDetails([])
            }
        })
    }

    const handleCheckboxChangeAtleaverq = (event, i) => {
        const updatedShowReadOnlyData = [...showReadOnlyLeaveReqData];
        updatedShowReadOnlyData[i] = !event?.currentTarget?.checked;
        setShowReadOnlyLeaveReqData(updatedShowReadOnlyData);

        if (event?.currentTarget?.checked === true) {
            const updatedAttendance = LeaveApproveIOformIk?.values?.requestcomfirmation?.map((item, index) => (
                index === i ? {
                    ...item,
                    confirmation: true,
                    leave_status: item?.leave_status !== undefined ? item?.leave_status : null,
                } : item
            ));

            LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
            const allCheckedAleavereq = updatedAttendance.every(item => item.confirmation === true);
            setCheckAll(allCheckedAleavereq);
        } else {
            const updatedAttendance = LeaveApproveIOformIk?.values?.requestcomfirmation?.map((item, index) => (
                index === i ? { ...item, leave_status: null } : item
            ));

            LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
            setCheckAll(false);
        }
    };

    const handleRadioChangeAtLeaveReq = (i, slno, empid, value) => {
        const currentselectedradio = LeaveApproveIOformIk?.values?.requestcomfirmation;
        const updatedradiovalue = Array.isArray(currentselectedradio) ? [...currentselectedradio] : [];
        updatedradiovalue[i] = {
            ...updatedradiovalue[i],
            leave_status: value,
            leave_status_remakrs: LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.leave_status_remakrs,
            slno: slno,
            emp_id: empid,
            leave_status_updated_by: logindetailsLeaveReq?.username
        };
        LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedradiovalue);
    };

    const RequestAprroveOrRejectedSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.RequestAprroveOrRejectedDetailsSave(values).then((res) => {
                    if (res?.data?.ResponseCode === "01") {
                        successAlert2(res.data.ResponseDesc)
                        LeaveApproveIOformIk.resetForm();
                        GetLeaveRequsetData();
                    }
                    else if (res?.data?.ResponseCode === "02") {
                        Sweetalert(res.data.ResponseDesc, 'warning');
                    }
                    else {
                        Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                    }
                })
                    .catch(() => { console.log("Exception Occured 71 "); });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })

    };

    const fetchDataAtleaveReq = useRef(false);
    useEffect(() => {
        if (!fetchDataAtleaveReq.current) {
            LeaveApproveIOformIk.resetForm();
            GetLeaveRequsetData()
            fetchDataAtleaveReq.current = true
        }

    }, []);

    useEffect(() => {
        if (GetLeaveReqDetails) {
            const reqValues = GetLeaveReqDetails?.map(data => ({
                emp_id: data?.emp_id,
                leave_status_updated_by: data?.leave_status_updated_by,
                leaves_type_id: data?.leaves_type_id,
                type_of_leave: data?.type_of_leave,
                total_no_of_days: data?.total_no_of_days,
                leave_status: ""
            }));
            LeaveApproveIOformIk.setValues({ ...LeaveApproveIOformIk?.values, requestcomfirmation: reqValues });
        }
    }, [GetLeaveReqDetails]);
    const navigateAtleaveREq = useNavigate()
    function navigateToHistoryAtleaveREq(empid, empname) {
        navigateAtleaveREq("/EmpPreviousHystoryDetails")
        localStorage.setItem("employee_id", empid);
        localStorage.setItem("employee_name", empname);
        localStorage.setItem("backbutton", "RequestApproveOrRejectApplication");
    }
    function navigateToPReviousHistory() { navigateAtleaveREq("/AprroveOrRejectDataHistory"); }
    const historycolumnlength = GetLeaveReqDetails?.length > 0 ? 2 : 6
    const [filteredLeaveReqDetails, setFilteredLeaveReqDetails] = useState(GetLeaveReqDetails);

    const handleCheckAllAtleaveReq = (e) => {
        const isChecked = e.target.checked;
        setCheckAll(isChecked);
        const updatedAttendance = filteredLeaveReqDetails.map(item => ({
            ...item,
            confirmation: isChecked,
            leave_status: isChecked ? (item?.leave_status || null) : null,
        }));

        LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
    };
    const filterLeaveReqDetails = (searchValue) => {

        return filteredLeaveReqDetails?.filter(item => {
            const empIdMatches = typeof item?.emp_id === 'string' && item.emp_id.includes(searchValue);
            const empNameMatches = item?.emp_name?.toLowerCase().includes(searchValue?.toLowerCase());
            return empIdMatches || empNameMatches;
        });
    };
    const resetSelections = () => {
        const updatedAttendance = LeaveApproveIOformIk.values.requestcomfirmation.map(item => ({
            ...item,
            confirmation: false,
            leave_status: "",
            leave_status_remakrs: "",
        }));
        LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
        LeaveApproveIOformIk.setFieldValue('leave_status', "");
        setCheckAll(false);
    };

    const handleSearchInputChange = (searchValue) => {
        resetSelections();

        const filteredData = filterLeaveReqDetails(searchValue);
        if (searchValue) {
            const updatedAttendance = filteredData.map(item => ({
                ...item,
                confirmation: true,
                leave_status: item?.leave_status || "",
            }));

            LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
        } else {
            LeaveApproveIOformIk.setFieldValue('requestcomfirmation', []);
        }
    };
    const handleRadioChangeForAll = (value) => {
        const updatedAttendance = LeaveApproveIOformIk.values.requestcomfirmation.map(item => ({
            ...item,
            leave_status: item.confirmation ? value : item.leave_status,
            leave_status_remakrs: item.confirmation && value === "false" ? "" : item.leave_status_remakrs,
        }));
        LeaveApproveIOformIk.setFieldValue('requestcomfirmation', updatedAttendance);
    };

    useEffect(() => {
        const { typeofleave, searchbyid } = LeaveApproveIOformIk.values;

        const newFilteredData = GetLeaveReqDetails?.filter((element) => {
            const typeOfLeave = element?.type_of_leave?.toLowerCase() === "Movement" ? "regularization" : element?.type_of_leave?.toLowerCase();
            const selectedLeaveType = typeofleave?.toLowerCase();
            const empIdSearch = searchbyid?.toLowerCase();

            const matchesType = selectedLeaveType ? typeOfLeave?.includes(selectedLeaveType) : true;
            const matchesEmpId = empIdSearch ? element?.emp_id?.toString()?.toLowerCase().includes(empIdSearch) : true;
            const matchesDateOrTypeName =
                element?.emp_name?.toString()?.toLowerCase().includes(empIdSearch) ||
                element?.from_date?.toString()?.toLowerCase().includes(empIdSearch) ||
                element?.to_date?.toString()?.toLowerCase().includes(empIdSearch) ||
                element?.type_name?.toLowerCase().includes(empIdSearch) ||
                element?.halfday_from_time_to_time?.toLowerCase().includes(empIdSearch);

            return matchesType && (matchesEmpId || matchesDateOrTypeName);
        }) || [];

        setFilteredLeaveReqDetails(newFilteredData);
    }, [GetLeaveReqDetails, LeaveApproveIOformIk.values]);
    return (<>
        <jnb.Row className="m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leave Approval</li>
                </ol>
            </jnb.Col>
        </jnb.Row>

        <jnb.Row className=" pt-2">
            <FormikProvider value={LeaveApproveIOformIk}>
                <Form onSubmit={LeaveApproveIOformIk.handleSubmit} onChange={LeaveApproveIOformIk.handleChange}>
                    <jnb.Row className="px-3 pt-1">

                        <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                            {(GetLeaveReqDetails !== undefined && GetLeaveReqDetails?.length > 0 && GetLeaveReqDetails != "No data found") ? (<>
                                <jnb.InputGroup>
                                    <Field as="select" className="form-control mb-2" name="typeofleave" onChange={event => {
                                        handleSearchInputChange(event.target.value); LeaveApproveIOformIk?.setFieldValue("searchbyid", "");
                                    }}>
                                        <option value="">--Search here--</option>
                                        <option value="Leave">Leave</option>
                                        <option value="Movement">Regularization</option>
                                        <option value="WFH">Work From Home</option>
                                    </Field>
                                </jnb.InputGroup>
                            </>) : (<></>)}
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                            {(GetLeaveReqDetails !== undefined && GetLeaveReqDetails?.length > 0 && GetLeaveReqDetails != "No data found") ? (<>
                                <jnb.InputGroup>
                                    <Field name="searchbyid"
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="Search by Employee ID"
                                    />
                                </jnb.InputGroup>
                            </>) : (<></>)}
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={historycolumnlength} xl={historycolumnlength} xxl={historycolumnlength}>
                            <button type="button" class="btn btn-secondary  bg-opacity-50 btn-sm float-end" onClick={() => { navigateToPReviousHistory() }}>History</button>
                        </jnb.Col>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-2">
                        {(GetLeaveReqDetails !== undefined && GetLeaveReqDetails?.length > 0 && GetLeaveReqDetails != "No data found") ? (<>
                            <table className="table  table-bordered  table-responsive  leaveapproval-table">
                                <thead>
                                    <tr>
                                        <th align="center" >
                                            <input id="checkall" type="checkbox" onChange={handleCheckAllAtleaveReq} checked={isCheckAll} />&nbsp;S.No</th>
                                        <th >ID</th>
                                        <th >Name</th>
                                        <th >Leave Request</th>
                                        <th >Details of <br></br>Leaves for Apply</th>
                                        <th >Type of Leave</th>
                                        <th >Visiting Office Name - <br />Remarks</th>
                                        <th>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    name="leave_status"
                                                    value="true"
                                                    onClick={() => handleRadioChangeForAll("true")}
                                                />&nbsp;Approve&emsp;
                                            </label>
                                            <label>
                                                <Field
                                                    type="radio"
                                                    name="leave_status"
                                                    value="false"
                                                    onClick={() => handleRadioChangeForAll("false")}
                                                />&nbsp;Reject
                                            </label>
                                        </th>
                                        <th >Previous <br />Data History</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredLeaveReqDetails.length > 0 ? (
                                        filteredLeaveReqDetails.map((datafltrleavereq, i) => {
                                            const rowClassNameAtleavefiltrreq = datafltrleavereq?.type_of_leave === "Leave"
                                                ? 'RowColorForLeave' : datafltrleavereq?.type_of_leave === "Movement" ? 'RowColorForMovement' : datafltrleavereq?.type_of_leave === "WFH" ? 'RowColorForWFH' : '';
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className={rowClassNameAtleavefiltrreq}>
                                                        {/* <td>
                                                            <Field type="hidden" name={`requestcomfirmation[${i}].leave_status_updated_by`} />
                                                            <Field
                                                                type="checkbox"
                                                                name={`requestcomfirmation[${i}].confirmation`}
                                                                onClick={(e) => handleCheckboxChangeAtleaverq(e, i)}
                                                                checked={LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.confirmation}
                                                            />&nbsp;{i + 1}
                                                        </td> */}
                                                        <td onClick={() => handleCheckboxChangeAtleaverq(null, i)}>
                                                            <Field
                                                                type="hidden"
                                                                name={`requestcomfirmation[${i}].leave_status_updated_by`}
                                                            />
                                                            <label htmlFor={`checkbox-${i}`}>
                                                                <Field
                                                                    id={`checkbox-${i}`}
                                                                    type="checkbox"
                                                                    name={`requestcomfirmation[${i}].confirmation`}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleCheckboxChangeAtleaverq(e, i);
                                                                    }}
                                                                    checked={LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.confirmation} />
                                                                &nbsp;{i + 1}
                                                            </label>
                                                        </td>
                                                        <td>{datafltrleavereq?.emp_id}</td>
                                                        <td>{datafltrleavereq?.emp_name}</td>
                                                        <td>{datafltrleavereq?.type_of_leave === "Movement" ? "Regularization" : datafltrleavereq?.type_of_leave}</td>
                                                        <td>
                                                            {datafltrleavereq?.no_of_days !== "" && (
                                                                <>
                                                                    {datafltrleavereq?.no_of_days === "One Day" && moment(datafltrleavereq?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                                                                    {datafltrleavereq?.no_of_days === "More Than One Day" && `${moment(datafltrleavereq?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} to ${moment(datafltrleavereq?.to_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}`}
                                                                    {datafltrleavereq?.no_of_days === "One Half Day" && `${moment(datafltrleavereq?.from_date, 'YYYY-MM-DD').format('DD-MM-YYYY')} (${datafltrleavereq?.halfday_from_time_to_time})`}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>{datafltrleavereq?.type_name === "Movement" ? "Regularization" : datafltrleavereq?.type_name}</td>
                                                        <td>
                                                            {(datafltrleavereq?.type_of_leave === "Leave" || datafltrleavereq?.type_of_leave === "WFH" || datafltrleavereq?.type_of_leave === "Movement") && (
                                                                <>
                                                                    {datafltrleavereq?.office_name}<pre></pre>{datafltrleavereq?.remarks}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <label>
                                                                <Field
                                                                    type="radio"
                                                                    name={`requestcomfirmation[${i}].leave_status`}
                                                                    value="true"
                                                                    onClick={() => handleRadioChangeAtLeaveReq(i, datafltrleavereq?.slno, datafltrleavereq?.emp_id, "true")}
                                                                    disabled={!LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.confirmation}
                                                                    onChange={() => LeaveApproveIOformIk.setFieldValue(`requestcomfirmation[${i}].leave_status_remakrs`, "")}
                                                                />&nbsp;Approve&emsp;
                                                            </label>
                                                            <label>
                                                                <Field
                                                                    type="radio"
                                                                    name={`requestcomfirmation[${i}].leave_status`}
                                                                    value="false"
                                                                    onClick={() => handleRadioChangeAtLeaveReq(i, datafltrleavereq?.slno, datafltrleavereq?.emp_id, "false")}
                                                                    disabled={!LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.confirmation}
                                                                    onChange={() => LeaveApproveIOformIk.setFieldValue(`requestcomfirmation[${i}].leave_status_remakrs`, "")}
                                                                />&nbsp;Reject
                                                            </label>
                                                            {LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.leave_status === 'false' && (
                                                                <>
                                                                    <Field
                                                                        type="text"
                                                                        name={`requestcomfirmation[${i}].leave_status_remakrs`}
                                                                        className="form-control mb-2"
                                                                        readOnly={!LeaveApproveIOformIk?.values?.requestcomfirmation[i]?.confirmation}
                                                                    />
                                                                    <ErrorMessage component="div" className="text-danger" name={`requestcomfirmation[${i}].leave_status_remakrs`} />
                                                                </>
                                                            )}
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => navigateToHistoryAtleaveREq(datafltrleavereq?.emp_id, datafltrleavereq?.emp_name)}
                                                            >
                                                                <BsEye />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            );
                                        })
                                    ) : (<p>No leave requests found matching the criteria.</p>)}
                                </tbody>
                            </table>
                            <jnb.Row className="pt-2 m-0">
                                <div className='d-flex flex-row justify-content-end pe-0'>
                                    <button type="submit" className="btn btn-primary btn-sm rounded" >update</button>
                                </div>
                            </jnb.Row>
                        </>) : (<>
                            {showLeaveReqErrMsg === true ? <center className="mt-5"><b style={{ color: "red" }}>**************** No Data Found **************</b></center> : null}</>)}
                    </jnb.Row>
                </Form>
            </FormikProvider>
        </jnb.Row>
    </>)
}