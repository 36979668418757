import React, { useEffect, useState } from 'react'
import * as jnb from "react-bootstrap";
import { ErrorMessage, Field,  Form, FormikProvider, useFormik } from 'formik';
import { config } from '../../../CommonUtils/CommonApis';
import Sweetalert, { SweetalertOKFunction } from '../../../CommonUtils/SweetAlerts';
import allowNumbersOnly from '../../../CommonUtils/CommonValidations';
import * as Yup from "yup";
import CommonPost from '../../../CommonUtils/CommonPost';
import { CommonAxiosGet } from '../../../CommonUtils/CommonAxios';
import { Link} from 'react-router-dom';
export default function KpWiseWeightage() {
    const validation = Yup.object().shape({
        kpAreas: Yup.array().of(Yup.object().shape({
            kpWeightage: Yup.string().required("Please Enter Weightage").matches(/^[0-9]+$/, "Weightage must be Digits only").nullable(),
        })),
    });
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            kpAreas: [{
                designationId: "",
                kpId: "",
                zoneId: "",
                kpWeightage: "",
                kpSubIndicator: "",
            }, {
                designationId: "",
                kpId: "",
                zoneId: "",
                kpWeightage: "",
                kpSubIndicator: "",
            }]
        },
        onSubmit: (values) => {
       
            CommonPost.kpAreasPost(values.kpAreas).then((res) => {
                if (res.data.scode === "01") {
                    SweetalertOKFunction(res.data.sdesc, 'success').then(function (isConfirm) {
                        if (isConfirm.value) {
                            Getdesignationsdata(values.kpAreas[0].zoneId);
                            setzonescreen(true);
                            setkparessscreen(false);
                        }
                    })
                } else {
                    Sweetalert(res.data.sdesc, 'warning')
                }
            })
                .catch(() => {
                    console.log("Exception Occured ");
                });

        },
        validationSchema: validation,
    });
    const [showZoneMaster, setZoneMaster] = useState([]);
    useEffect(() => {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((response) => {
            if (response.data.scode === "01") {
                setZoneMaster(response.data.data);
            } else { }
        });
    }, []);
    const [showzonescreen, setzonescreen] = useState(false);
    const [showkpareasscreen, setkparessscreen] = useState(false);
    const [showDesignationsData, setDesignationsData] = useState([]);
    const [showKPAreasData, setKPAreasData] = useState([]);
    const [showDesignationName, setDesignationName] = useState('');
    function Getdesignationsdata(zoneid) {
        if (zoneid != null && zoneid != "" && zoneid != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneid).then((res) => {
                if (res.data.scode === "01") {
                    setzonescreen(true);
                    setDesignationsData(res.data.data)
                }
                else if (res.data.scode === "02") {
                    Sweetalert(res.data.sdesc, 'warning')
                    setDesignationsData([])
                    setzonescreen(false)
                }
            })
        }

    }
    function Getkpareasdata(zoneid, desigid, designationname) {
        formIk.values.kpAreas = [];
        CommonAxiosGet(config.url.COMMON_URL + "kpsweightage/" + zoneid + "/" + desigid).then((res) => {
            if (res.data.scode === "01") {
                setDesignationName(designationname);
                setKPAreasData(res.data.data)
                for (var i = 0; i <= res.data.data.length; i++) {
                    formIk.setFieldValue("kpAreas[" + i + "].designationId", res.data.data[i]?.designation_id);
                    formIk.setFieldValue("kpAreas[" + i + "].kpId", res.data.data[i]?.kp_id);
                    formIk.setFieldValue("kpAreas[" + i + "].zoneId", res.data.data[i]?.zone_id);
                    formIk.setFieldValue("kpAreas[" + i + "].kpWeightage", res.data.data[i]?.kp_weightage);
                    if (res.data.data[i]?.kp_sub_indicator_flag === "N")//
                    {
                        formIk.setFieldValue("kpAreas[" + i + "].kpSubIndicator", 0);
                    }
                    else {
                        formIk.setFieldValue("kpAreas[" + i + "].kpSubIndicator", res.data.data[i]?.kp_sub_indicator);
                    }
                }
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, 'warning')
                setkparessscreen(false)
                setKPAreasData([])
            }
        })
    }
   
    return (<>
       
         <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Key Performance Weightage</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"> <h1>Key Performance Weightage</h1></div>
        <div className="border p-3 pb-0">
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                <jnb.Row className="px-0 formss">
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                        <span className="label-text pt-2 d-inline-block"><b>Zone<font style={{ color: "red" }}>*</font>:</b></span>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <Field as="select" className="form-control" name="zone"
                                onChange={(e) => { Getdesignationsdata(e.target.value); setkparessscreen(false); }}>
                                <option value="">--Select--</option>
                                {showZoneMaster !== undefined && showZoneMaster.map((cs, i) => {
                                    return (
                                        <option key={i} value={cs.zone_id}>{cs.zone_name}</option>);
                                })}
                            </Field>
                            <ErrorMessage name="zone" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                </jnb.Row>
                {showzonescreen && (<>
                    <jnb.Row className="px-0">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3} > Zone:&nbsp;
                            <b>
                                {showZoneMaster.filter((e, i) => e.zone_id == formIk.values.zone)[0]?.zone_name}</b></jnb.Col>
                        <div className="table-responsive mt-3">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr >
                                        <th>S.No</th>
                                        <th>Designations</th>
                                        <th>No.of Key Performance Areas<br></br>For 100 Percentage Weightage</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showDesignationsData !== undefined && showDesignationsData.map((row, i) => {
                                        return (
                                            <tr key={i}>
                                                <td align='center'>{i + 1}</td>
                                                <td>{row.designation_name}</td>
                                                <td><a href="#" onClick={(e) => {
                                                    Getkpareasdata(row.zone_id, row.designation_id, row.designation_name);
                                                    setkparessscreen(true); setzonescreen(false)
                                                }}>{row.noofkp}</a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </jnb.Row>
                </>)}
                {showkpareasscreen && (<>
                    <jnb.Row className="px-0 pt-2">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={1} xxl={1}>
                            <button type="button" className='btn btn-primary btn-sm' onClick={(e) => {
                                 Getdesignationsdata( formIk.values.zone);
                                setzonescreen(true); setkparessscreen(false);
                            }} >Back</button></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3} >
                            Zone:&nbsp;<b>
                                {showZoneMaster.filter((e, i) => e.zone_id == formIk.values.zone)[0]?.zone_name}</b></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4} >
                            Designation:&nbsp;<b>{showDesignationName}</b></jnb.Col>
                        <div className="table-responsive mt-3">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Key Performance Areas Description</th>
                                        <th>Weightage<font style={{ color: "red" }}>*</font></th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showKPAreasData !== undefined && showKPAreasData.map((row, i) => {
                                        return (
                                            <tr key={i}>
                                                <td align='center'>{i + 1}
                                                </td>
                                                <td>{row.kp_description}</td>
                                                <td><Field type="text" className="form-control" name={`kpAreas[${i}].kpWeightage`} maxLength="2"
                                                    onKeyPress={(e) => { allowNumbersOnly(e); }}></Field>
                                                    <ErrorMessage name={`kpAreas[${i}].kpWeightage`} component="div" className="text-danger" ></ErrorMessage>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "center" }}>Totals</td>
                                        <td>
                                            {isNaN(formIk.values.kpAreas.map((order) => order.kpWeightage).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0)) ? 0 : (formIk.values.kpAreas.map((order) => order.kpWeightage).reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}></jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} style={{ textAlign: "right" }} >
                            <button type="submit" className="btn btn-success btn-sm" style={{ float: "right" }}>
                                Submit
                            </button>
                            <br></br>
                        </jnb.Col>

                    </jnb.Row>

                </>)}
                <br></br>
                {/* </div> */}

                {/* </jnb.Container> */}
            </Form>
        </FormikProvider >
        </div>
        {/* <Footer/> */}
    </>)
}