
import { IMG_UPLOAD_URL } from "./CommonApis";
import { CommonAxiosPost } from "./CommonAxios";
import { InfoAlert } from "./SweetAlerts";
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

const MAX_FILE_SIZE = 1000000;

function validateFileTypeAndSize(customefile) {
    console.log("customefile?.type", customefile)
    let errormsg = "";
    if (customefile?.size > MAX_FILE_SIZE) {
        errormsg = "Please Check your file size, it should be less than 1MB\n";
    }
    if (!SUPPORTED_FORMATS.includes(customefile?.type)) {
        console.log("customefile?.type", customefile?.type)

        errormsg = "File format is invalid, Please upload only .jpg or .jpeg or .png";
    }
    if (errormsg === "") {
        return true;
    }
    else {
        InfoAlert(errormsg, "info");
        return false;
    }
}


export default function ImageBucketUpload(e, formIk, path, name, filename) {
    e.preventDefault();
    const file = e.target.files[0];
    console.log("file", file)
    const type = file.type.split("/")[1];
    console.log("type", type)
    const newFileName = filename + "." + type;
    console.log("newFileName", newFileName)
    const modifiedFile = new File([file], newFileName, { type: file.type });
    console.log("modifiedFile", modifiedFile)
    if (validateFileTypeAndSize(file)) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", modifiedFile);
            console.log("formdata", formData)
            CommonAxiosPost(IMG_UPLOAD_URL + path, formData).then((response) => {
                resolve(response.data)
                formIk.setFieldValue(name, response?.data);
            }).catch((error) => {
                formIk.setFieldValue(name, '');
                console.log("error at imagebucket", error)
                resolve(null);
            });

        });
    } else {
        e.target.value = '';
    }
}