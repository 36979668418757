import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useSelector } from "react-redux";
function HomePage() {
  //.....................................reducers............................
  const logindetailsAthomePage = useSelector((statehome) => statehome.reducers.loginreducer.userLoginDetials);


  return (
    <>
      <div style={{ padding: '16px 24px', color: '#44596e', textAlign: 'left' }}>
            <div style={{ marginBottom: '10px' }}>
              <center>

                


                <strong><span>{"Welcome to Online System for Annual Appraisal 2022-23"}&nbsp;&nbsp;</span>{logindetailsAthomePage?.employee_name}</strong>

              </center>

            </div>
          </div>
    </>
  );
}

export default HomePage;
