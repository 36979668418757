import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import { Link } from "react-router-dom";
import * as jnb from "react-bootstrap";
import React, { useEffect,useState } from "react";
import { config } from "../../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { CommonAxiosDelete, CommonAxiosGet } from "../../../../CommonUtils/CommonAxios";
import { allowAlphabetAndSpacesOnly } from "../../../../CommonUtils/CommonValidations";
import Swal from "sweetalert2";
import CommonPost from "../../../../CommonUtils/CommonPost";
import * as Yup from "yup";

export default function MastersEntryForm(){
    const [showSaveType, setSaveType] = useState("");
    const validateCategory = Yup.object().shape({
        category_name: Yup.string().required("Please Enter Category Name").nullable(),
    });
    const validateDepartment = Yup.object().shape({
        department_name: Yup.string().required("Please Enter Deparment Name").nullable(),
    });
    const validateProject = Yup.object().shape({
        project_name: Yup.string().required("Please Enter Project Wise Name").nullable(),
    });
    const validateLevel = Yup.object().shape({
        level_name: Yup.string().required("Please Enter level Name").nullable(),
    });
    const validateBand = Yup.object().shape({
        band_name: Yup.string().required("Please Enter Band Name").nullable(),
    });
    const validateQualification= Yup.object().shape({
        qualification_name: Yup.string().required("Please Enter Qualification").nullable(),
    });
    const validateZone= Yup.object().shape({
        zone_name: Yup.string().required("Please Enter Zone Name").nullable(),
    });
    const categoryvalidationJSON = [
        {
            type: "CATEGORY",
            validation_schema_name: validateCategory,
        }, {
            type: "DEPARTMENT",
            validation_schema_name: validateDepartment,
        },
        {
            type: "PROJECT",
            validation_schema_name: validateProject,
        },
        {
            type: "LEVEL",
            validation_schema_name: validateLevel,
        },
        {
            type: "BAND",
            validation_schema_name: validateBand,
        },
        {
            type: "ZONE",
            validation_schema_name: validateZone,
        },
        {
            type: "QUALIFICATION",
            validation_schema_name: validateQualification,
        }
    ]
    const MastersEntryFormformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            master: "",
            category_name: "",
            department_name: "",
            level_name: "",
            project_name: "",
            band_name: "",
            category_id: "",
            department_id: "",
            project_id: "",
            level_id: "",
            band_id: "",
            zone_id:"",
            zone_name:"",
            qualification_id:"",
            qualifiication_name:"",
        },
        onSubmit: (values) => {
            MastersEntryFormSubmitALert(values)
        },
        validationSchema: categoryvalidationJSON?.filter((rowdataValidate) => rowdataValidate.type === showSaveType)[0]?.validation_schema_name,
    });
    const getMastername = (masterValue) => {
        switch (masterValue) {
            case "C":
                return "categories";
            case "D":
                return "departments";
            case "L":
                return "levels";
            case "P":
                return "project-wise";
            case "B":
                return "bands";
            case "Z":
                return "zones";
            case "Q":
                return "qualifications";
            default:
                return "";
        }
    };
    const MastersEntryFormSubmitALert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                const Mastername = getMastername(values.master);
                
                if (Mastername) {
                    CommonPost.MatersEntryPost(Mastername, values).then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Submitted', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    MastersEntryFormformIk.resetForm();
                                    GetAllMastersEntryForm();
                                }
                            });
                        } else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        } else {
                            Swal.fire('Canceled', 'Something went wrong, Please check', 'error');
                        }
                    }).catch(() => { console.log(null) });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        });
    };
    const [showCategoryMasterEntryForm, setCategoryMasterEntryForm] = useState([]);
    const [showDepartmentMasterEntryForm, setDepartmentMasterEntryForm] = useState([]);
    const [showLevelsMasterEntryForm, setLevelsMasterEntryForm] = useState([]);
    const [showProjectWiseMasterEntryForm, setProjectWiseMasterEntryForm] = useState([]);
    const [showBandMasterEntryForm, setBandMasterEntryForm] = useState([]);
    const [showZoneMasterEntryForm, setZoneMasterEntryForm] = useState([]);
    const [showQualificationMasterEntryForm, setQualificationMasterEntryForm] = useState([]);
    function GetAllMastersEntryForm() {
        CommonAxiosGet(config.url.COMMON_URL + "masters/categories").then((responseallmst) => {
            if (responseallmst.data.scode === "01") {
                setCategoryMasterEntryForm(responseallmst.data.data);
            } else if (responseallmst.data.scode === "02") {
                Sweetalert(responseallmst.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/departments").then((responsedept) => {
            if (responsedept.data.scode === "01") {
                setDepartmentMasterEntryForm(responsedept.data.data);
            } else if (responsedept.data.scode === "02") {
                Sweetalert(responsedept.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/levels").then((responselevels) => {
            if (responselevels.data.scode === "01") {
                setLevelsMasterEntryForm(responselevels.data.data);
            } else if (responselevels.data.scode === "02") {
                Sweetalert(responselevels.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/project-wise").then((responseprj) => {
            if (responseprj.data.scode === "01") {
                setProjectWiseMasterEntryForm(responseprj.data.data);
            } else if (responseprj.data.scode === "02") {
                Sweetalert(responseprj.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/bands").then((responsebands) => {
            if (responsebands.data.scode === "01") {
                setBandMasterEntryForm(responsebands.data.data);
            } else if (responsebands.data.scode === "02") {
                Sweetalert(responsebands.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((responsezones) => {
            if (responsezones.data.scode === "01") {
                setZoneMasterEntryForm(responsezones.data.data);
            } else if (responsezones.data.scode === "02") {
                Sweetalert(responsezones.data.sdesc, "warning");
            }
        });
        CommonAxiosGet(config.url.COMMON_URL + "masters/qualifications").then((responseql) => {
            if (responseql.data.scode === "01") {
                setQualificationMasterEntryForm(responseql.data.data);
            } else if (responseql.data.scode === "02") {
                Sweetalert(responseql.data.sdesc, "warning");
            }
        });
    }
    useEffect(() => {
        GetAllMastersEntryForm();
    }, [])
    const [showFlag,setFlag]=useState(false)
    function GetCategoryNameSet(namecatgset, catgsetid) {
        MastersEntryFormformIk.setFieldValue("category_name", namecatgset)
        MastersEntryFormformIk.setFieldValue("category_id", catgsetid)
        setFlag(true)
    }
    function GetDepartmentNameSet(namedeptset, deptsetid) {
        MastersEntryFormformIk.setFieldValue("department_name", namedeptset)
        MastersEntryFormformIk.setFieldValue("department_id", deptsetid)
        setFlag(true)
    }
    function GetprojectWiseNameSet(nameprjset, prjsetid) {
        MastersEntryFormformIk.setFieldValue("project_name", nameprjset)
        MastersEntryFormformIk.setFieldValue("project_id", prjsetid)
        setFlag(true)
    }
    function GetLevelNameSet(namelevel, levelid) {
        MastersEntryFormformIk.setFieldValue("level_name", namelevel)
        MastersEntryFormformIk.setFieldValue("level_id", levelid)
        setFlag(true)
    }
    function GetBandNameSet(nameband, bandid) {
        MastersEntryFormformIk.setFieldValue("band_name", nameband)
        MastersEntryFormformIk.setFieldValue("band_id", bandid)
        setFlag(true)
    }
    function GetZoneNameSet(namezoneset, zonesetid) {
        MastersEntryFormformIk.setFieldValue("zone_name", namezoneset)
        MastersEntryFormformIk.setFieldValue("zone_id", zonesetid)
        setFlag(true)
    }
    function GetQualificationNameSet(nameqalf, idqalf) {
        MastersEntryFormformIk.setFieldValue("qualification_name", nameqalf)
        MastersEntryFormformIk.setFieldValue("qualification_id", idqalf)
        setFlag(true)
    }
    function ClearValues() {
        MastersEntryFormformIk.setFieldValue("category_name", "")
        MastersEntryFormformIk.setFieldValue("department_name", "")
        MastersEntryFormformIk.setFieldValue("level_name", "")
        MastersEntryFormformIk.setFieldValue("project_name", "")
        MastersEntryFormformIk.setFieldValue("band_name", "")
        MastersEntryFormformIk.setFieldValue("zone_name", "")
        MastersEntryFormformIk.setFieldValue("qualification_name", "")
        MastersEntryFormformIk.setFieldValue("category_id", "")
        MastersEntryFormformIk.setFieldValue("band_id", "")
        MastersEntryFormformIk.setFieldValue("level_id", "")
        MastersEntryFormformIk.setFieldValue("project_id", "")
        MastersEntryFormformIk.setFieldValue("department_id", "")
        MastersEntryFormformIk.setFieldValue("zone_id", "")
        MastersEntryFormformIk.setFieldValue("qualification_id", "")
        setFlag(false)
    }
 
    const DeleteExistedEntry = (id, name) => {
        Swal.fire({
            title: `<p style='color:blue;font-size:20px;font-weight:bold;'>${name}</p>`,
            text: 'Are you sure you want to delete?',
            icon: 'warning',
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then((result) => {
            if (result.value) {
                const Mastername = getMastername(MastersEntryFormformIk.values.master);
                
                if (Mastername) {
                    CommonAxiosDelete(`${config.url.COMMON_URL}masters/${Mastername}/${id}`).then((response) => {
                        if (response.data.scode === "01") {
                            SweetalertOKFunction("Deleted Successfully", 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetAllMastersEntryForm();
                                }
                            });
                        } else if (response.data.scode === "02") {
                            Sweetalert(response.data.sdesc, "warning");
                        }
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        });
    };
    
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Masters Entry Form</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"><h1>Masters Entry Form </h1></div>
        <FormikProvider value={MastersEntryFormformIk}>
            <Form onSubmit={MastersEntryFormformIk.handleSubmit} onChange={MastersEntryFormformIk.handleChange}>
                <div className="border p-3 pb-0 mb-3">
                    <jnb.Row className="px-0 mb-3 formss">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                            <span className="label-text mb-2 d-block"><b>Masters<font style={{ color: "red" }}>*</font>:</b></span>
                            <jnb.InputGroup className="mb-45">
                                <Field as="select" className='form-control' name="master"
                                    onChange={(e) => { ClearValues(); }}>
                                    <option value="">--Select--</option>
                                    <option value="C">Category of Employement</option>
                                    <option value="D">Department</option>
                                    <option value="L">Level</option>
                                    <option value="P">ProjectWise</option>
                                    <option value="B">Band</option>
                                    <option value="Z">Zone</option>
                                    <option value="Q">Qualification</option>
                                </Field>
                            </jnb.InputGroup>
                            <ErrorMessage name="master" component="div" className="text-danger" ></ErrorMessage>
                        </jnb.Col>
                    </jnb.Row>
                </div>
                {MastersEntryFormformIk.values.master != "" ? (
                    <div className="border p-3 pb-0 mb-3">
                        {MastersEntryFormformIk.values.master === "C" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Category of Employement<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="category_name" className="form-control" 
                                        onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50"/>
                                        <ErrorMessage name="category_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("CATEGORY") }}>{showFlag ? "Update":"Submit"}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Category Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showCategoryMasterEntryForm !== undefined && showCategoryMasterEntryForm?.map((rowdataAtMaster, rowdataAtMasterIndex) => {
                                        return (<React.Fragment key={rowdataAtMasterIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdataAtMasterIndex + 1}</td>
                                                <td>{rowdataAtMaster.category_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetCategoryNameSet(rowdataAtMaster.category_name, rowdataAtMaster.category_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdataAtMaster.category_id,rowdataAtMaster.category_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                        {MastersEntryFormformIk.values.master === "D" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Department<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="department_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="department_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("DEPARTMENT") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Department Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showDepartmentMasterEntryForm !== undefined && showDepartmentMasterEntryForm?.map((rowdataDEpt, rowdataDEptIndex) => {
                                        return (<React.Fragment key={rowdataDEptIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdataDEptIndex + 1}</td>
                                                <td>{rowdataDEpt.department_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetDepartmentNameSet(rowdataDEpt.department_name, rowdataDEpt.department_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdataDEpt.department_id,rowdataDEpt.department_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) :null}
                        {MastersEntryFormformIk.values.master === "L" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Level<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="level_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="level_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("LEVEL") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Level Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showLevelsMasterEntryForm !== undefined && showLevelsMasterEntryForm?.map((rowdatalevel, rowdatalevelIndex) => {
                                        return (<React.Fragment key={rowdatalevelIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdatalevelIndex + 1}</td>
                                                <td>{rowdatalevel.level_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetLevelNameSet(rowdatalevel.level_name, rowdatalevel.level_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdatalevel.level_id,rowdatalevel.level_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                        {MastersEntryFormformIk.values.master === "P" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>ProjectWise<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="project_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="project_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("PROJECT") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Project Wise Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showProjectWiseMasterEntryForm !== undefined && showProjectWiseMasterEntryForm?.map((rowdataprj, rowdataprjIndex) => {
                                        return (<React.Fragment key={rowdataprjIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdataprjIndex + 1}</td>
                                                <td>{rowdataprj.project_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetprojectWiseNameSet(rowdataprj.project_name, rowdataprj.project_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdataprj.project_id,rowdataprj.project_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                        {MastersEntryFormformIk.values.master === "Z" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Zone<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="zone_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="zone_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("ZONE") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Zone Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showZoneMasterEntryForm !== undefined && showZoneMasterEntryForm?.map((rowdatazone, rowdatazoneIndex) => {
                                        return (<React.Fragment key={rowdatazoneIndex}>
                                            <tr>                           
                                                <td align="center" width="100px">{rowdatazoneIndex + 1}</td>
                                                <td>{rowdatazone.zone_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetZoneNameSet(rowdatazone.zone_name, rowdatazone.zone_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdatazone.zone_id,rowdatazone.zone_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                        {MastersEntryFormformIk.values.master === "B" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Band<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="band_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="band_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("BAND") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Band Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showBandMasterEntryForm !== undefined && showBandMasterEntryForm?.map((rowdataband, rowdatabandIndex) => {
                                        return (<React.Fragment key={rowdatabandIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdatabandIndex + 1}</td>
                                                <td>{rowdataband.band_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetBandNameSet(rowdataband.band_name, rowdataband.band_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdataband.band_id,rowdataband.band_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                        {MastersEntryFormformIk.values.master === "Q" ? (<>
                            <jnb.Row className="px-0 mb-3 formss">
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <span className="label-text mb-2 d-block" ><b>Qualification<font style={{ color: "red" }}>*</font> : </b></span>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                                    <jnb.InputGroup className="mb-4p5">
                                        <Field type="text" name="qualification_name" className="form-control" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }} maxLength="50" />
                                        <ErrorMessage name="qualification_name" component="div" className="text-error" />
                                    </jnb.InputGroup>
                                </jnb.Col>
                                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2}>
                                    <button className="btn btn-success btn-sm " type="submit"
                                        onClick={(e) => { setSaveType("QUALIFICATION") }}>{showFlag?("Update"):("Submit")}</button>
                                </jnb.Col>
                            </jnb.Row>
                            <br /><br /><br />
                            <legend >Report:</legend>
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Qualification Name</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showQualificationMasterEntryForm !== undefined && showQualificationMasterEntryForm?.map((rowdataqualify, rowdataqualifyIndex) => {
                                        return (<React.Fragment key={rowdataqualifyIndex}>
                                            <tr>
                                                <td align="center" width="100px">{rowdataqualifyIndex + 1}</td>
                                                <td>{rowdataqualify.qualification_name}</td>
                                                <td><button className="btn btn-success btn-sm " type="button"
                                                    onClick={(e) => { GetQualificationNameSet(rowdataqualify.qualification_name, rowdataqualify.qualification_id) }}>Edit</button></td>
                                                <td><button className="btn btn-danger btn-sm " type="button"
                                                onClick={(e)=>{DeleteExistedEntry(rowdataqualify.qualification_id,rowdataqualify.qualification_name)}}>Delete</button></td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>) : null}
                    </div>
                ) : null}
            </Form>
        </FormikProvider>
    </>)
}