import React from 'react';
import { SiMicrosoftexcel } from 'react-icons/si';
import { BsPrinter } from 'react-icons/bs';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import * as jnb from "react-bootstrap";
function SearchAndExportSection({ searchValue, onSearchChange, placeholder, handlePrint }) {
  return (
    <jnb.Row className="px-0">
      <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
        <jnb.InputGroup className="mb-1">
          <input
            name={`search_${Date.now()}`}
            type="text"
            className="form-control"
            value={searchValue}
            onChange={onSearchChange}
            placeholder={placeholder}
          />
        </jnb.InputGroup>
      </jnb.Col>
      <jnb.Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5}></jnb.Col>
      <jnb.Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4} className='excel-print'>
        <div style={{ float: 'right' }}>
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="pull-right btn btn-sm btn-success"
            table="table-to-xls"
            filename={"FRs Report"}
            sheet="tablexls"
            buttonText={<><SiMicrosoftexcel size={20} title="Excel" /> Excel</>}
          />
          &nbsp;
          <button type="button" className="btn btn-primary btn-sm" onClick={handlePrint}>
            <BsPrinter title="Print" size={"20px"} /> Print
          </button>
        </div>
      </jnb.Col>
      <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}></jnb.Col>
    </jnb.Row>
  );
}

export default SearchAndExportSection;
