
import LoginReducer from '../Reducers/LoginReducer';

import { combineReducers } from "redux";
import { ModalPopupReducer } from '../Reducers/ModalPopUpReducer';
import EmployeeReducer from '../Reducers/EmployeeReducer';
import SpinnerReducer from '../Reducers/SpinnerReducer';

 const Reducers = combineReducers({
     loginreducer : LoginReducer,
     employeereducer:EmployeeReducer,
     modalState : ModalPopupReducer,
     spinner: SpinnerReducer,
})

export default Reducers;