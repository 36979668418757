import React, { useEffect, useRef, useState } from 'react'
import * as jnb from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom'
import { config } from '../../../../CommonUtils/CommonApis';
import { CommonAxiosGet } from '../../../../CommonUtils/CommonAxios';
import CommonReactTable from '../../../../CommonUtils/CommonReactTable';

export default function WithoutDuesEmployeesDetails(){
    const [showWithoutDuesemployeedetails,setWithoutDuesEmployeeDetails]=useState([]);
    const [showErrMsgAtwithoutdues,setErrMsgAtwithoutdues]=useState(false)
    const navigateAtwithoutdues= useNavigate();

    function navigatetoEmpWithOrWithoutDues(empidAtwithoutdues,empnameAtwithoutdues,designation_nameAtwithoutdues,designation_idAtwithoutdues,count,asset_idAtwithoutdues){
        localStorage.setItem("employee_id", empidAtwithoutdues);
        localStorage.setItem("employee_name", empnameAtwithoutdues);
        localStorage.setItem("designation_name", designation_nameAtwithoutdues);
        localStorage.setItem("designation_id", designation_idAtwithoutdues);
        localStorage.setItem("asset_id", asset_idAtwithoutdues);
        localStorage.setItem("backbutton", "witoutdues");
        navigateAtwithoutdues("/withduesorwithoutduesdetails",{state:{count:count}})
    }

    function GetDataEmpWithOrWithoutDues() {
        let Url = (config?.url?.COMMON_URL + "assetsreport/withoutdues");
        CommonAxiosGet(Url).then((resAtDues) => {
            if(resAtDues?.data?.status === true){
                setWithoutDuesEmployeeDetails(resAtDues?.data?.assetsreport);
                setErrMsgAtwithoutdues(false)
        }
        else{
            setWithoutDuesEmployeeDetails([]);
            setErrMsgAtwithoutdues(true)
        }
        })
    }
    const WithoutDuesEmployeesDetailscolumns = [
        {
            Header: "S.No",
            accessor: (row,duesIndex) => (<>{duesIndex + 1}&nbsp;</>),
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: 'ID',
            accessor: "cfms_id",
        },
        {
            Header: 'Employee Name',
            accessor: "emp_name",
        },
        {
            Header: 'Designation',
            accessor: "designation_name",
        },
        {
            Header: 'No.Of Dues',
            accessor: (duesrow,i) => (
                <div style={{ fontSize: "15px" }} className=" p-1">
                   <button type="button" class="btn btn-link btn-sm"  
                    onClick={(e) => {navigatetoEmpWithOrWithoutDues(duesrow?.cfms_id,duesrow?.emp_name,duesrow?.designation_name,duesrow?.designation_id,duesrow?.count,duesrow?.asset_id)  }}>
                    {duesrow?.count}
                    </button>
                </div>  
            ),
        },
];
    const fetchDataAtWithoutDues = useRef(false)
    useEffect(() => {
        if(!fetchDataAtWithoutDues.current){
            GetDataEmpWithOrWithoutDues()
            fetchDataAtWithoutDues.current = true
        }
    }, []);

    function navigateToActiveemployeesAtWitoutDues(){navigateAtwithoutdues("/employeesinfo")}

  return (
    <div>
        <jnb.Row className=" m-0">
            <jnb.Col xs={12} sm={12} md={12} lg={11} xl={11} xxl={11}>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/homepage">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Employee Dues Report</li>
                </ol></jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
           <button type="button" className="bg-success p-2 text-white  btn-sm rounded" style={{float:"right"}}
           onClick={() => {navigateToActiveemployeesAtWitoutDues()}}>Back</button> 
        </jnb.Col></jnb.Row>
         <jnb.Row className=" pt-2">
                    <jnb.Row className="px-3">
                        {showWithoutDuesemployeedetails?.length>0 ? (<>
           <CommonReactTable data={showWithoutDuesemployeedetails} columns={WithoutDuesEmployeesDetailscolumns} showFooter="false" filename="Employee Dues Report" 
                                        headerName={`Employee Dues Report`} />
        </>):(<>{showErrMsgAtwithoutdues === true ? (<>
            <center><b style={{color:"red"}}>*********************No Data Found******************* </b></center></>):(<></>)}</>)}
        </jnb.Row>
        </jnb.Row>
    </div>
  )
}
