import { ErrorMessage, Field, Form, FormikProvider, useFormik } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect,useState} from "react";
import { config } from "../../../CommonUtils/CommonApis";
import Sweetalert, { SweetalertOKFunction } from "../../../CommonUtils/SweetAlerts";
import { CommonAxiosGet, CommonAxiosPost } from "../../../CommonUtils/CommonAxios";
import CommonPost from "../../../CommonUtils/CommonPost";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as Yup from "yup";
export default function KpsubindicatorsMapping() {
    const validation = Yup.object().shape({
        zoneId: Yup.string().required("Please Select ZoneId").nullable(),
        designationId: Yup.string().required("Please Select designationId").nullable(),
        kpId: Yup.string().required("Please Select kpId").nullable(),
    });
    const [showTypeSave, setTypeSave] = useState("");
    const [showZoneId, setZoneId] = useState("");
    const [showDesgId, setDesgId] = useState("");
    const [showKpid, setKpid] = useState("");
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            zoneId: showZoneId,
            designationId: showDesgId,
            kpId: showKpid,
            kpIndicatorId: "",
        },
        onSubmit: (values) => {
            showTypeSave === "save" ? KPsubmitAlert(values) : (<></>)
        },
        validationSchema: validation,
    });
    const KPsubmitAlert = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowEnterKey: false, 
            allowEscapeKey: false,
             allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                CommonPost.kpindicatorPost(values)
                    .then((res) => {
                        if (res.data.scode === "01") {
                            SweetalertOKFunction('Successfully Updated ', 'success').then(function (isConfirm) {
                                if (isConfirm.value) {
                                    GetMappedData(values.zoneId, values.kpId, values.designationId);
                                    KeyPerformanceGet(values.zoneId,values.kpId,values.designationId);
                                }
                            })
                        }
                        else if (res.data.scode === "02") {
                            Sweetalert(res.data.sdesc, 'warning');
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    })
                    .catch(() => {console.log(null)});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
    const [showKPdata, setKPdata] = useState([]);
    const [showZoneMaster, setZoneMaster] = useState([]);
    const [showDesignationsData, setDesignationsData] = useState([]);
    const [showkpcountdata, setkpcountdata] = useState([]);
    const [showmappedatabrowser, setmappedbrowser] = useState(false);
    const [showmappeddata, setmappeddata] = useState([]);
    const [showbrowser, setbrowser] = useState(false);
    function Zones() {
        CommonAxiosGet(config.url.COMMON_URL + "zones").then((response) => {
            if (response.data.scode === "01") {
                setZoneMaster(response.data.data);
            } else if (response.data.scode === "02") {
                Sweetalert(response.data.sdesc, "warning");
            }
        });
    }
    function KeyPerformances() {
        CommonAxiosGet(config.url.COMMON_URL + "keyperformances/Y").then((res) => {
            if (res.data.scode === "01") {
                setKPdata(res.data.data);
            }
            else if (res.data.scode === "02") {
                Sweetalert(res.data.sdesc, "warning");
            }
        })
    }
    function KeyPerformanceGet(zoneid, kpid, designationid) {
        const checkboxes = Array.from(document.querySelectorAll('input[type="checkbox"]'));
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;});
        setZoneId(zoneid);
        setDesgId(designationid);
        setKpid(kpid)
        CommonAxiosGet(config.url.COMMON_URL + "keyperformanceindicators/" + zoneid + "/" + designationid + "/" + kpid + "/N").then((res) => {
            if (res.data.scode === "01") {
                setkpcountdata(res.data.data);
                formIk.setFieldValue("kpIndicatorId", "")
                setbrowser(true);
            }
            else if (res.data.scode === "02") {Sweetalert(res.data.sdesc, "warning");}
        })

    }
    function GetMappedData(zoneid, kpid, designationid) {
        CommonAxiosGet(config.url.COMMON_URL + "keyperformanceindicators/" + zoneid + "/" + designationid + "/" + kpid + "/Y").then((res) => {
         
            if (res.data.scode === "01") {
                setmappeddata(res.data.data);
                setmappedbrowser(true)
            }
            else if (res.data.scode === "02") {
                setmappedbrowser(false);
                setmappeddata([])
            }
        })
    }
    function Getdesignationsdata(zoneid) {

        if (zoneid != null && zoneid != "" && zoneid != undefined) {
            CommonAxiosGet(config.url.COMMON_URL + "designationkps/" + zoneid).then((res) => {
                if (res.data.scode === "01") {
                    setDesignationsData(res.data.data)
                }
                else if (res.data.scode === "02") {
                    Sweetalert(res.data.sdesc, 'warning')
                    setDesignationsData([])
                }
            })
        }
    }
    useEffect(() => {
        KeyPerformances();
        Zones();
    }, [])
    function updateKPIndicatorsStatus(kp_indicator_id,status){
        
        Swal.fire({
            title: status===false? ' Are you sure you want to  Unmap the Indicator':' Are you sure you want to  Map the Indicator' ,
            text: 'Please check it once before Maping/Unmaping',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:status===false? '#d33':'#3085d6',
            cancelButtonColor:status===false? '#3085d6':'#d33',
            confirmButtonText: status===false? 'Unmap':'Map',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            allowEnterKey: false, 
            allowEscapeKey: false,
             allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                let zoneid=formIk?.values?.zoneId;
                let designationId=formIk.values.designationId;
                let kpid=formIk.values.kpId;
                CommonAxiosPost(config.url.COMMON_URL +"kpindicatorsunmap/"+zoneid+"/"+designationId+"/"+kpid+"/"+kp_indicator_id+"/active/"+status).then((res) => {
                    if (res.data.scode === "01") {
                        SweetalertOKFunction('Successfully Updated ', 'success').then(function (isConfirm) {
                            if (isConfirm.value) {
                                GetMappedData(formIk.values.zoneId, formIk.values.kpId, formIk.values.designationId);
                                KeyPerformanceGet(formIk.values.zoneId,formIk.values.kpId,formIk.values.designationId);
                            }
                        })
                    }
                    else if (res.data.scode === "02") {
                        Sweetalert(res.data.sdesc, 'warning');
                    }
                })
                    .catch(() => {console.log(null)});
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
        
    }
    return (<>
        <div className="card">
            <div className="card-body">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page"> Key Performance Sub Indicators Mapping</li>
                </ol>
            </div>
        </div>
        <div className="head-text mt-4"><h1>Key Performance Sub Indicators Mapping</h1></div>
        <div className="border p-3 pb-0 mb-3">
            <FormikProvider value={formIk}>
                <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
                    <jnb.Row className="px-0  formss">
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                            <span className="label-text mb-2 d-block" ><b>Zone<font style={{ color: "red" }}>*</font> : </b></span>
                            <jnb.InputGroup className="mb-4p5">
                                <Field as="select" className="form-control" name="zoneId"
                                    onChange={(e) => { Getdesignationsdata(e.target.value); }}>
                                    <option value="">--Select--</option>
                                    {showZoneMaster !== undefined && showZoneMaster.map((cs, i) => {
                                        return (<option key={i} value={cs.zone_id}>{cs.zone_name}</option>);})}
                                </Field>
                                <ErrorMessage name="zoneId" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                            <span className="label-text mb-2 d-block" ><b>Designation<font style={{ color: "red" }}>*</font> : </b></span>
                            <jnb.InputGroup className="mb-4p5">
                                <Field as="select" className="form-control" name="designationId">
                                    <option value="">--Select--</option>
                                    {showDesignationsData !== undefined && showDesignationsData.map((row, i) => {
                                        return (<option key={i} value={row.designation_id}>{row.designation_name}</option>);})}
                                </Field>
                                <ErrorMessage name="designationId" component="div" className="text-error" />
                            </jnb.InputGroup>
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
                            <span className="label-text mb-2 d-block" ><b>Key Performance<font style={{ color: "red" }}>*</font> : </b></span>
                            <jnb.InputGroup className="mb-4p5">
                                <Field as="select" className='form-control' name="kpId">
                                    <option value="">--Select--</option>
                                    {showKPdata !== undefined && showKPdata.map((rowdata, i) => {
                                        return (<option value={rowdata.kpId} key={i}>{rowdata.kpDescription}</option>)})}
                                </Field>
                            </jnb.InputGroup>
                            <ErrorMessage name="kpId" component="div" className="text-error" />
                        </jnb.Col>
                        <jnb.Col xs={12} sm={12} md={12} lg={12} xl={2} xxl={2} className="text-end">
                            <button className="btn btn-success btn-sm pt-2" type="submit" style={{ marginTop: "24px" }}
                                onClick={(e) => {
                                    setTypeSave("getdata");
                                    KeyPerformanceGet(formIk.values.zoneId, formIk.values.kpId, formIk.values.designationId); 
                                    GetMappedData(formIk.values.zoneId, formIk.values.kpId, formIk.values.designationId); setmappedbrowser(true);
                                }}>Get</button>
                        </jnb.Col>
                    </jnb.Row>
                    {(showmappeddata !== undefined && showmappeddata.length > 0) ? (<>
                        <div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>S.No</th>
                                        <th>Key Performance Sub Indicator Description</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showkpcountdata !== undefined && showkpcountdata.map((rowdata, i) => {
                                        return (<React.Fragment key={i}>
                                            <tr>
                                                <td width="70px">
                                                  
                                                    <input type="checkbox" name="kpIndicatorId" value={rowdata.kpIndicatorId} id="kpIndicatorId" />
                                                </td>
                                                <td width="80px">{i + 1}</td>
                                                <td  >{rowdata.kpIndicatorDescription}</td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end mb-3">
                            <button type="submit" className="btn rounded-0 btn-success btn-sm "
                                onClick={() => { setTypeSave("save") }} >Submit</button></div>
                    </>) : (<>{showbrowser && (<>

                        <div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>S.No</th>
                                        <th>Key Performance Sub Indicator Description</th>
                                       
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showkpcountdata !== undefined && showkpcountdata.map((rowdata, i) => {
                                        return (<React.Fragment key={i}>
                                            <tr>
                                                <td width="70px">
                                                   
                                                    <input type="checkbox" name="kpIndicatorId" value={rowdata.kpIndicatorId} id="kpIndicatorId" />
                                                </td>
                                                <td width="80px">{i + 1}</td>
                                                <td  >{rowdata.kpIndicatorDescription}</td>
                                               
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-end mb-3">
                            <button type="submit" className="btn rounded-0 btn-success btn-sm "
                                onClick={() => { setTypeSave("save") }} >Submit</button></div>
                    </>)}
                    </>)}


                    {showmappedatabrowser && (<>
                        <legend>Mapped Data:</legend>
                        <div className="table-responsive">
                            <table className="table table-condensed table-bordered table-striped table-responsive">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Key Performance Sub Indicator Description</th>
                                        <th>Status</th>
                                        <th>Active/InActive</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "left" }}>
                                    {showmappeddata !== undefined && showmappeddata.map((rowdata, i) => {
                                        return (<React.Fragment key={i}>
                                            <tr>
                                                <td align="center" width="100px">{i + 1}</td>
                                                <td>{rowdata.kpIndicatorDescription}</td>
                                                <td>{rowdata.active_status===true ?"Mapped":"Unmapped"}</td>
                                                <td>{rowdata.active_status===true?(<>
                                                
                                                <button className="btn btn-m btn-danger" onClick={(e)=> updateKPIndicatorsStatus(rowdata.kpIndicatorId,false)}>Unmap</button></>):(<>
                                                    <button className="btn btn-m btn-primary" onClick={(e)=> updateKPIndicatorsStatus(rowdata.kpIndicatorId,true)}>Map</button></>) }</td>
                                            </tr>
                                        </React.Fragment>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>)}
                </Form>
            </FormikProvider>
        </div>
    </>)
}
