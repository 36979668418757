import { Link} from "react-router-dom";
import * as jnb from "react-bootstrap";
import React,{ useEffect,useState} from "react";
import { config } from "../../../../../CommonUtils/CommonApis";
import { CommonAxiosGet } from "../../../../../CommonUtils/CommonAxios";
import Swal from "sweetalert2";
import CommonReactTable from "../../../../../CommonUtils/CommonReactTable";
import moment from "moment";
export default function HealthDashboardReport() {
    const [GetHealthReportHistory,setHealthReporthistory]=useState([]);
    const [GetTotalEmplData,setTotalEmplData]=useState([])
    const [showTotalEmplTable,setTotalEmplTable]=useState(false)
    const [GetConfirmEmplData,setConfirmEmplData]=useState([])
    const [showConfirmEmplTable,setConfirmEmplTable]=useState(false)
    const [GetNotConfirmEmplData,setNotConfirmEmplData]=useState([])
    const [showNotConfirmEmplTable,setNotConfirmEmplTable]=useState(false)
    const [GetHealthReportName, setHealthReportName] = useState();
    const [GetHealthData, setHealthData] = useState([])
    const [ReportDatafound, setReportDatafound] = useState()
    function GetEmpHealthHistory(){
        let Url = (config.url.COMMON_URL + "healthdashboard");
        CommonAxiosGet(Url).then((res) => {
            if (res !== null && res !== undefined) {
                if (res?.data?.status === true) {setHealthReporthistory(res?.data?.healthdashboard);}
                else {setHealthReporthistory([]);}}
        })
    }
    function DetailsOfDashboradReport(type){       
        let Url = (config.url.COMMON_URL + "healthdataconfirmnotconfirm/"+type);
        CommonAxiosGet(Url).then((res) => {
            if (res !== null && res !== undefined) {
                if(type === "total"){
                    if (res?.data?.status === true) {
                        setTotalEmplData(res?.data?.total_healthinsurance_data);
                        setTotalEmplTable(true);
                        setConfirmEmplTable(false);
                        setNotConfirmEmplTable(false)
                    }
                    else {
                        setTotalEmplData([]);
                        setTotalEmplTable(true);
                        setConfirmEmplTable(false);
                        setNotConfirmEmplTable(false)
                    }
                }
                if(type === "confirm"){
                    if (res?.data?.status === true) {
                        setConfirmEmplData(res?.data?.confirm_healthinsurance_data);
                        setTotalEmplTable(false);
                        setConfirmEmplTable(true);
                        setNotConfirmEmplTable(false)
                    }
                    else {
                        setConfirmEmplData([]);
                        setTotalEmplTable(false);
                        setConfirmEmplTable(true);
                        setNotConfirmEmplTable(false)
                    }
                }
                if(type === "notconfirm"){
                    if (res?.data?.status === true) {
                        setNotConfirmEmplData(res?.data?.notconfirm_healthinsurance_data);
                        setTotalEmplTable(false);
                        setConfirmEmplTable(false);
                        setNotConfirmEmplTable(true)
                    }
                    else {
                        setNotConfirmEmplData([]);
                        setTotalEmplTable(false);
                        setConfirmEmplTable(false);
                        setNotConfirmEmplTable(true)
                    }
                }

            }
        })
    }

      const [showModelPopUp, setModelPopUp] = useState(false);
  
      const handleClose = () => {setModelPopUp(false);}
      const showPopup = (row) => {
        setHealthReportName(row?.emp_name)
            let Url = (config.url.COMMON_URL + "healthdatanew/" + row?.cfms_id);
            CommonAxiosGet(Url).then((res) => {
                if (res !== null && res !== undefined) {
                    if (res?.data?.status === true) {
                        setHealthData(res?.data?.healthdatanew);
                        setReportDatafound("Data Found")
                    }
                    else{
                        setHealthData([])
                        setReportDatafound("No Data Found")
                    }}
            })
        setModelPopUp(true);}
    useEffect(() => {
        GetEmpHealthHistory()
    }, []);
    const columnsInfo = [
        {
            Header: "S.No",
            accessor: (row, index) => (<div style={{textAlign:"center"}}>{index + 1}&nbsp;</div>),
        },
        {
            Header: "Name of the Employee",
            accessor:"emp_name",
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: "Employee ID",
            accessor:"cfms_id",
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: 'Applied/Not-applied',
            accessor: row => {
                return (
                    <div>
                    {row?.view_status === 0 ? "Not-applied" :(<a href="javascript:void(0);"style={{color:"green"}} onClick={(e) => { showPopup(row) }}>VIEW</a>)}
                        </div>
                );
            },
          },
    ]
    const confirmcolumns = [
        {
            Header: "S.No",
            accessor: (row, index) => (<div style={{textAlign:"center"}}>{index + 1}&nbsp;</div>),
        },
        {
            Header: "Employee Name",
            accessor:"emp_name",
            Cell: ({ value }) => <div style={{ textAlign: 'center' }}>{value}</div>,
        },
        {
            Header: "Employee ID",
            accessor:"cfms_id",
            Cell: ({ value,row }) => <div style={{ textAlign: 'center' }}>
                {row?.original?.status === 0 ? (<>{row?.original?.cfms_id}</>):(
                <a href="javascript:void(0);" style={{color:"green"}} onClick={(e) => { showPopup(row?.original)}}>{row?.original?.cfms_id}</a>)}
            </div>,
        }
    ]
    const notconfirmcolumns = [
        {
            Header: "S.No",
            accessor: (row, index) => (<div style={{textAlign:"center"}}>{index + 1}&nbsp;</div>),
        },
        {
            Header: "Name of the Employee",
            accessor:"emp_name",
        },
        {
            Header: "Employee ID",
            accessor:"cfms_id",
            Cell: ({ value}) => <div style={{ textAlign: 'center' }}>{value}</div>,
        }
    ]

 return (<>
     <jnb.Row className=" m-0">
     <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
     <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Health Insurance Form</li>
                </ol>
     </jnb.Col>
     </jnb.Row>
        <jnb.Row className=" pt-2">
            <jnb.Row className="m-0 pt-2">
        <jnb.Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>
        <div className="head-text"style={{display:"inline"}}>
            <h1>Health Insurance</h1> </div></jnb.Col>
        </jnb.Row>
                <jnb.Row className="px-3">
                    {GetHealthReportHistory !== undefined ? (<>
                <table className="table  table-bordered  table-responsive" style={{width:"80%"}}>
                        <thead> 
                            <tr>
                                <th>Total</th>
                                <th>Applied</th>
                                <th>Not-Applied</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {GetHealthReportHistory && GetHealthReportHistory !== undefined && GetHealthReportHistory?.map((data, i) => {
                                return (<React.Fragment key={i} >
                                    <tr>
                                    <td>
                                        <a href="javascript:void(0);" onClick={(e) => { (data.count) !== 0 ? DetailsOfDashboradReport("total") : Swal.fire("NNo Data found","info") }}>
                                       {data.count}</a></td>
                                        <td>
                                            <a href="javascript:void(0);" 
                                        onClick={(e) => { (data.applied) !== 0 ? DetailsOfDashboradReport("confirm") : Swal.fire("NNo Data found","info") }}>
                                       {data.applied}
                                       </a></td>
                                        <td>
                                        <a href="javascript:void(0);" 
                                        onClick={(e) => { (data.not_applied) !== 0 ? DetailsOfDashboradReport("notconfirm") : Swal.fire("NNo Data found","info") }}>
                                       {data.not_applied}</a></td>
                                    </tr>
                                </React.Fragment>
                                )
                            })}
                        </tbody>
                    </table>
                    </>):(<center><b style={{color:"red"}}>No Data Found</b></center>)}
                </jnb.Row>
                <jnb.Row className="px-3 pt-2">
                    {showTotalEmplTable === true ? (<>{GetTotalEmplData !== undefined ? (<>
                    <CommonReactTable data={GetTotalEmplData} columns={columnsInfo} showFooter="false" filename="Heath Dashboard info" />
                        </>):(<center><b style={{color:"red"}}>No Data Found</b></center>)}</>):null}
                        {showConfirmEmplTable === true ? (<>{GetConfirmEmplData !== undefined ? (<>
                     <CommonReactTable data={GetConfirmEmplData} columns={confirmcolumns} showFooter="false" filename="Heath Dashboard applied employees" />
                    </>):(<center><b style={{color:"red"}}>No Data Found</b></center>)}</>):null}
                        {showNotConfirmEmplTable === true ? (<>{GetNotConfirmEmplData !== undefined ? (<>
                        <CommonReactTable data={GetNotConfirmEmplData} columns={notconfirmcolumns} showFooter="false" filename="Heath Dashboard not-applied employees" />
                    </>):(<center><b style={{color:"red"}}>No Data Found</b></center>)}</>):null}
                        <jnb.Modal show={showModelPopUp} onHide={handleClose} className="modal-xl" backdrop="static">
                            <jnb.Modal.Header className="bg-success bg-opacity-75 text-white" closeButton>
                                <jnb.Modal.Title>Details of {GetHealthReportName}</jnb.Modal.Title>
                            </jnb.Modal.Header>
                            <jnb.Modal.Body>
                            <jnb.Row className="px-3 pt-2">
                        <div className="border">
                        {ReportDatafound === "Data Found" ? (
                    <div className="border ">
                        < jnb.Row className="px-2 pt-4">
                            <table class="table table-condensed table-bordered table-striped table-responsive"
                                style={{ border: '3px solid lightblue', width: '87%', marginLeft: '55px', borderCollapse: 'collapse', }}>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Relation</th>
                                <th>Name</th>
                                <th>DOB</th>
                                <th>Gender</th>
                                <th>Aadhaar </th>
                            </tr>
                        </thead>
                        <tbody>
                            {GetHealthData && GetHealthData?.map((data, i) => (
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{data.rel_name}</td>
                                                <td>{data.person_name}</td>
                                                <td>{data.dob !== null  ? (moment(data.dob).format('DD-MM-YYYY')) :""}</td>
                                                <td>{ data.gender !== null ?  (data.gender === "F" ? "Female" : "Male") :""}</td>
                                                <td>{data.aadhaar}</td>
                                            </tr>
                                        ))}
                        </tbody>
                        </table>
                        </jnb.Row>
                    </div>
                ) : null}
                        </div>
                    </jnb.Row>
                    <jnb.Row className="px-3 pt-2">
                        <div className='d-flex flex-row justify-content-end'>
                            <button type="button" className='btn btn-success rounded' onClick={handleClose}>Close</button>
                        </div>
                    </jnb.Row>
                </jnb.Modal.Body>
            </jnb.Modal>
        </jnb.Row>
        </jnb.Row>
    </>)
}


