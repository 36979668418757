import { ErrorMessage, Field, FormikProvider, useFormik,Form} from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as jnb from "react-bootstrap";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useState } from "react";
import * as Yup from "yup";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Sweetalert from "../../../../CommonUtils/SweetAlerts";
import allowNumbersOnly, { allowAlphabetAndSpacesOnly } from "../../../../CommonUtils/CommonValidations";
import FilesBase64 from "../../../../CommonUtils/FilesBase64";
export default function CandidateEntryForm() {
   const navigate = useNavigate()
   const logindetails = useSelector((state) => state.reducers?.loginreducer?.userLoginDetials);
   const jobDetails=JSON.parse(localStorage.getItem("jobDetails"));
   const validation = Yup.object().shape({
    years_of_experience:Yup.string().required("Required").nullable(),
        candidate_name:Yup.string().required("Required").nullable(),
        mobile:Yup.string().required("Required").nullable(),
        presnet_designation:Yup.string().required("Required").nullable(),
        presnet_company:Yup.string().required("Required").nullable(),
        email:Yup.string().required("Required").nullable(),
        remarks:Yup.string().required("Required").nullable(),
        resume_upload:Yup.string().required("Required").nullable(),
});

    const candidateentryInitailValues  = {
        acceptance:"",
        designation_applied:jobDetails?.designation,
        technology:jobDetails?.technology,
        insert_by:logindetails?.username,
        years_of_experience:"",
        candidate_name:"",
        mobile:"",
        presnet_designation:"",
        presnet_company:"",
        email:"",
        remarks:"",
        resume_uploadhidden:"",
        resume_upload:""
    };
    
    const formIk = useFormik({
        enableReinitialize: true,
        initialValues: candidateentryInitailValues, 
        onSubmit: (values) => {
            CandidateDetailsSave(values);
        },
         validationSchema: validation,
    });
    function navigateToDetaisl(){
        navigate("/jobpostingdetails")
    }
    const CandidateDetailsSave = (values) => {
        Swal.fire({
            title: 'Are you sure you want to submit?',
            text: 'Please check it once before submitting',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                CommonPost.candidateDetailsPost(values)
                    .then((res) => {
                        console.log("candidate data",res.data)
                        if (res.data.ResponseCode === "01") {
                            Swal.fire('Successfully Submitted', 'Success', 'success',);
                            navigateToDetaisl()
                        }
                        else if (res.data.ResponseCode === "02") {
                            Sweetalert(res.data.ResponseDesc, 'warning');
                        }
                        else if (res.data.ResponseCode === "04") {
                            Sweetalert(res.data.ResponseDesc);
                        }
                        else {
                            Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                        }
                    }).catch(() => {
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
            }
        })
    };
   
    const [showCandidateEntryForm, setCandidateEntryForm] = useState();
    function clearFields() {
        formIk.setFieldValue("years_of_experience", "");
        formIk.setFieldValue("candidate_name", "");
        formIk.setFieldValue("mobile", "");
        formIk.setFieldValue("presnet_designation", "");
        formIk.setFieldValue("presnet_company", "");
        formIk.setFieldValue("email", "");
        formIk.setFieldValue("remarks", "");
        formIk.setFieldValue("resume_upload", "");
        formIk.setFieldValue("resume_uploadhidden", "");
        formIk.resetForm();
        const fileInputRef = document.getElementById("resume_upload");
        if (fileInputRef) {
          fileInputRef.value = "";
        }
      }
    function getCandidateEntryInfo(event) {
        setCandidateEntryForm(event.target.checked);
        if (!event.target.checked) {
            clearFields();
        }
    }
      function NavigateToJobPosReport(){
        navigate("/jobpostingdetails")
      }
    return (<>
         <jnb.Row>
         <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
         <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/homepage">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Job Application-{jobDetails?.zone_name}</li>
                </ol>
         </jnb.Col>
         <jnb.Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="pe-0">
         <button type="button" class="btn btn-secondary  btn-sm float-end mb-2" onClick={() => {NavigateToJobPosReport()}}>BACK</button> 
         </jnb.Col>
         </jnb.Row>
        <FormikProvider value={formIk}>
            <Form onSubmit={formIk.handleSubmit} onChange={formIk.handleChange}>
            <jnb.Row className="px-3 mb-2 pt-3 py-2 border">
            <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
            <label className="w-100"><b>Job Category:</b>  </label>
            <label className="w-100">{jobDetails?.zone_name}  </label>
            </jnb.Col>
            <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
            <label className="w-100"><b>Job Description:</b>  </label>
            <label className="w-100">{jobDetails?.job_description} </label>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                <label className="w-100"><b>Designation:</b>  </label>
            <label className="w-100">{jobDetails?.designation_name}</label>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                <label className="w-100"><b>Requirement Years:</b>  </label>
            <label className="w-100">{jobDetails?.requirement_years}  </label>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                <label className="w-100"><b>No.Of Persons:</b>  </label>
               <label className="w-100">{jobDetails?.no_of_persons}  </label>
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                <label className="w-100"><b>Location:</b>  </label>
                <label className="w-100">{jobDetails?.job_location}  </label>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}>
                    <label className="w-100"><b>Immediate Joining:</b>  </label>
            <label className="w-100">{jobDetails?.immediate_joining}  </label>
                    </jnb.Col>
            </jnb.Row>
            <jnb.Row className=" mb-2 pt-1 ">
            <pre></pre>
            <div class="alert alert-danger p-4 rounded" >
                <Field type="checkbox" name="acceptance" onClick={getCandidateEntryInfo}/>&nbsp;
                <label className='field-mandatory'><b style={{color:"brown"}}> 
                    I'm in! Excited to contribute my skills and passion to your team. Let's embark on this journey together!</b></label>
            </div>
            </jnb.Row>
            <Field type="hidden" name="technology" className="form-control"/>
            {showCandidateEntryForm && (<>
                <jnb.Row className=" pt-5   px-0  border">
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                        <span className="label-text-style"><b>Name:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="candidate_name" className="form-control mb-2" onKeyPress={(e) => { allowAlphabetAndSpacesOnly(e); }}/>
                            <ErrorMessage name="candidate_name" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                        <span className="label-text-style"><b>Years of Experience:<font style={{ color: "red" }}>*</font></b></span>
                            <Field type="text" name="years_of_experience" className="form-control mb-2"/>
                            <ErrorMessage name="years_of_experience" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Present Designation:<font style={{ color: "red" }}>*</font> </b></span>
                                <Field type="text" name="presnet_designation" className="form-control mb-2"/>
                            <ErrorMessage name="presnet_designation" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Presnet Company:<font style={{ color: "red" }}>*</font> &nbsp;</b></span>
                            <Field type="text" name="presnet_company" className="form-control mb-2"/>
                            <ErrorMessage name="presnet_company" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>Mobile:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="mobile" className="form-control mb-2" maxLength="10"  onKeyPress={(e) => { allowNumbersOnly(e); }}/>
                            <ErrorMessage name="mobile" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Email:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="email" className="form-control mb-2"/>
                            <ErrorMessage name="email" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup >
                            <span className="label-text-style"><b>Remarks:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field as="textarea" style={{height:'30px'}} className="form-control mb-2" name="remarks" maxLength="400"></Field>
                            <ErrorMessage name="remarks" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                        <jnb.InputGroup>
                            <span className="label-text-style"><b>Upload Resume:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="hidden" id="resume_uploadhidden" name="file_uploadhidden" maxLength="15"className="form-control mb-2"/>
                                    <Field type="file" id="resume_upload" name="file_upload" maxLength="15"className="form-control mb-3"
                                    onChange={(event) => FilesBase64(event, formIk,"resume_upload","resume_uploadhidden")} />
                            <ErrorMessage name="resume_upload" component="div" className="text-error" />
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="mt-2" >
                        <div className='d-flex flex-row justify-content-end ' >
                            <button type="submit" className="btn btn-success btn-sm mb-2">Apply</button>
                        </div>
                    </jnb.Col>
                </jnb.Row>
                </>)}
            </Form>
        </FormikProvider>
    </>)
}
