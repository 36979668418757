// CarouselComponent.js
import React from 'react';

const CarouselComponent = ({ images, interval }) => {
    return (
        // <div className="set-pic">
            <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {images.map((image, index) => (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval={interval[index]} key={index}>
                            <img src={image.src} alt={image.alt} className="img-fluid w-100" />
                        </div>
                    ))}
                </div>
            </div>
        // </div>
    );
};

export default CarouselComponent;
