import { FormikProvider, useFormik, Form, Field, ErrorMessage } from "formik";
import * as jnb from "react-bootstrap";
import React, { useEffect } from "react";
import allowNumbersOnly from "../../../../CommonUtils/CommonValidations";
import CommonPost from "../../../../CommonUtils/CommonPost";
import Swal from "sweetalert2";
import Sweetalert, { SweetalertOKFunction } from "../../../../CommonUtils/SweetAlerts";
import { useSelector } from "react-redux";
import * as  Yup from "yup";
import CommonEmployeeDetailsForPayroll from "./PaySlips/CommonEmployeeDetailsForPayroll";
const ContractPayRollEntryForm = (propsAtContractPayRoll) => {
    const  contractOrInternAtContract=JSON.parse(localStorage?.getItem("data")) || [];
    const logindetailsContractPayRoll = useSelector((statecnt) => statecnt?.reducers?.loginreducer?.userLoginDetials)
    const ContractPayRollformIk = useFormik({
        enableReinitialize: true,
        initialValues: {
            emp_id:contractOrInternAtContract?.emp_id,
            desg_id:contractOrInternAtContract?.designation_id,
            insert_by:logindetailsContractPayRoll?.username,
            consolidated_pay_earned: "",
            tds:"",
        },
        validationSchema: Yup.object().shape({
            consolidated_pay_earned: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            Swal.fire({
                title: 'Are you sure you want to submit?',
                text: 'Please check it once before submitting',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    CommonPost.ContractEmpPaySave(values).then((resCnt) => {
                            if (resCnt?.data?.ResponseCode === "01") {
                                SweetalertOKFunction('Successfully Submitted ', 'success').then(function (isConfirm) {
                                    if (isConfirm.value) {window.location.reload();}
                                })
                            }
                            else if (resCnt?.data?.ResponseCode === "02") {
                                Sweetalert(resCnt?.data?.ResponseDesc, 'warning');
                            }
                            else {
                                Swal.fire('Canceled', 'Something went wrong,Please check', 'error');
                            }
                        })
                        .catch(() => {console.log("Exception Occured 71 ")
                        });
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Canceled', 'Do corrections and then retry :)', 'error');
                }
            })
        },
    });

    function calculateTDS(value){
        const tdsvalue=value*0.1 
        ContractPayRollformIk.setFieldValue("tds",tdsvalue); 
    }
    function ContractViewDataAtPayRoll() {
        if (propsAtContractPayRoll?.contractviewdata === undefined || propsAtContractPayRoll?.contractviewdata === null || propsAtContractPayRoll?.contractviewdata === "") {
            return;
        }
        else {
            if(propsAtContractPayRoll?.contractviewdata?.consilated_total === null || propsAtContractPayRoll?.contractviewdata?.consilated_total === undefined){
                return;
            }
            else {
                ContractPayRollformIk.setFieldValue("consolidated_pay_earned", propsAtContractPayRoll?.contractviewdata?.consilated_total);
                ContractPayRollformIk.setFieldValue("tds", propsAtContractPayRoll?.contractviewdata?.tds);
            }
        }
    }

    useEffect(() => {
        ContractViewDataAtPayRoll();
    }, [propsAtContractPayRoll?.contractviewdata]);  

    const handleClose = () => {
        propsAtContractPayRoll.contractpopup();
        if(propsAtContractPayRoll?.contractviewdata?.consilated_total === null){
            ContractPayRollformIk.resetForm();
        }
        else{
            ContractViewDataAtPayRoll();
        }
    }
    return (<>
            <jnb.Modal show={propsAtContractPayRoll?.contractpopupflagvalue} onHide={handleClose} className="modal-xl" backdrop="static">
                <jnb.Modal.Header className="bg-success bg-opacity-75 text-white">
                    <jnb.Modal.Title>Payroll Details </jnb.Modal.Title>
                </jnb.Modal.Header>
                <jnb.Modal.Body>
                <jnb.Row className="px-2 mb-2 pt-0">
                <jnb.Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <CommonEmployeeDetailsForPayroll employeeDetails={contractOrInternAtContract} />
                </jnb.Col>
                <jnb.Col xs={12} sm={12} md={12} lg={2} xl={2} xxl={2}></jnb.Col>
            </jnb.Row>
            <FormikProvider value={ContractPayRollformIk}>
                <Form onSubmit={ContractPayRollformIk.handleSubmit} onChange={ContractPayRollformIk.handleChange} autoComplete="off" >
                <jnb.Row className="px-3">
                        <div className="border pt-3 RowColorForWEH">
                    <jnb.Row className="px-3 pt-4">
                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>Consolidated Pay Gross:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="consolidated_pay_earned" className="form-control"  onKeyPress={allowNumbersOnly} 
                            onChange={(e)=>{calculateTDS(e.target.value)}}/>
                        <ErrorMessage name="consolidated_pay_earned" component="div" className="text-error"/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                        <jnb.InputGroup className="mb-4p5">
                            <span className="label-text-style"><b>TDS:<font style={{ color: "red" }}>*</font> </b></span>
                            <Field type="text" name="tds" className="form-control"  onKeyPress={allowNumbersOnly} readOnly={true}/>
                        </jnb.InputGroup>
                    </jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}></jnb.Col>
                    <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                            <div className='d-flex flex-row justify-content-end pe-0'>
                            <button type="button" className="btn btn-primary btn-sm float-end ms-2 mb-4" onClick={handleClose}>Close</button>
                            </div>
                            </jnb.Col>
                    </jnb.Row>
                    </div>
                    </jnb.Row>
                    <jnb.Row className="pt-2">
                             <jnb.Col xs={12} sm={12} md={12} lg={1} xl={1} xxl={1}>
                            <div className='d-flex flex-row justify-content-end pe-0'>
                            <button type="submit" className="btn btn-success btn-sm  ms-2 mb-4">Save</button>
                            </div>
                            </jnb.Col>
                        </jnb.Row>
                </Form>
            </FormikProvider>
            </jnb.Modal.Body>
            </jnb.Modal>
    </>)
}
export default ContractPayRollEntryForm;