export const UPLOAD_URL = "https://api.herb.apcfss.in/dms/onboarding/upload";//for documents

export const PRR_Docs_Pdf = 'https://swapi.dev.nidhi.apcfss.in/socialwelfaredms/user-defined-path/file-upload/hrms_pms/';


export const IMG_UPLOAD_URL = "https://swapi.dev.nidhi.apcfss.in/socialwelfaredms/user-defined-path/file-upload/";
export const IMG_DOWNLOAD_URL = "https://swapi.dev.nidhi.apcfss.in/socialwelfaredms/user-defined-path/file-download/";
const protocol = window.location.protocol === "https:" ? "https" : "http";
const prod = {
    url: {
        // COMMON_URL: 'https://hrmsapi.apcfss.in/',
        COMMON_URL: 'https://api.apnidhi.in/cfsshrms/',
        UI_URL: "https://hrms.apnidhi.in",
        BANK_URL: 'https://swapi.dev.nidhi.apcfss.in/nivascommonservice/'
    }
}; const dev = {
    url: {
        UI_URL: "https://cfsshrms.dev.nidhi.apcfss.in",
        COMMON_URL: 'https://swapi.dev.nidhi.apcfss.in/cfsshrms/',
        BANK_URL: "https://swapi.dev.nidhi.apcfss.in/nivascommonservice/auth/",
    }

};
const local = {
    url: {
        UI_URL: "http://localhost:3000",
        COMMON_URL: 'http://172.16.119.131:8080/cfsshrms/',
        BANK_URL:`${protocol}://172.16.119.177:3456/nivascommonservice/auth/`,
        EARNING_SAVE: 'https://api.herb.apcfss.in'
    }

};
export const config = process.env.NODE_ENV === 'development' ? dev : prod; 