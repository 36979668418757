import { useLocation, Link } from "react-router-dom";
import Sweetalert from "../../../CommonUtils/SweetAlerts";
import { config } from "../../../CommonUtils/CommonApis";
import React, { forwardRef, useEffect, useState } from "react";
import { useRef } from "react";
import { IoPrint } from "react-icons/io5";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { CommonAxiosGet } from "../../../CommonUtils/CommonAxios";
import { ROLE_HR, ROLE_IO, ROLE_RO } from "../../../CommonUtils/ApplicationRoles";
import { FaFilePdf } from "react-icons/fa6";
import getPDFView from "../../../CommonUtils/PdfView";

export default function PmsEmpPrintForm() {
    const loactionAtpmsprint = useLocation();
    const employeeIdAtpmsprint = loactionAtpmsprint?.state?.empid;
    const pagenameFlagAtpmsPrint = loactionAtpmsprint?.state?.pageflag;
    const EmpStatusAtpmsPrint = loactionAtpmsprint?.state?.status;
    const [showpageFlagAtpmsprint, setpageFlagAtpmsprint] = useState(pagenameFlagAtpmsPrint);
    const [showEmpStatusAtpmsprint, setEmpStatusAtpmsprint] = useState(EmpStatusAtpmsPrint);
    const [showPrintEmpDataAtpmsprint, setPrintEmpDataAtpmsprint] = useState([]);
    const [showPrintEmpKpDataAtpmsprint, setPrintEmpKpDataAtpmsprint] = useState([]);
    const [showPrintEmpKpStatusDataAtpmsprint, setPrintEmpKpStatusDataAtpmsprint] = useState([]);
    const [showEmployeePromotionDataAtpmsprint, setEmployeePromotionDataAtpmsprint] = useState([]);
    const [showEmployeeRatingDataAtpmsprint, setEmployeeRatingDataAtpmsprint] = useState([]);
    useEffect(() => {
        let Url = (config.url.COMMON_URL + "pms/employee/appraisal/" + pagenameFlagAtpmsPrint + "/" + employeeIdAtpmsprint);
        CommonAxiosGet(Url).then((resAtpmsprint) => {
            if (resAtpmsprint.data.scode === "01") {
                setPrintEmpDataAtpmsprint(resAtpmsprint.data.employee_data);
                setPrintEmpKpDataAtpmsprint(resAtpmsprint.data.employee_kp_data);
                setPrintEmpKpStatusDataAtpmsprint(resAtpmsprint.data.employee_kpstatus_data)
                setpageFlagAtpmsprint(pagenameFlagAtpmsPrint);
                setEmployeeRatingDataAtpmsprint(resAtpmsprint?.data?.employee_ratings_data);
                setEmpStatusAtpmsprint(EmpStatusAtpmsPrint);
                setEmployeePromotionDataAtpmsprint(resAtpmsprint?.data?.employee_io_status_data)
            }
            else if (resAtpmsprint.data.scode === "02") {
                Sweetalert(resAtpmsprint.data.sdesc, 'warning')
            }
        })
    }, []);

    const ref = useRef();
    const ComponentToPrint = forwardRef((props, ref) => {
        return (<>
            <div ref={ref}> <>
                <legend><b>Employee Details</b></legend>
                <table className="table table-condensed table-bordered table-striped table-responsive">
                    <tbody>
                        <tr>
                            <th>Employee Name (ID):</th><td>{showPrintEmpDataAtpmsprint[0]?.emp_name}({showPrintEmpDataAtpmsprint[0]?.emp_id})</td>
                            <th>Date of Joining:</th><td>{showPrintEmpDataAtpmsprint[0]?.doj?.split("-")?.reverse()?.join("/")}</td>
                            <th>Wing:</th><td>{showPrintEmpDataAtpmsprint[0]?.zone_name}</td>
                            <th>Designation :</th><td>{showPrintEmpDataAtpmsprint[0]?.designation_name}</td>
                        </tr>
                        <tr>
                            <th>IO Name(ID):</th><td>{showPrintEmpDataAtpmsprint[0]?.io_emp_name}({showPrintEmpDataAtpmsprint[0]?.io_emp_id})</td>
                            <th>RO Name(ID):</th><td>{showPrintEmpDataAtpmsprint[0]?.ro_emp_name}({showPrintEmpDataAtpmsprint[0]?.ro_emp_id})</td>
                            <th>Contact:</th><td>{showPrintEmpDataAtpmsprint[0]?.mobile_no}</td>
                            <th>Email:</th><td>{showPrintEmpDataAtpmsprint[0]?.email}</td>
                        </tr>
                        <tr>
                            <th>Band:</th><td>{showPrintEmpDataAtpmsprint[0]?.band}</td>
                        </tr>
                    </tbody>
                </table>
                {showPrintEmpKpStatusDataAtpmsprint?.length === 0 ? (<>
                    <center><span className="text-danger" >Pending at Appraise</span></center> </>) : (<>
                        {showPrintEmpKpDataAtpmsprint !== undefined && showPrintEmpKpDataAtpmsprint.map((row, i) => {
                            return (<React.Fragment key={i}>
                                <legend><b>{row.kp_short_description}</b></legend>
                                <h4 style={{ fontSize: "16px" }}>{row.kp_description}</h4>
                                <table className="table table-condensed table-bordered table-striped table-responsive">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Key Performance Areas Description</th>
                                            <th>Self Rating</th>
                                            <th>Self Remarks</th>
                                            {showpageFlagAtpmsprint === "HR" || (showpageFlagAtpmsprint === ROLE_IO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO")) ? (<>
                                                <th>IO Rating</th>
                                                <th>IO Remarks</th>
                                            </>) : (<>
                                                {showpageFlagAtpmsprint === "HR" || (showpageFlagAtpmsprint === ROLE_RO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO")) ? (<>
                                                    <th>IO Rating</th>
                                                    <th>IO Remarks</th>
                                                </>) : (<></>)}
                                            </>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {showPrintEmpKpStatusDataAtpmsprint !== undefined && showPrintEmpKpStatusDataAtpmsprint.filter((kpi) => kpi.kp_id === row.kp_id).map((kpidata, k) => {
                                                return (
                                                    <React.Fragment key={k}>
                                                        <tr>
                                                            <td>{k + 1}</td>
                                                            <td>{kpidata.kp_indicator_description}</td>
                                                            <td>{kpidata.emp_self_rating}</td>
                                                            <td>{kpidata.emp_self_rating_remarks}</td>
                                                            {showpageFlagAtpmsprint === "HR" || (showpageFlagAtpmsprint === ROLE_IO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO")) ? (<>
                                                                <td>{kpidata.io_emp_rating}</td>
                                                                <td>{kpidata.io_emp_remarks}</td>
                                                            </>) : (<>
                                                                {showpageFlagAtpmsprint === "HR" || (showpageFlagAtpmsprint === ROLE_RO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO")) ? (<>
                                                                    <td>{kpidata.io_emp_rating}</td>
                                                                    <td>{kpidata.io_emp_remarks}</td>
                                                                </>) : (<></>)}
                                                            </>)}
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            }
                                            )
                                        }
                                        
                                    </tbody>
                                </table>

                            </React.Fragment>)
                        })}
                        {showpageFlagAtpmsprint === "HR" || (showpageFlagAtpmsprint === ROLE_IO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO") ||
                            (showpageFlagAtpmsprint === ROLE_RO && (showEmpStatusAtpmsprint === "COMPLETED" || showEmpStatusAtpmsprint === "PENDING_AT_RO"))) ? (<>
                               
                                <table className="table table-condensed table-bordered table-striped table-responsive">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Self remarks</th>
                                            <th>IO Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>  Strengths </td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.self_strengths}</b></td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.io_strengths}</b> </td>
                                        </tr>
                                        <tr>
                                            <td>Areas for Improvement</td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.self_areas_of_imporvement}</b></td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.io_areas_of_imporvement}</b></td>
                                        </tr>
                                        <tr>
                                            <td> Career Aspirations </td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.self_carrer_aspirations}</b></td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.io_carrer_aspirations}</b></td>

                                        </tr>
                                        <tr>
                                            <td>Upload Any Relevant Document</td>
                                            <td>
                                                {(showPrintEmpDataAtpmsprint[0]?.file_upload != undefined && showPrintEmpDataAtpmsprint[0]?.file_upload != "") ? (<>
                                                    <FaFilePdf style={{ fontWeight: "bold", fontSize: "30px", color: "blue", cursor: "pointer" }}
                                                        onClick={(e) => { getPDFView(showPrintEmpDataAtpmsprint[0]?.file_upload) }} />
                                                </>) : (<></>)}</td>
                                            <td><b>{showPrintEmpDataAtpmsprint[0]?.io_file_remarks}</b></td>
                                        </tr>
                                        <tr>
                                            <td>Additional accomplishments not covered in Key Objectives above</td>
                                            <td>{showPrintEmpKpStatusDataAtpmsprint[0]?.self_addl_key_objective}</td>
                                            <td><b>{showPrintEmpKpStatusDataAtpmsprint[0]?.io_addl_key_objective}</b></td>
                                        </tr>
                                        {(showEmpStatusAtpmsprint === "COMPLETED" && showpageFlagAtpmsprint === ROLE_RO) ? (<>
                                            <tr>
                                                <td>Ro Overall Remarks:</td>
                                                <td colSpan={2}><b>{showPrintEmpDataAtpmsprint[0]?.strengths_weaknesses}</b></td>

                                            </tr>
                                        </>) : (<></>)}

                                    </tbody>
                                </table>

                                <table className="table table-condensed table-bordered table-striped table-responsive"
                                    style={{ border: '3px solid lightblue', borderCollapse: 'collapse', }}>
                                    <thead>
                                        <tr>
                                            <th width="250px"></th>
                                            <th>Employee</th>
                                            <th>IO</th>
                                            {(showpageFlagAtpmsprint === "HR" || (showEmpStatusAtpmsprint === "COMPLETED" && showpageFlagAtpmsprint === ROLE_RO)) ? (<>
                                                <th>RO</th>
                                            </>) : (<></>)}
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Final Rating:</b><font style={{ color: "red" }}>*</font> </td>
                                            <td>
                                                {showEmployeePromotionDataAtpmsprint?.length > 0 ? (<>
                                                    {showEmployeePromotionDataAtpmsprint[0]?.emp_self_final_rating}
                                                    ({showEmployeePromotionDataAtpmsprint[0]?.emp_self_final_marks})
                                                </>) : (<>
                                                    {showEmployeeRatingDataAtpmsprint?.length > 0 ? (<>
                                                        {showEmployeeRatingDataAtpmsprint[0]?.emp_rating}({showEmployeeRatingDataAtpmsprint[0]?.total_avg_emp})
                                                    </>) : (<></>)}
                                                </>)}
                                            </td>
                                          
                                            <td>
                                                {showEmployeePromotionDataAtpmsprint?.length > 0 ? (<>
                                                    {showEmployeePromotionDataAtpmsprint[0]?.io_final_rating !== null ? (<>
                                                        {showEmployeePromotionDataAtpmsprint[0]?.io_final_rating}
                                                        ({showEmployeePromotionDataAtpmsprint[0]?.io_final_marks})
                                                    </>) : (<></>)}
                                                </>) : (<>
                                                    {showEmployeeRatingDataAtpmsprint?.length > 0 ? (<>
                                                        {showEmployeeRatingDataAtpmsprint[0]?.io_rating}({showEmployeeRatingDataAtpmsprint[0]?.total_avg_io})
                                                    </>) : (<></>)}
                                                </>)}
                                            </td>
                                            {((showpageFlagAtpmsprint === "HR" && (showEmployeePromotionDataAtpmsprint?.length > 0)) || (showEmpStatusAtpmsprint === "COMPLETED" && showpageFlagAtpmsprint === ROLE_RO) ) ? (<>
                                                <td>{showEmployeePromotionDataAtpmsprint[0]?.ro_final_rating_status === "N" ? (<>Disagree &nbsp;
                                                    (<b>Remarks:</b>&nbsp;
                                                    {showEmployeePromotionDataAtpmsprint[0]?.ro_final_rating_status_remarks})
                                                    &nbsp;(<b>Final Rating:</b>{showEmployeePromotionDataAtpmsprint[0]?.ro_final_rating} &nbsp;<b>Remarks:</b> &nbsp;
                                                    {showEmployeePromotionDataAtpmsprint[0]?.ro_final_remarks} )</>) : (<>
                                                        {showEmployeePromotionDataAtpmsprint[0]?.ro_final_rating_status === "Y" ? ("Agree") : ("")}
                                                    </>)}</td>
                                            </>) : (<></>)}
                                        </tr>
                                        <tr>
                                            <td><b>I am Referring for Promotion </b><font style={{ color: "red" }}>*</font>&nbsp;&nbsp;</td>
                                            <td>-</td>
                                            <td>{showEmployeePromotionDataAtpmsprint?.length > 0 ? (<>
                                                {showEmployeePromotionDataAtpmsprint[0]?.io_promotion_status === true ?
                                                    (<>Yes &nbsp; (<b>Remarks:&nbsp;</b>{showEmployeePromotionDataAtpmsprint[0]?.io_promotion_remarks})</>) : (<>{
                                                        showEmployeePromotionDataAtpmsprint[0]?.io_promotion_status !== null ? ("No") : ("")
                                                    }
                                                    </>
                                                    )}
                                            </>) : (<></>)}
                                            </td>
                                            {((showpageFlagAtpmsprint === "HR" && (showEmployeePromotionDataAtpmsprint?.length > 0)) || (showEmpStatusAtpmsprint === "COMPLETED" && showpageFlagAtpmsprint === ROLE_RO)) ? (<>
                                                <td>
                                                    {showEmployeePromotionDataAtpmsprint[0]?.io_promotion_status === true ? (<>
                                                        {showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status === "N" ? (<>Disagree &nbsp; (<b>Remarks:</b>
                                                            {showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status_remarks})
                                                        </>) : (<>
                                                            {showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status === "Y" ? ("Agree") : ("")}
                                                        </>)}
                                                    </>) : (<>
                                                        {showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status === "N" ? (<>Disagree &nbsp;(<b>Remarks:</b>&nbsp;
                                                            {showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status_remarks}) &nbsp;
                                                            (<b>Promotion Status:</b> &nbsp;{showEmployeePromotionDataAtpmsprint[0]?.promotion_status === true ? (<>Yes &nbsp;
                                                                (<b>Remarks:&nbsp;{showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_remarks}</b>)
                                                            </>) : ("No")})&nbsp;

                                                        </>) : (<>{showEmployeePromotionDataAtpmsprint[0]?.ro_promotion_status === "Y" ? ("Agree") : ("")}</>)}
                                                    </>)}
                                                </td>
                                            </>) : (<></>)}
                                        </tr>
                                        <tr>
                                            <td><b>Salary Correction:</b><font style={{ color: "red" }}>*</font></td>
                                            <td>-</td>

                                            <td>{showEmployeePromotionDataAtpmsprint?.length > 0 ? (<>
                                                {showEmployeePromotionDataAtpmsprint[0]?.io_salary_correction === "Y" ? (<> Yes &nbsp;
                                                    (<b>Amount (per Annum):</b>&nbsp;Rs.{showEmployeePromotionDataAtpmsprint[0]?.io_salary_correction_amount})&nbsp;
                                                    (<b>Remarks:&nbsp;</b>{showEmployeePromotionDataAtpmsprint[0]?.io_salary_correction_remarks})
                                                </>) : (<>{showEmployeePromotionDataAtpmsprint[0]?.io_salary_correction === "N" ? ("No") : ("")}</>)}
                                            </>) : (<></>)}
                                            </td>
                                            {((showpageFlagAtpmsprint === "HR" && (showEmployeePromotionDataAtpmsprint?.length > 0)) || (showEmpStatusAtpmsprint === "COMPLETED" && showpageFlagAtpmsprint === ROLE_RO)) ? (<>
                                                <td>{showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction_status === "N" ? (<>Disagree &nbsp;
                                                    (<b>Remarks: &nbsp;</b>&nbsp; {showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction_status_remarks})
                                                    (<b>Salary Correction:</b>&nbsp; {showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction === "Y" ? (<>Yes
                                                        &nbsp; (<b>Amount (per Annum):&nbsp; </b>Rs.{showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction_amount} <b>&nbsp;
                                                            Remarks:&nbsp;</b>{showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction_remarks})</>) : (<>
                                                                {showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction === "N" ? ("No") : ("")}
                                                            </>)} )
                                                </>) : (<>{showEmployeePromotionDataAtpmsprint[0]?.ro_salary_correction_status === "Y" ? ("Agree") : ("")}</>)}</td>
                                            </>) : (<></>)}
                                        </tr>
                                    </tbody>
                                </table>
                            </>) : (<> </>)}
                    </>)}
            </>
            </div></>)
    });
    return (
        <>
            <div class="card">
                <div class="card-body">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/homepage">Home</Link>
                        </li>
                        {showpageFlagAtpmsprint === ROLE_IO ? (<>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"IO"}`}>My Team Appraisal-IO</Link>
                            </li>
                        </>) : (<>{showpageFlagAtpmsprint === ROLE_RO ? (<>
                            <li className="breadcrumb-item">
                                <Link to={`/loginwisereport/${"RO"}`}>My Team Appraisal-RO</Link>
                            </li>
                        </>) : (<>{showpageFlagAtpmsprint === ROLE_HR ? (<>
                            <li className="breadcrumb-item">
                                <Link to={"/hradmindashboard"}>Key Performance Abstract Report</Link>
                            </li>
                        </>) : (<></>)}
                        </>)}</>)}
                        <li className="breadcrumb-item active" aria-current="page">Appraisal Form</li>
                    </ol>
                </div>
            </div>
            <ComponentToPrint ref={ref} />
            {showPrintEmpKpStatusDataAtpmsprint?.length === 0 ? (<></>) : (<>
                <ReactToPrint content={() => ref.current}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <div className="text-center">
                                <button
                                    className="btn btn-md"
                                    style={{ backgroundColor: "skyblue" }}
                                    variant="primary"
                                    onClick={() => { handlePrint(); }
                                    }>
                                    <IoPrint />&nbsp;
                                    Print</button>
                            </div>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>
            </>)}
        </>
    )
}